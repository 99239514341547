﻿// MACRO.BLOCKQUOTE


.m-quote {
	position: relative;
	background: $white;
	padding: 0 0 0 $bsu;
	border-radius: 0;
	border-left: 3px solid $brand;

	@include bp("large") {
		padding: 0 0 0 $bsu;
	}

	&::before {
		display: none;
	}

	&.u-float--left,
	&.u-float--right {
		+ h2,
		+ h3,
		+ h4,
		+ h5,
		+ h6 {
			margin-top: 0;
		}
	}
}

.m-quote__text {
	@include font-base-16;
	color: $brand;
}

.m-quote__citation {
	@include font-base-12b;
	color: $brand;
}
