$webchat-bp: 520px;

.ac-pushButton {
	border-radius: 6px;
	border-style: none;
	border-color: #bbbcbd;
	background-color: black;
}

.footer-webchat-button {
	@include link-transition;
	background: none;
	position: fixed;
	right: $bsu;
	background: $orange;
	color: $white;
	bottom: 0;
	padding: $ssu $bsu;
	z-index: 1;
	border-radius: 8px 8px 0 0;
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: lh(22, 14);
	border-color: $orange;
	cursor: pointer;

	&:hover {
		background-color: $white;
		color: $brand;
	}

	&.is-hidden {
		display: none;
	}
}

.webchat-container {
	display: none;
	overflow: hidden;
	position: fixed;
	bottom: 0;
	z-index: 99;
	height: 60vh;
	width: calc(100% - $bsu);
	left: $ssu;
	right: $ssu;
	border: none;

	&.is-showing {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: column;
	}

	@include bp($webchat-bp) {
		max-width: 460px;
		left: auto;
	}

	* {
		max-width: 100%;
	}
}

.webchat-content {
	height: 100%;
	width: 100%;
	border: 1px $grey solid;
	border-radius: 8px;
	overflow: hidden;
}

.webchat-close-button {
	display: block;
	padding: $ssu 0;
	color: $white;
	background: $brand;
	border-radius: 100%;
	cursor: pointer;
	border: 0;
	width: 40px;
	height: 40px;
	font-size: 24px;
	margin-top: $ssu;
	margin-bottom: $bsu;
}
