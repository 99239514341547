// COMPONENT.HUBMNEU
.c-hubmenu {
	width: 100%;
	background-color: $grey--funsion;

	position: relative;
	transform: scale(1); // Create a new stacking context
}

.c-hubmenu__items {
	max-width: $layout-wrapper-narrow-width;
	padding: $bsu 0;
	margin: 0 auto;
	display: none;
	background-color: $white;

	@include bp-below('large') {
		&[aria-expanded="true"] {
			height: calc(100vh - #{$header-height - 44px});
			display: block;
		}
	}

	@include bp("large") {
		padding: 0;
		width: 100%;
		display: flex;
		align-items: center;
		background-color: transparent;
		height: auto;

		:focus-within > & { // :focus-within is not supported by IE
			display: flex;
		}

		&[aria-expanded="true"] {
			height: auto;
		}
	}
}

.c-hubmenu__item {

	@include bp('large') {
		display: flex;
		align-items: center;

		&[aria-current="true"] > a {
			color: $brand;
		}
	}

	&.c-hubmenu__item--dropdown {
		position: relative;
	}
}

.c-hubmenu__item--current {
	@include button-reset;

	@include font-heading-16b-cap;
	color: $grey--funsion;
	@include bp("large") {
		color: $white;
	}
	&:hover,
	&:focus {
		color: $brand;
	}
	padding: ($bsu - $tsu);
	display: block;
	position: relative;
}

button.hubmenu__link {
	@include button-reset;
}
.c-hubmenu__link {
	@include font-heading-18b;
	padding: $ssu $lsu;
	color: $grey--funsion;
	display: block;
	cursor: pointer;
	position: relative;
	border-bottom: 1px solid $grey--pale;

	@include bp('large') {
		@include font-heading-14b;
		color: $white;
		padding: $bsu ($bsu + $ssu) $bsu $bsu;
		border: 0;
	}

	&:hover {
		color: $brand;
	}
}

.c-hubmenu__expander {
	position: absolute;
	right: $lsu;
	top: $ssu + 5;
	font-size: 13px;
	color: $grey--dark;
	transform: rotate(-90deg);
	transition: all ease-in 0.3s;

	@include bp('large') {
		display: block;
		right: $ssu;
		top: 50%;
		transform: translateY(-50%) rotate(0);
		color: $white;
	}

	.c-hubmenu__item[aria-expanded="true"] & {
		transform: rotate(0);

		@include bp('large') {
			transform: translateY(-50%) rotate(0);
		}
	}
}

.c-hubmenu__dropdown-nav {
	display: none;
	width: 100%;
	padding: $ssu $lsu;

	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('large') {
		background-color: $grey--light;
		border-radius: $border-radius;
		position: absolute;
		left: 0;
		display: block;
		width: 290px;

		max-height: 0;
		visibility: hidden;
		transition: all 0.5s ease-in-out;

		overflow: hidden;
		text-align: left;
		z-index: 0;
		top: 100%;
		box-shadow: 3px 3px 5px rgba($black, 0.2);
		transform: translateX(-30%);
		padding: 0;


		&.allow-animations,
		.allow-animations > & {
			animation: 0.5s becomeHidden forwards;
		}
		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 600px;
		}

		&[aria-expanded="true"].allow-animations,
		[aria-expanded="true"].allow-animations > & {
			animation: 0.5s becomeVisible forwards;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}

		.c-navigation__item:last-child & {
			left: -158px;
		}
	}

	@include bp('extra-large') {
		.c-hubmenu__item:last-child & {
			left: 0;
		}
	}
}

.c-hubmenu__dropdown-items {
	@include bp('large') {
		padding: $ssu;
	}
}

.c-hubmenu__dropdown-item {
	display: block;
}

.c-hubmenu__dropdown-link {
	@include font-heading-14;
	color: $grey--darkest;
	padding: $tsu 0;
	border-radius: $border-radius;
	display: block;

	&[aria-expanded="true"],
	&:hover {
		color: $brand;
		background-color: $white;
	}

	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: $ssu $bsu;

		&::before {
			@include abs-center-v;
			right: $bsu;
			display: block;
			color: $brand--alt;
			opacity: 0;
			transition: all 0.3s ease;
		}

		&:hover::before {
			right: $ssu;
			opacity: 1;
		}
	}
}

.c-hubmenu__mobile-split {
	@include flex-extend;
	justify-content: space-between;

	background-color: $grey--funsion;
	color: $white;

	@include bp("large") {
		display: none;
	}
}

.c-hubmenu__mobile-link {
	flex-grow: 1;

	@include font-heading-16b-cap;
	padding: $ssu $lsu;
	text-align: left;

	color: $white;
}

.c-hubmenu__mobile-toggle {
	flex-grow: 0;

	@include button-reset;
	@include font-heading-16b-cap;
	padding: $ssu $lsu;

	display: flex;
	align-items: center;
	justify-content: center;

	color: $white;

	border-left: 1px solid rgba($white, 0.2);

	&[aria-expanded="true"] {
		.c-hubmenu__mobile-toggle-icon {
			transform: rotate(90deg);
		}
	}
}

.c-hubmenu__mobile-toggle-icon {
	@include link-transition;
}
