﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery {

	.flickity-prev-next-button {
		top: calc(50% - 29px);
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: $brand;
		border-radius: $border-radius;
	}

	.flickity-prev-next-button .flickity-button-icon {
		width: 50%;
		height: 50%;
		position: static;
	}
}

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;

	.c-image-gallery__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-gallery__caption-text {
	flex: 1 0 auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp('medium') {
		@include font-base-12b;
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}

