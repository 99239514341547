﻿// THEME
$white: #fff;
$black: #000;

$grey--lightest: #f2f2f2;
$grey--light: #f9f9f9;
$grey--pale: #efefef;
$grey: #cccccc;
$grey--mid: #666666;
$grey--dark: #333;
$grey--darkest: #1C1A1A;
$grey--funsion: #3B444A;


$blue: #1F3441;
$blue--button: #233C4C;
$blue--dark: #06334C;

$skyblue--lightest: #EAF4F7;
$skyblue--light: #8AD4E3;
$skyblue: #44ADB3;

$teal: #00A6b0;
$teal--dark: #006B70;

// Font Awesome dual colour icons
:root {
	--fa-primary-color: #{$teal};
	--fa-secondary-color: #{$teal--dark};

	--fa-primary-opacity: 1;
	--fa-secondary-opacity: 1;
}

$red: #C32032;
$red--dark: #780c15;

$orange: #FD7B23;
$orange--dark: #C66E36;

$brand: $orange;
$brand--alt: $orange--dark;
$brand--alt-light: $orange;

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $black;
$body-grey-color: #333;
$base-ui-color: $grey--light;

// Main Colours
$primary: $brand;
$primary-text: $white;
$secondary: darken($brand, 10);
$secondary-text: $white;

// Headings
$heading: $brand;

// Buttons
// Variants are: Primary - Light - Outline
$btn-bg: $brand;
$btn-text: $white;
$btn-bg-hover: darken($brand, 10);
$btn-text-hover: $white;
$btn-light-bg: $white;
$btn-light-text: $brand;
$btn-light-bg-hover: darken($brand, 10);
$btn-light-text-hover: $white;
$btn-outline-bg: $white;
$btn-outline-text: $brand;
$btn-outline-border: $brand;
$btn-outline-bg-hover: darken($brand, 10);
$btn-outline-text-hover: $white;
$btn-outline-border-hover: darken($brand, 10);

// Links
$link-text: $brand;
$link-text-hover: darken($brand, 10);