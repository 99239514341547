// COMPONENT.FOOTER

$footer-logo-width: 150px;

.c-footer {
	position: relative;
	background-color: $brand;
}

.c-footer__wrapper {
	background-color: $grey--funsion;
}

.c-footer__primary {
	padding: $lsu 0;

	@include bp('medium') {
		@include flex-extend;
		padding: $lsu 0 $bsu;
	}

	> .o-grid {
		width: 100%;
	}
}

.o-grid__item-last {
	.c-footer & {
		@include bp("medium") {
			margin-left: auto;
		}
	}
}

.c-footer__secondary {
	padding: $bsu 0;
	@include flex-extend;
	flex-direction: row;
	justify-content: center;

	@include bp('medium') {
		@include flex-extend;
		flex-direction: row;
		align-items: flex-start;
		justify-content: initial;
		padding: 0;
	}

}

.c-footer__logo {
	display: block;
	width: $footer-logo-width;
	padding: 0;
	margin: 0 auto;
	text-align: center;

	img {
		width: 100px;
	}

	@include bp('medium') {
		display: block;
		align-self: flex-start;
		margin: 0 0 $bsu;
		text-align: left;
	}
}

.c-footer__live-chat {
	margin-bottom: $bsu;
	text-align: center;
	@include bp('medium') {
		text-align: left;
	}
}

.c-footer__signup {
	padding: $bsu 0;

	.c-form__field-label {
		color: $white;
	}
}

.c-footer__signup-fields {
	@include list-reset;

	@include flex-extend;
	flex-direction: column;
	margin-top: -$bsu;
	@include bp("small") {
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -$bsu;
	}
	@include bp("medium") {
		flex-wrap: nowrap;
	}
}

.c-footer__signup-field,
.c-footer__signup-submit {
	flex-grow: 1;

	padding-top: $bsu;
	padding-left: $bsu;

	@include bp("small") {
		width: 50%;
	}
	@include bp("medium") {
		width: auto;
	}
}

.c-footer__signup-submit {
	@include flex-extend;
	align-items: flex-end;
}

.c-footer__navigation {
	display: flex;
	flex-direction: column;


	@include bp('medium') {
		display: flex;
		width: 100%;
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: 0;
	}

	@include bp('large') {
	}
}

.c-footer__navigation-link {
	@include font-base-14b;
	display: block;
	padding: $tsu 0;
	color: $white;
	outline: none;

	&:focus,
	&:hover {
		color: $brand;
		text-decoration: none;
	}
}

.c-footer__partners-list {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.c-footer__partners-list-item {
	width: calc( 50% - #{$ssu});
	margin-bottom: $bsu;
	margin-right: $ssu;

	@include bp('large') {
		width: calc( 33% - #{$ssu});
	}
}

.c-footer__partner__logo {
	width: 100%;
	filter: brightness(0) invert(1);
}


.c-footer__secondary-nav--wapper {
	@include flex-extend;
	flex-direction: column;
	padding-top: $bsu;
	padding-bottom: $bsu;
	margin-bottom: $bsu;
	border-bottom: 2px solid $brand;
	text-align: center;

	@include bp('medium') {
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		text-align: left;
	}
}

.c-footer__social {
	margin-top: $bsu;
	margin-bottom: $bsu;
	display: flex;
	flex-direction: row;
	justify-content: center;

	@include bp('medium') {
		flex: 0 0 auto;
		width: auto;
		padding-left: 0;
		margin-bottom: $bsu;
		justify-content: flex-start;
	}

	@include bp('large') {
		width: auto;
		padding-left: 0;
	}
}

.c-footer__social-heading {
	display: block;
	margin-bottom: $ssu;
	color: $white;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $ssu;
	margin-bottom: 0;
}

.c-footer__social-link {
	@include circle(40px);
	@include link-transition;
	border: 0;
	font-size: 30px;
	color: $grey--light;
	background-color: transparent;
	border-color: transparent;

	&:hover,
	&:focus {
		color: $brand;
		background-color: transparent;
	}
}

.o-btn--chat {
	.c-footer__wrapper & {
		@include font-base-14b;

		border: 1px solid $brand;

		&:hover {
			background-color: $white;
			color: $brand;
		}
	}
}

.o-btn__icon {
	.c-footer & {
		display: inline-block;
		margin-right: $ssu;
	}
}

.c-footer__heading {
	@include font-heading-10l--cap;
	color: $white;
	margin-bottom: $bsu;
}


.c-footer__offices {
	@include font-heading-14;
	width: 100%;
	color: $white;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;


	@include bp("medium") {
		justify-content: inherit;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.c-footer__offices p{
	margin-bottom: $tsu;
}

.c-footer__office-phone {
	@include font-heading-14b;
}

.c-footer__offices a {
	@include font-heading-14b;
	color: $white;
	display: block;

	@include bp("medium") {
		display: inline-block;
	}

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-footer__office-location {
	@include bp("extra-large") {
		margin-left: $bsu;
	}
}

.c-footer__legal {
	margin-bottom: 0;
	text-align: center;

	@include bp('small') {
		@include flex-extend;
		align-items: center;
		//justify-content: space-between;
	}
}

.c-footer__legal a {
	color: $skyblue;
}

.c-footer__legal-copyright {
	@include font-base-12l;
	display: block;
	margin-bottom: 0;
	color: $white;

	@include bp('small') {
		display: inline;
		margin: 0;
	}
}

.c-footer__legal-links-item {
	display: inline-block;
	padding: 0 $tsu;
	margin: 0 $tsu;
}

.c-footer__legal-link {
	@include font-base-12l;
	color: $white;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include font-base-12l;
	display: block;
	text-align: center;
	color: $white;
	outline: none;
	margin-left: $ssu;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__sticky {
	@include flex-extend;

	@include bp("large") {
		display: none !important;
	}

	position: fixed;
	z-index: 1;
	left: 0;
	bottom: 0;
	width: 100%;
	background: rgba($white, 0.8);
	padding: $tsu 0;
	align-items: center;
	justify-content: flex-end;
}

.c-footer__sticky-button {
	display: block;
	margin: 0 $tsu;
	width: 50%;
}

.c-footer__sticky-spacer {
	@include bp("medium") {
		display: none;
	}

	height: 50px;
}

.c-footer-back-to-top-container {
	position: relative;
}

.c-footer-back-to-top {
	display: block;
	position: fixed;
	bottom: 60px;
	right: 20px;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 54px;
	height: 54px;
	font-size: 32px;
	color: $white;
	background: $brand;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;
	border-radius: $border-radius;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 16px;
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp('large') {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		background: transparent;
		color: $grey--darkest;
		border: 1px solid $grey--darkest;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: absolute;
				bottom: auto;
				top: -50px;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				color: $white;
				border: none;
				background: $brand;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
