// CONTROL.RESOURCES

.c-resources {}

.c-resources__list {
	@include list-reset;

	@include flex-extend;
	align-items: stretch;
	flex-wrap: wrap;

	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-resources__item-layout {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 100%;
}

.o-layout-content--has-aside {
	.c-resources__item-layout {
		width: 100%;
	}
}

.c-resources__item {
	@include flex-extend;
	align-items: stretch;

	@include bp-below("medium") {
		flex-direction: column;
	}
}

.c-resources__media {
	@include figure;

	@include bp("medium") {
		width: 100px;
	}
}

.c-resources__body {
	flex-grow: 1;
	@include flex-extend;

	flex-direction: column;
	align-items: stretch;
	@include bp("medium") {
		flex-direction: row;
		align-items: center;
	}

	padding: $ssu $ssu $ssu #{$ssu + $tsu};
	background: $grey--lightest;
}

.c-resources__details {
	flex-grow: 1;
}

.c-resources__brand {
	display: block;
	color: $brand;
	@include font-12b;
	margin-bottom: $tsu;
}

.c-resources__title {
	@include font-16b;
}

.c-resources__type-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -15px;
	margin-top: -$ssu;

	@include bp-below("medium") {
		padding-bottom: $ssu;
	}
}

.c-resources__type {
	padding-left: 15px;
	padding-top: $ssu;

	@include flex-extend;
	align-items: center;
}

.c-resources__type-label {
	@include flex-extend;
	align-items: center;
	cursor: pointer;

	.c-fake-checkbox__display {
		margin-right: $tsu;
	}
}

.c-resources__type-text {
	@include font-14;
}


.c-resources__actions {
	@include flex-extend;
	align-items: center;

	@include bp("medium") {
		margin-left: $ssu;
	}
}
