// COMPONENT.PRODUCT-TILE

.c-product-tile {
	flex-direction: column;
	justify-content: space-between;
}

.c-product-tile__subheading {
	display: block;
	margin-bottom: 5px;
}

.c-product-tile__body {
	flex: 1 0 auto;
	margin-bottom: $ssu;
}

.c-product-tile__actions {
	text-align: center;
}