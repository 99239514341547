﻿// CONTROL.PODS
.c-pod__heading {
	@include font-heading-20b;
	@include bp("medium") {
		@include font-heading-24b;
	}
}

.c-pods__introduction {
	display: block;
	margin-bottom: $bsu;
}

// IMAGE POD
.c-pod__figure {
	position: relative;

	// Create new stacking context
	transform: scale(1);

	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 6px;

	> img {
		position: absolute;
		left: 50%;
		top: 50%;
		width: auto;
		min-width: 101%;
		min-height: 101%;
		transform: translate(-50%, -50%);
		transition: transform 0.2s ease-in-out;
	}

	&:hover,
	&:focus {
		> img {
			transform: translate(-50%, -50%) scale(1.05);
		}
	}
}

button.c-pod__figure {
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
}

.c-pod__body {
	background-color: rgba($black, 0.45);
	transition: background-color 0.2s ease-in-out;

	// If there's no image
	&:first-child {
		background: none;
	}
}
.c-pod__figure {
	&:hover,
	&:focus {
		.c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pod__text {
	display: none;

	max-height: 0;
	overflow: hidden;
	margin: 0;
	transition: 0.4s ease;
	transition-property: max-height;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}
.c-pod:hover,
.c-pod__figure:focus {
	.c-pod__text {
		max-height: 200px;
		animation: 0.4s becomeVisible forwards;
	}
}



.c-pod__image-fallback {
	width: 100%;
	height: 100%;

	background: $brand;
	.c-pod:hover &,
	.c-pod__figure:focus & {
		background: $brand--alt;
	}

	+ .c-pod__body {
		background: transparent;
	}
}

// NO IMAGE POD
.c-pod-noimage {
	background: $brand;
	border-radius: 6px;
}

.c-pod-noimage__heading {
	@include font-heading-24b;
}


a.c-pod-noimage {
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background: darken($brand, 10);
	}
}

.c-pod-noimage__body {
	padding: $bsu;
	color: $white;
}

.c-pod-noimage__text {
	display: none;

	max-height: 0;
	overflow: hidden;
	margin: 0;
	transition: 0.4s ease;
	transition-property: max-height;

	@media (prefers-reduced-motion) {
		transition: none;
	}

	animation: 0.4s becomeHidden forwards;
}
.c-pod-noimage:hover,
.c-pod-noimage:focus {
	.c-pod-noimage__text {
		max-height: 200px;
		animation: 0.4s becomeVisible forwards;
	}
}


.c-pod--cta {
	.c-pod__body {
		color: $brand;
	}
}

.c-pod--small {
	height: 180px;

	.c-pod__body {
		padding: $bsu;
	}

	.c-pod__heading {
		@include font-heading-20b;
		@include bp("medium") {
			@include font-heading-24b;
		}
	}
}
