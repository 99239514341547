﻿// CONTROL.EXPAND-COLLAPSE

.c-expand-collapse__item {
	margin-bottom: $bsu;
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;
	outline: none;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-heading {
	@include font-base-16sb;
}

.c-expand-collapse__item-btn {
	margin: $bsu 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.c-expand-collapse__item-icon {
	font-size: 15px;
	margin-right: $tsu;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {

		&:before {
			content: $iconf-minus;
		}
	}
}

.c-expand-collapse__item-btn--open {
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: none;
	}
}

.c-expand-collapse__item-btn--close {
	display: none;
	.c-expand-collapse__item[aria-expanded="true"] & {
		@include flex-extend;
	}
}

.c-expand-collapse__item-body {
	padding: $ssu 0 $bsu;

	.js & {
		display: none;
	}

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
