﻿// CONTROL.CONTENT-PATHWAY

.c-content-pathway {
	background-color: $white;
	background: linear-gradient(to top, rgba($skyblue--lightest, 1) 0%,rgba($white,0) 100%);
	border-radius: 6px;
	overflow: hidden;
}

.c-content-pathway-wrapper {
	@include bp('medium') {
		@include flex-extend;
		padding: 0 $bsu;
	}
}

.c-content-pathway--right {

	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-content-pathway__figure {
	position: relative;

	@include bp('medium') {
		flex: 0 0 50%;
		align-self: center;
		margin-bottom: 0;
	}
}

.c-content-pathway .c-image-feature__image {
	background-color: transparent !important;

	@include bp('medium') {
		height: 100%;
	}
}

.c-content-pathway__body {
	@include font-base-16;
	padding: $bsu;
	text-align: left;
	width: 100%;
	position: relative;

	@include bp('medium') {
		align-self: center;
		padding: ($bsu * 3) ($bsu * 2);
	}

	@include bp('large') {
		padding: $bsu * 4;

		.o-layout-content--has-aside & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-content-pathway__heading {
	@include font-heading-20b;
	position: relative;
	margin-bottom: $bsu;
	color: $grey--darkest;

	@include bp('large') {
		@include font-heading-30b;
	}

	&::after {
		content: '';
		display: block;
		width: $lsu;
		height: 3px;
		background-color: $brand--alt;
		margin: $bsu 0;
	}
}

.c-content-pathway__text {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $bsu * 1.5;
	}

	p,
	li {
		@include font-body-text;
		b, strong {
			font-weight: $weight--bold;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}