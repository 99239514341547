// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;
$mobileHeader-height: ($header-height - 45px);

.c-navigation {
	position: relative;
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp('medium') {
		order: 2;
	}

	@include bp('large') {
		position: relative;
		display: block;
		//align-self: flex-end;
		width: auto;
		background-color: transparent;
	}

	@include bp('extra-large') {
		margin-left: 5%;
		margin-top: 0;
	}

	&[aria-expanded="true"] {
		display: flex;
		position: absolute;
		top: $mobileHeader-height;
		left: 0;
		height: calc(100vh - #{$mobileHeader-height});
		flex-direction: column;
		justify-content: space-between;
		overflow: scroll;
	}
}

.c-navigation__wrapper {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: 0;
	}
}

.c-navigation__items {
	padding-top: $tsu;
	background-color: $white;

	@include bp('large') {
		width: 100%;
		display: table;
		padding: 0;

		:focus-within > & { // :focus-within is not supported by IE
			display: block;
		}
	}
}

.c-navigation__item {
	@include ui-border('bottom');
	position: relative;

	&.c-navigation__item-home {
		@include ui-border('top');
	}

	&[aria-expanded="true"] {
		background-color: $white;
	}


	@include bp('large') {
		display: table-cell;
		width: 1%;
		border: 0;
		text-align: center;
		vertical-align: bottom;

		&[aria-expanded="true"] {
			background-color: transparent;
		}

		&[aria-current="true"] > a{
			color: $brand;
		}
	}
}

.c-navigation__link {
	@include font-heading-18b;
	display: block;
	padding: $ssu $bsu $ssu $lsu;
	color: $grey--darkest;
	cursor: pointer;

	@include bp('large') {
		@include font-heading-15b;
		color: $grey--darkest;
		display: inline-block;
		padding: ($bsu - $tsu);
		white-space: nowrap;
	}

	@include bp('extra-large') {
		@include font-heading-16b;
		display: inline-block;
		padding: $bsu ($bsu + $ssu) $bsu $ssu;
		white-space: nowrap;
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp('large') {
		line-height: 1;
	}
}

.c-navigation__expander {
	position: absolute;
	left: inherit;
	right: $ssu + 5;
	top: $ssu + 5;
	font-size: 13px;
	color: $grey--dark;
	transition: all ease 0.3s;
	transform: rotate( -90deg );

	&[aria-hidden="true"] {
		&:before {
			content: $iconf-arrow-down;
		}
	}

	.c-navigation__item[aria-expanded="true"] & {
		transform: rotate(0);

		@include bp('large') {
			transform: rotate(0) translateY(-50%);
		}
	}

	@include bp('large') {
		display: block;
		left: inherit;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

	}

	@include bp('extra-large') {
		right: $ssu;
	}
}

.c-navigation__megamenu__heading {
	@include bp('large') {
		&::before {
			content: '';
			display: none;
			width: calc(100% - 50px);
			height: 4px;
			border: 0;
			position: absolute;
			left: 20px;
			bottom: 0;
			transition: all 0.5s ease-in-out;
			background-color: $brand;
		}

		[aria-expanded="true"] & {
			&::before {
				display: block;
			}
		}
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp('large') {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);

		:focus-within > & { // :focus-within is not supported by IE
			display: block;
		}
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp('large') {
		@include ui-border('bottom');
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__skiplink {
	background-color: $red;
	color: $white;
	padding: 5px 10px;
	margin: 5px;
	position: fixed;
	top: 5px;
	left: 5px;
	z-index: 1000;
	border-radius: 5px !important;
	border: 1px solid $red;
	opacity: 0;
	transition: all 0.5s ease;

	&:focus {
		opacity: 1;
	}
}

// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp('medium') {
		display: none;
	}
}