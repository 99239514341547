﻿/*======================================
  Selectric v1.13.0
======================================*/

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	@include font-heading-24b;
	color: $brand;
	border: none;
	border-bottom: 1px solid $brand;
	background: none;
	cursor: pointer;
	appearance: none;
	padding: $ssu $lsu $ssu 0;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@include bp('large') {
		@include font-heading-50b;
	}
}

.selectric .label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0 38px 0 10px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric .button {
	display: none;
}

.selectric-open {
	z-index: 9999;
}

.selectric-open .selectric {}

.selectric-open .selectric-items {
	display: block;
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
}

.selectric-hide-select select {
	position: absolute;
	left: -100%;
}

.selectric-hide-select.selectric-is-native {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	border: none;
	z-index: 1;
	box-sizing: border-box;
	opacity: 0;
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

/* Items box */
.selectric-items {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	background: $white;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
}

.selectric-items .selectric-scroll {
	height: 100%;
	overflow: auto;
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.selectric-items ul, .selectric-items li {
	list-style: none;
	padding: 0;
	margin: 0;
	@include font-heading-16b;
	min-height: 20px;
}

.selectric-items li {
	display: block;
	padding: 10px;
	color: $body-color;
	cursor: pointer;
}

.selectric-items li.selected {
	background: $grey--lightest;
	color: $body-color;
}

.selectric-items li.highlighted {
	background: $grey--lightest;
	color: $body-color;
}

.selectric-items li:hover {
	background: $grey--lightest;
	color: $body-color;
}

.selectric-items .disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default !important;
	background: none !important;
	color: #666 !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
	font-weight: bold;
	padding-left: 10px;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background: none;
	color: $body-color;
}

.selectric-items .selectric-group.disabled li {
	filter: alpha(opacity=100);
	opacity: 1;
}

.selectric-items .selectric-group li {
	padding-left: 25px;
}
