// CONTROL.RESOURCES-INSIGHTS

.c-insights-resources {
	background-color: $white;
	margin-bottom: $bsu;
	height: 360px;
	border: 1px solid $grey--pale;
}

.c-insights-resources.has-image .c-pod__figure {

	&:hover,
	&:focus {
		.c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pods__type-blog {
	background-color: $white;

	.o-fit {
		display: none;
		visibility: hidden !important;
	}

	&.pod__featured {
		background-image: url(/Content/images/interface/bg/f5_small_wave_bg.png);
		background-position: center right, center;
		background-repeat: no-repeat;
		background-size: 75%, cover;
	}
}

.c-pods__type-news {
	background-color: $white;
	background: linear-gradient(180deg, rgba($grey--darkest,1) 0%,rgba($grey--funsion,1) 100%);

	.o-fit {
		display: none;
		visibility: hidden !important;
	}

	&.pod__featured {
		background: url(/Content/images/interface/bg/f5_small_wave_bg.png) no-repeat, linear-gradient(180deg, $grey--darkest 0%, $grey--funsion 100%);
		background-position: center right, center;
		background-size: 75%, cover;
	}
}

.c-pod__figure {
	.c-insights-resources & {
		border-radius: 0;
	}
}

.c-insights-resources__logo {
	position: absolute;
	left: 25px;
	top: 25px;
	z-index: 2;
	display: block;
	width: 120px;
	background-color: transparent !important;

	.o-pod & {
		height: 120px;
	}

	.o-fit__image {
		object-fit: contain;
		object-position: left top;
	}
}

.c-insights-resources__img-mobile,
.c-insights-resources__img {
	.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		height: 55%;
	}

	.pod__featured.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		height: 100%;
	}

	.c-pods__type-case-study & {
		&::after {
			position: absolute;
			content: " ";
			width: 100%;
			height: 100%;
			background: linear-gradient( to bottom, rgba($black, 0) 0%, rgba($black, 0.7) 50%, rgba($black, 0.9) 100% );
			top: 0;
		}
	}
}


.c-insights-resources__body {
	.c-pods__type-blog &,
	.c-pods__type-news & {
		justify-content: flex-start;
	}

	.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		position: relative;
		padding: $bsu;
		height: 45%;
		z-index: 1;
		justify-content: center;
		overflow: initial;
	}

	.pod__featured.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		height: 100%;
	}
}

.c-pod__figure {
	.pod__featured.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		display: flex;
	}
}

.c-insights-resources__text {
	.c-pods__type-blog &,
	.c-pods__type-news & {
		max-height: 100%;
		opacity: 1;
		margin-top: $bsu;
	}

	.c-pods__type-blog & {
		color: $grey--darkest;
	}

	.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog) & {
		display: none;
	}

	.pod__featured.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		max-height: 100%;
		opacity: 1;
		margin-top: $bsu;
		color: $grey--darkest;
		display: block;
	}
}



.c-insights-resources .c-pod__body {
	@include font-base-16l;
	background-color: transparent;

	&:hover {
		background-color: transparent;
	}
}

.c-insights-resources__tag {
	@include font-base-12l;
	display: inline-block;
	padding: $tsu $ssu;
	background-color: $brand;
	color: $white;
	border-radius: ($border-radius - 5);
	margin-bottom: $bsu;
	align-self: flex-start;

	.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		position: absolute;
		top: -($ssu * 1.5);
		margin-bottom: 0;
	}

	.pod__featured.c-insights-resources:not(.c-pods__type-news):not(.c-pods__type-blog):not(.c-pods__type-case-study) & {
		position: static;
		margin-bottom: $bsu;
	}
}

.c-insights-resources__heading {
	@include font-heading-20b;

	color: $grey--darkest;
	.has-image .c-pod__figure:hover &,
	.has-image .c-pod__figure:focus & {
		color: $brand;
	}

	.c-pods__type-news &,
	.c-pods__type-case-study & {
		color: $white;
	}

	.c-insights-resources__featured {
		@include font-heading-28b;
	}
}

.c-insights-resources__date {
	@include font-base-14;

	color: $grey--darkest;
	.c-pods__type-news &,
	.c-pods__type-case-study & {
		color: $white;
	}
}

.c-related-insights__pod-image-fallback {
	background: linear-gradient(135deg, $teal 0%, $teal--dark 100%);
}
