﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	margin-top: $lsu;
	margin-bottom: $lsu;
}

.c-breadcrumb__link {
	@include link-brand;
	@include font-base-10sb;

	color: $brand--alt;
}

.c-breadcrumb__current {
	@include link-plain;
	@include font-base-10sb;
}

.o-list--inline > [data-divider]::after {
	.c-breadcrumb & {
		@include font-base-10sb;
		color: $brand--alt;
	}
}
