// COMPONENT.PRODUCT-HERO

$product-hero-bp: "large";

.c-product-hero {
	@include flex-extend;
	align-items: stretch;

	// The media goes behind the header, the detail does now
	.c-product-hero__detail {
		margin-top: $header-height;
	}
}




.c-product-hero__media {
	overflow: hidden;
	position: relative;

	height: 50vh;
	min-height: 400px;
	max-height: 80vh;

	@include bp($product-hero-bp) {
		width: calc(50vw - 80px);
		margin-right: 80px;
		flex-shrink: 0;

		height: auto;
		min-height: 600px;
		max-height: none;
	}
}

.c-product-hero__image {
	@include bp($product-hero-bp) {
		width: 100%;
		height: 100%;

		padding-bottom: 0 !important;
		height: 100%;

		> img,
		> noscript > img {
			// Adjust image position to be vertically centred, taking sticky header into account
			@supports (object-position: 100% calc(50% + #{calc($header-height / 2)})) {
				object-position: 100% calc(50% + #{calc($header-height / 2)});
			}
		}
	}
}

.c-product-hero__media-actions {
	@include list-reset;

	position: absolute;
	bottom: $bsu;
	right: $bsu;

	@include flex-extend;
}

.c-product-hero__media-action {
	padding-left: $bsu;
}




.c-product-hero__detail {
	padding: $bsu;
	@include bp($product-hero-bp) {
		width: 50vw;
		max-width: #{calc($layout-wrapper-max-width / 2)};
		padding-left: 0;
	}
}


.c-product-hero__title-split {
	@include flex-extend;
	justify-content: space-between;
}

.c-product-hero__title-wrapper {
	padding-right: $bsu;
}

.c-product-hero__brand {
	color: $brand;
	@include font-14b;
}

.c-product-hero__title {
	@include font-36b;
}

.c-product-hero__logo {
	@include figure;
	max-width: 100px;

	flex-shrink: 0;
}


.c-product-hero__detail-text {
	margin: $bsu 0;
}


.c-product-hero__detail-actions {
	@include list-reset;
	@include flex-extend;

	margin-left: -$bsu;
	margin-top: #{$lsu - $bsu};
}

.c-product-hero__detail-action {
	padding-left: $bsu;
	padding-top: $bsu;
}


.c-product-hero__detail-properties {
	margin: #{$lsu - $ssu} 0 $lsu;
}

.c-product-hero__detail-property {
	@include flex-extend;
	padding-top: $ssu;

}

.c-product-hero__detail-property-key {
	margin-right: $bsu;
	@include font-16b;

	flex-shrink: 0;
}

.c-product-hero__detail-property-value {
	@include font-16;
}
