// CONTROL.BUTTON-FILTER

.c-button-filter {
	position: relative;

	top: -$lsu;
	@include bp("large") {
		top: -$hsu;
	}

	transform: translateY(-50%);
}

.c-button-filter__form {}

.c-button-filter__layout {
	@include list-reset;
	&:not(.flickity-enabled) {
		@include flex-extend;
		align-items: center;
		justify-content: center;

		margin-left: -$bsu;
	}
	// Halved because of how it's being moved up half its height
	margin-top: -(calc($bsu / 2));

	&.flickity-enabled {
		position: relative;
		&::before,
		&::after {
			content: "";
			position: absolute;
			z-index: 1;

			left: 0;
			width: 100%;
			height: 50%;

			pointer-events: none;
		}

		&::before {
			top: 0;
			background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 40px, rgba($white, 0) calc(100% - 40px), rgba($white, 1) 100%);
		}

		&::after {
			bottom: 0;
			background: linear-gradient(to right, rgba($grey--light, 1) 0%, rgba($grey--light, 0) 40px, rgba($grey--light, 0) calc(100% - 40px), rgba($grey--light, 1) 100%);
		}
	}
}

.c-button-filter__item {
	padding-left: $bsu;
	padding-top: $bsu;
}

.c-button-filter__button {
	@include button-reset;

	padding: $bsu;
	border-radius: 8px;

	@include font-16bu;

	@include link-transition;

	box-shadow: inset 0 0 0 1px $brand;

	background: $brand;
	color: $white;
	&:hover,
	&:focus {
		background: $white;
		color: $brand;
	}

	&[aria-current="true"] {
		background: $white;
		color: $brand;

		&:hover,
		&:focus {
			background: $brand;
			color: $white;
		}
	}
}
