// CONTROL.LOGOS

.c-logos {}

.c-logos__layout {
	@include flex-extend;
	margin-left: -$hsu;
	margin-top: -$lsu;

	@include bp-below("large") {
		flex-wrap: wrap;
	}
}

.c-logos__header,
.c-logos__body {
	padding-left: $hsu;
	padding-top: $lsu;
}

.c-logos__header {
	width: 100%;
	padding-top: ($lsu + $ssu);

	@include bp("large") {
		padding-top: $lsu;
		width: percentage(calc(1/3));
	}
}

.c-logos__body {
	width: 100%;
	margin-bottom: $ssu;

	@include bp("large") {
		width: percentage(calc(2/3));
	}
}

.c-logos__heading {
	@include font-heading-24b;
	padding-top: $lsu;
	position: relative;

	@include bp("large") {
		@include font-heading-36b;
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100px;
		height: 3px;
		background: $brand;
	}

	.o-layout-content--has-aside & {
		margin-left: $bsu;

		@include bp("large") {
			margin-left: 0;
		}
	}
}

.c-logos__eyebrow {
	@include font-12bu;
}

.c-logos__list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	align-items: flex-start;

	margin-left: -$lsu;
	margin-top: -$lsu;

	padding: 0 $bsu;
	// Adjust for eyebrow height
	padding-top: #{$lsu - 15px};
}

.c-logos__item {
	padding-left: $lsu;
	padding-top: $lsu;
	width: percentage(calc(1/2));

	@include bp("small") {
		width: percentage(calc(1/3));
	}

	@include bp("medium") {
		width: percentage(calc(1/5));
	}

	@include bp("large") {
		width: percentage(calc(1/4));
	}

	@include bp("extra-large") {
		width: percentage(calc(1/5));
	}
}

.c-logos__logo {
	width: 100%;
	background-color: transparent !important;

	filter: grayscale(100%);
	&:hover {
		filter: grayscale(0%);
	}

	@include link-transition;
}
