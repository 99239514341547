// COMPONENT.PAGINATION

.c-pagination {
	@include flex-extend;
	@include font-14;
	justify-content: center;
	align-items: center;
	margin-top: $bsu;
	text-align: center;

	@include bp('large') {
		margin-top: $lsu;
	}
}

.c-pagination__desktop {
	flex-grow: 1;
}

.c-pagination__current {
	padding: 0 $bsu;

	@include bp('medium') {
		padding: 0 $lsu;
	}
}

.c-pagination__dropdown {
	padding: $ssu;
	margin: 0 $tsu;
}

// "&, a" because the class is sometimes on an <li> and sometimes on an <a>
.c-pagination__prev,
.c-pagination__number,
.c-pagination__next {
	&, a {
		@include link-brand;
		@include link-transition;
		@include font-16b;
		padding: 0 $tsu;
		white-space: nowrap;
	}

	&:first-child {
		&, a {
			padding-left: 0;
		}
	}

	&:last-child {
		&, a {
			padding-right: 0;
		}
	}
}

.c-pagination__nav-text {
	display: none;

	@include bp('small') {
		display: inline;
	}
}
