﻿// OBJECT.ICON-WRAPPER

.o-icon-wrapper {
	position: relative;
	display: block;

	&::before {
		@include abs-center;
	}
}
