﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	background-color: $grey--light;
	border-radius: 6px;
	overflow: hidden;

	@include bp('medium') {
		@include flex-extend;
		padding: 0;
	}
}

.c-image-feature--right {
	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	position: relative;

	&::before {
		display: none;

		.c-image-feature--right & {
			display: none;
		}
	}

	&::after {
		display: none;
	}

	@include bp('medium') {
		flex: 0 0 60%;
		align-self: stretch;
		margin-bottom: 0;

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}
	}
}

.c-image-feature__image {
	@include bp('medium') {
		height: 100%;
	}
}

.c-image-feature__body {
	@include font-base-16;
	padding: $bsu;
	text-align: left;
	width: 100%;
	position: relative;

	@include bp('medium') {
		padding: ($bsu * 3) ($bsu * 2);
	}

	@include bp('large') {
		padding: ($bsu * 3) ($bsu * 4);

		.o-layout-content--has-aside & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}

	&::before {
		content: "";

		position: absolute;
		top: 0;
		left: $bsu;
		right: $bsu;
		@include bp('medium') {
			left: $bsu * 2;
			right: $bsu * 2;
		}
		@include bp('large') {
			left: $bsu * 4;
			right: $bsu * 4;
			.o-layout-content--has-aside & {
				left: $bsu * 2;
				right: $bsu * 2;
			}
		}

		height: 3px;

		background: $orange;
	}

	align-self: flex-start;
	.c-image-feature--right & {
		align-self: flex-end;
	}
}

.c-image-feature__heading {
	@include font-heading-20b;
	position: relative;
	margin-bottom: $bsu;
	color: $body-color;

	@include bp('large') {
		@include font-heading-30b;
	}
}

.c-image-feature__eyebrow {
	display: block;
	margin: 0 0 $ssu;

	@include font-base-14;
}

.c-image-feature__text {
	margin-bottom: $bsu;
	color: $black;

	@include bp('large') {
		margin-bottom: $bsu * 1.5;
	}

	p {
		@include font-body-text;
	}

	*:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__link {
	&,
	&:hover,
	&:focus {
		background: none;
	}

	padding: 0;

	@include font-base-14b;
	@include link-brand;

	&::after {
		display: inline-block;
		vertical-align: middle;
		margin-left: $bsu;

		@include font-icon;
		content: $iconf-arrow-right;
	}
}

// DARK VERSION
.o-theme-dark {

	&.c-image-feature {
		background-color: $grey--funsion;
	}

	.c-image-feature__eyebrow {
		color: $white;
	}
}
