// CONTROL.TRIPLE-PODS

.c-triple-pods {}

.c-triple-pods__items {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -$lsu;
	margin-top: -$lsu;
	@include bp("medium") {
		margin-left: -$hsu;
		margin-top: -$hsu;
	}
}

.c-triple-pods__item {
	padding-left: $lsu;
	padding-top: $lsu;

	@include bp("medium") {
		padding-left: $hsu;
		padding-top: $hsu;
	}

	width: 100%;

	@include bp("small") {
		width: percentage(calc(1/2));
	}

	@include bp("medium") {
		width: percentage(calc(1/3));
	}

	.o-layout-content--has-aside & {
		@include bp("medium") {
			width: percentage(calc(1/2));
		}

		@include bp("extra-large") {
			width: percentage(calc(1/3));
		}
	}
}

.c-triple-pods__item-body {
	@include flex-extend;
	flex-direction: column;
	align-items: stretch;

	height: 100%;

	text-align: center;

	> :last-child {
		margin-bottom: 0;
	}
}

.c-triple-pods__item-icon {
	display: block;
	margin-bottom: $lsu;

	font-size: 80px;
}

.c-triple-pods__item-image {
	display: block;
	width: 100%;
	max-width: 250px;
	margin: 0 auto $bsu;

	> .o-fit {
		padding-bottom: 100%;
		> img {
			object-fit: contain;
		}
	}
}

.c-triple-pods__item-heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20b;
}

.c-triple-pods__item-text {
	flex-grow: 1;

	display: block;
	margin-bottom: $bsu;

	@include font-body-text;
}

.c-triple-pods__item-embed {
	// Eloqua style overrides
	.submit-button-style {
		@include font-14b;

		display: block !important;
		margin: 0 auto !important;

		border: none !important;
		border-radius: 10px !important;
		padding: $ssu $bsu !important;
		box-shadow: inset 0 0 0 1px $brand !important;

		background: $brand !important;
		color: $white !important;

		transition: all 0.3s ease-in-out !important;
		&:hover,
		&:focus {
			background: $white !important;
			color: $brand !important;
		}
	}
}



.c-triple-pods__item--standard {}

.c-triple-pods__item--download {
	.c-triple-pods__item-heading {
		color: $brand;
	}
}
