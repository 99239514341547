// OBJECT.INPUT-COMBO

.o-input-combo {
	display: table;
	width: 100%;
}

.o-input-combo__input,
.o-input-combo__btn {
	display: table-cell;
	vertical-align: middle;
}

.o-input-combo__input {
	width: 100%;
}

.o-input-combo__btn {
	width: 1%;
}
