// CONTROL.CASE-STUDy

.c-case-study {
	overflow: hidden;

	@include bp('large') {
		@include flex-extend;
		padding: 0;
		flex-direction: row-reverse;
		position: relative;
	}
}

.c-case-study__figure {
	position: relative;
	width: 100%;

	@include bp('large') {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
		position: absolute;
		left:0;
		width: 60%;
		z-index: 1;

		&::before {
			display: block;
		}

		&::after {
			display: none;
		}
	}
}

.c-case-study__image {

	@include bp('medium') {
		height: 100%;
	}
}

.c-case-study__body {
	@include font-base-16;
	padding: $bsu;
	text-align: left;
	width: 100%;
	position: relative;
	background: $grey--dark;
	color: $white;
	border-radius: 0 0 6px 6px;

	@include bp('medium') {
		z-index: 0;
		padding: $lsu;
		align-self: stretch;
	}

	@include bp('large') {
		width: 50%;
		border-radius: 6px;
		//padding: $bsu * 4;
		padding: 40px 40px 40px 130px;

		.o-layout-content--has-aside & {
			padding: 30px $lsu 30px ($bsu * 5);
			//padding: ($bsu * 3) ($bsu * 2);
		}
	}

	@include bp('extra-large') {
		padding: 65px 65px ($bsu * 5) ($lsu * 5);
		min-height: 500px;

		.o-layout-content--has-aside & {
			padding: 65px 65px ($bsu * 4) ($bsu * 9);
			min-height: 400px;
			//padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-case-study__heading {
	@include font-heading-20b;
	position: relative;
	margin-bottom: $bsu;
	color: $white;

	@include bp('large') {
		@include font-heading-30b;
	}
}

.c-case-study__text {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $bsu * 1.5;
	}

	p,
	li {
		@include font-body-text;
		b, strong {
			font-weight: $weight--bold;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}
