#ss360-results {
	.ss360-suggests__content {
		padding: $bsu;
	}

	.ss360-suggests__header {
		@include font-heading-20b;
	}
}

.c-ss360__search-field {
	@extend .c-header__search-field;
}

.c-ss360__search-input {
	@extend .c-header__search-input;
}

.c-ss360__search-btn {
	@extend .c-header__search-btn;
}
