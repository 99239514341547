﻿// CONTROL.SUBNAV

$selected-accent-width: 5px;

.c-subnav__heading {
	@include font-20b;
	display: block;
	margin-bottom: 0.7em;
	color: $body-color;
}

.c-subnav__items {
	@include font-14;
	@include ui-border('top');
	border-radius: 6px;
	box-shadow: 0 0 6px rgba($black, 0.2);
}

.c-subnav__item {
	@include ui-border('bottom');
	background-color: $white;

	&.is-selected {
		position: relative;

		&:before {
			@include pseudo-element;
			left: 0;
			top: -1px;
			width: $selected-accent-width;
			height: calc(100% + 2px);
			background-color: $brand;
		}
	}
}

.c-subnav__link,
.c-subnav__child-link {
	@include link-transition;
	color: $body-color;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-subnav__link {
	display: block;
	padding: $ssu $bsu;
	padding-left: $bsu + $selected-accent-width;

	.is-selected > & {
		background-color: $skyblue--lightest;
		@include font-14b;
	}
}

.c-subnav__children {
	padding: $ssu $bsu;
	padding-left: ($bsu * 1.5) + $selected-accent-width;
}

.c-subnav__child {
	margin-bottom: 0.75em;

	&:last-child {
		margin-bottom: 0;
	}
}

.c-subnav__child-link {
	color: $body-color;
}