.o-eloqua-form {
	.elq-form .elq-item-input,
	.elq-form .elq-item-select,
	.elq-form .elq-item-textarea {
		background-color: $white !important;
		color: $body-color !important;
		border-radius: 6px;
		border: 1px solid $black !important;
		padding: $ssu $ssu;

		.o-theme-primary &,
		.o-theme-dark & {
			color: $black !important;
			border: 1px solid $black !important;
		}
	}

	.elq-form .elq-label {
		@include font-heading-16b;
		color: $black !important;
		margin-bottom: $tsu;

		.o-theme-primary &,
		.o-theme-dark & {
			color: $white !important;
			border: 0 !important;
		}
	}

	.elq-form .elq-item-label {
		@include font-base-14l;
	}

	.elq-form .row.single-checkbox-row {
		color: $body-color !important;

		.o-theme-primary &,
		.o-theme-dark & {
			color: $white !important;
		}
	}

	.form-element-instruction {
		@include font-base-14;
		color: $body-color !important;

		.o-theme-primary &,
		.o-theme-dark & {
			color: $white !important;
		}
	}

	.elq-form .elq-required {
		color: $red !important;
	}

	.elq-form .LV_invalid {
		color: lighten($red, 15%);
	}

	.elq-form .LV_invalid_field,
	.elq-form input.LV_invalid_field:active,
	.elq-form input.LV_invalid_field:hover,
	.elq-form textarea.LV_invalid_field:active,
	.elq-form textarea.LV_invalid_field:hover {
		background-color: #f7d0d1 !important;
	}

	.elq-form .elq-field-style input[type=submit] {
		@include font-heading-16b;
		@include link-transition;
		color: $white;
		background-color: $brand;
		float: right;
		display: inline-block;
		padding: ($ssu * 1.5) ($bsu * 1.5);
		border: 0;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		cursor: pointer;
		border-radius: $border-radius;
		width: calc(100% - 30px);
		position: absolute;
		bottom: -50px;
		left: 15px;

		@include bp("large") {
			bottom: -70px;
		}

		.o-theme-primary & {
			background-color: $grey--funsion;
		}

		&:hover,
		&:focus {
			background-color: $brand--alt;

			.o-theme-primary & {
				background-color: $grey--darkest;
			}
		}

		&:disabled {
			cursor: not-allowed;

			&,
			&:hover,
			&:focus {
				background-color: $grey--dark;
			}
		}
	}
}
