﻿// MACRO.BLOCKQUOTE


.m-quote {
	position: relative;
	background: linear-gradient(135deg, rgba($brand,1) 0%,rgba($brand--alt,1) 100%);
	padding: $lsu $lsu $lsu 70px;
	border-radius: 6px;

	@include bp("large") {
		padding: $lsu $lsu $lsu ($lsu*2.5);
	}

	&::before {
		content: '\201C';
		display: block;
		position: absolute;
		color: $white;
		font-size: 90px;
		left: $bsu;
		top: ($bsu * 1.5);

		@include bp("large") {
			font-size: 120px;
			top: $bsu;
			left: ($bsu * 1.5);
		}
	}
}

	.m-quote__text {
		@include font-20-tall;
		color: $white;
	}

	.m-quote__citation {
		color: $white;
	}
