.c-modal {
	display: none;
	&[aria-hidden="false"] {
		@include flex-extend;
		align-items: center;
		justify-content: center;
	}

	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background: rgba($black, 0.6);
}

.c-modal--full {
	.c-modal__body {
		max-width: none;
		max-height: none;
		width: 100%;
		height: 100%;

		// 45px is the close button height plus its 1px offset
		padding: #{45px + $bsu} 0 $lsu;
		@include bp("medium") {
			padding: $hsu 0;
		}

		box-shadow: none;
	}

	.c-modal__close {
		right: 20px;
		@include bp($layout-wrapper-max-width) {
			right: 50%;
			transform: translateX(#{calc($layout-wrapper-max-width / 2) - 20px});
		}
	}
	background: rgba($white,0.9);
}

.c-modal__body-open {
	// To be placed on the <body> tag when a modal is open

	// Uncomment this rule to prevent background scrolling
	// while a modal window is open:
	// overflow: hidden;
}
.c-modal__body-open--full {
	overflow: hidden;
}

.c-modal__body {
	position: relative;

	overflow: auto;

	width: 100%;
	height: 100%;
	padding: 44px;
	@include bp("medium") {
		width: auto;
		max-width: 80%;
		height: auto;
		max-height: 80%;
		padding: $lsu $hsu;
	}
	//background: $white;
	box-shadow: 0 0 20px rgba($black, 0.4);
}

.c-modal--feature {
	.c-modal__body {
		background: $white;
		padding: 0;

		width: 100%;
		max-width: none;
		height: 100%;
		max-height: none;

		@include bp("extra-large") {
			width: auto;
			max-width: 80%;
			height: auto;
			max-height: 80%;
		}
	}
}

.c-modal__close {
	position: absolute;
	// Offset by 1px so focus style isn't cut off
	top: 1px;
	right: 1px;

	@include button-reset;

	display: block;
	padding: 10px;
	font-size: 24px;
	line-height: 1;

	color: $black;
	&:hover,
	&:focus {
		color: $brand;
	}

	&:before {
		@include font-icon;
		content: $iconf-close;
	}
}

.c-modal__dummy {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}
