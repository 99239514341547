// CONTROL.LOGO-GRID

// CONTROL.IMAGE-GRID

@use "sass:math";


.c-logo-grid__layout {}

.c-logo-grid__heading {}


.c-logo-grid__list {
	@include list-reset;
	display: flex;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	.c-logo-grid__body--center & {
		justify-content: center;
	}

	/*
	@supports (display: grid) {
		display: grid;

		.c-logo-grid--large & {
			@include grid-ram(400px);
			grid-template-columns: repeat(1, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(2, 1fr);
			}

			.c-logo-grid__item-image {
				padding-bottom: 56.25%;
			}
		}
		// Medium is the default
		&, .c-logo-grid--medium & {
			@include grid-ram(300px);
			grid-template-columns: repeat(2, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(4, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(6, 1fr);
			}

			.c-logo-grid__item-image {
				padding-bottom: 75%;
			}
		}

		.c-logo-grid--small & {
			@include grid-ram(230px);
			grid-template-columns: repeat(3, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(6, 1fr);
			}

			.c-logo-grid__item-image {
				padding-bottom: 100%;
			}
		}

		.c-logo-grid--extra-small & {
			@include grid-ram(230px);
			grid-template-columns: repeat(3, 1fr);

			@include bp("medium") {
				grid-template-columns: repeat(6, 1fr);
			}

			@include bp("large") {
				grid-template-columns: repeat(8, 1fr);

				.l-page--has-sidebar & {
					grid-template-columns: repeat(6, 1fr);
				}
			}

			.c-logo-grid__item-image {
				padding-bottom: 100%;
			}
		}

		margin: 0;
		grid-gap: $bsu;
	}
		*/
}

.c-logo-grid__item {
	display: flex;
	flex-direction: column;
	padding-left: $bsu;
	padding-top: $bsu;


	&, .c-logo-grid--large & {
		width: percentage(calc(1 / 1));

		@include bp("medium") {
			width: percentage(calc(1 / 2));
		}

		@include bp("large") {
			width: percentage(calc(1 / 3));

			.o-layout-content--has-aside & {
				width: percentage(calc(1 / 3));
			}
		}
	}
	// Medium is the default
	&, .c-logo-grid--medium & {
		width: percentage(calc(1 / 2));

		@include bp("medium") {
			width: percentage(calc(1 / 4));
		}

		@include bp("large") {
			width: percentage(calc(1 / 4));

			.o-layout-content--has-aside & {
				width: percentage(calc(1 / 4));
			}
		}
	}

	.c-logo-grid--small & {
		width: percentage(calc(1 / 3));

		@include bp("medium") {
			width: percentage(calc(1 / 6));
		}

		@include bp("large") {
			width: percentage(calc(1 / 6));

			.o-layout-content--has-aside & {
				width: percentage(calc(1 / 6));
			}
		}
	}
	/*
	@supports (display: grid) {
		padding: 0;

		&,
		.c-logo-grid--large &,
		.c-logo-grid--medium &,
		.c-logo-grid--small & {
			width: 100%;
		}
	}
	*/

	position: relative;
}

.c-logo-grid__item-wrap {
	display: flex;
	flex-direction: column;
	text-align: center;
	height: 100%;

	&:not(.c-logo-grid__img-cropped) {
		//height: 100%;
	}
}

.c-logo-grid__item-link {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: center;
	flex: 1;

	&:hover,
	&:focus-visible {
		.c-logo-grid__item-image {
			border-color: $brand;
		}
	}
}

.c-logo-grid__item-figure {
	flex-grow: 1;
	@include figure;
	margin-bottom: 0;
	text-align: center;

	.c-logo-grid__no-img-cropped & {
		.c-logo-grid__item-image {
			background-color: transparent;
		}

		.o-fit__image {
			background-color: transparent;
		}
	}
}

.c-logo-grid__has-link,
a.c-logo-grid__item-figure {


	&:hover,
	&:focus-visible {


		.c-logo-grid__item-image {
			border-color: $brand;

			.c-logo-grid--cover & {
				transform: scale(1);
			}

			.c-logo-grid--contain & {
				// Scale up less because these may
				// be logos that shouldn't get cut off
				transform: scale(1);
			}
		}

		.c-logo-grid__item-text-link {
			color: $brand;
		}

		.c-logo-grid__item-title {
			color: $brand;
		}
	}
}

.c-logo-grid__item-image {
	width: 100%;
	height: 100%;
	overflow: hidden;
	border: 1px solid $grey--light;
	background: none;

	.c-logo-grid--cover & {
		&, .o-fit__image {
			object-fit: cover;
		}
	}

	.c-logo-grid--contain & {
		&, .o-fit__image {
			object-fit: contain;
		}
	}

	@include link-transition(transform);
}

.c-logo-grid__item-title {
	//@include font-base-16;
	color: var(--secondary-a);
	line-height: lh(20, 16);
	margin-bottom: $ssu;
	margin-top: ($ssu * 1.5);
}

.c-logo-grid__item-text-link,
.c-logo-grid__item-link {

	&:hover,
	&:focus {
		.c-logo-grid__item-title {
			color: $brand;
		}
	}
}
