﻿// BASE.TYPOGRAPHY
// Adobe Fonts definitions:
// Account: sarah.mcgrath@terabyte.co.nz

// font-family: alisha, sans-serif;


// Typography.com definitions:

// font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', sans-serif;

$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 500;
$weight--bold: 700;

// Style declarations
//
//	.c-component__heading {
//		@include font-12;
//	}

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-base-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: lh(32, 24);
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(26, 18);
}

@mixin font-base-18b {
	@include font-base-18;
	font-weight: $weight--bold;
	line-height: lh(26, 18);
}


@mixin font-base-18l {
	@include font-base-18;
	font-weight: $weight--light;
	line-height: lh(26, 18);
}

@mixin font-base-16sb {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--semibold;
	line-height: lh(22, 16);
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(22, 16);
}

@mixin font-base-16b {
	@include font-base-16;
	font-weight: $weight--bold;
}

@mixin font-base-16l {
	@include font-base-16;
	font-weight: $weight--light;
}

@mixin font-base-14sb {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--semibold;
	line-height: lh(22, 14);
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(22, 14);
}
@mixin font-base-14b {
	@include font-base-14;
	font-weight: $weight--bold;
}


@mixin font-base-14l {
	@include font-base-14;
	font-weight: $weight--light;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(17, 12);
}

@mixin font-base-12l {
	@include font-base-12;
	font-weight: $weight--light;
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(17, 12);
}

@mixin font-base-10sb {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--semibold;
	line-height: lh(14, 10);
}

@mixin font-base-10l {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--light;
	line-height: lh(14, 10);
}

@mixin font-base-10lu {
	@include font-base-10l;
	text-transform: uppercase;
}


@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--light;
	line-height: lh(22, 14);

	@include bp("large") {
		font-size: 16px;
		font-weight: $weight--light;
		line-height: lh(26px, 16px);
	}
}

@mixin font-body-text-b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(26px, 16px);
}

@mixin font-heading-94-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 94px;
	line-height: 1;
}

@mixin font-heading-72b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 72px;
	line-height: 90px;
}

@mixin font-heading-72-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 72px;
	line-height: 1;
}

@mixin font-heading-64b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 64px;
	line-height: 1.2;
}

@mixin font-heading-60b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 60px;
	line-height: 68px;
}

@mixin font-heading-50 {
	font-family: $heading-font-family;
	font-weight: $weight--normal;
	font-size: 50px;
	line-height: 58px;
}

@mixin font-heading-50b {
	@include font-heading-50;
	font-weight: $weight--bold;
}

@mixin font-heading-48-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 48px;
	line-height: 1.5;
}

@mixin font-heading-38b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 38px;
	line-height: 42px;
}

@mixin font-heading-42-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 42px;
	line-height: 1;
}

@mixin font-heading-38-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 38px;
	line-height: 1.5;
}

@mixin font-heading-36b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 36px;
	line-height: 42px;
}

@mixin font-heading-32-h {
	font-family: $highlight-font-family;
	font-weight: $weight--normal;
	font-size: 32px;
	line-height: 1.5;
}

@mixin font-heading-30b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 30px;
	line-height: 36px;
}

@mixin font-heading-30 {
	font-family: $heading-font-family;
	font-weight: $weight--normal;
	font-size: 30px;
	line-height: 36px;
}

@mixin font-heading-28b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 28px;
	line-height: 40px;
}

@mixin font-heading-24 {
	font-family: $heading-font-family;
	font-weight: $weight--normal;
	font-size: 24px;
	line-height: 26px;
}

@mixin font-heading-24b {
	@include font-heading-24;
	font-weight: $weight--bold;
}

@mixin font-heading-20b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 20px;
	line-height: 28px;
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-weight: $weight--normal;
	font-size: 20px;
	line-height: 28px;
}

@mixin font-heading-18b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 18px;
	line-height: 24px;
}

@mixin font-heading-16b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 16px;
	line-height: 24px;
}

@mixin font-heading-16b-cap {
	@include font-heading-16b;
	text-transform: uppercase;
}

@mixin font-heading-16 {
	font-family: $heading-font-family;
	font-weight: $weight--normal;
	font-size: 16px;
	line-height: 24px;
}

@mixin font-heading-15b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 15px;
	line-height: 24px;
}

@mixin font-heading-14b {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 14px;
	line-height: 21px;
}

@mixin font-heading-14b--cap {
	@include font-heading-14b;
	text-transform:uppercase;
}

@mixin font-heading-14 {
	font-family: $heading-font-family;
	font-weight: $weight--bold;
	font-size: 14px;
	line-height: 21px;
}


@mixin font-heading-10l--cap {
	font-family: $heading-font-family;
	font-size: 10px;
	font-weight: $weight--light;
	line-height: lh(14, 10);
	text-transform: uppercase;
}

@mixin font-36b {
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-32 {
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-32b {
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-24 {
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-24b {
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-20 {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-20-tall {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-20b {
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-18 {
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
}

@mixin font-18b {
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-16 {
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-16b {
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-14 {
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-14b {
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-14-caps {
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-12 {
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-12b {
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

html {
	font-family: $base-font-family;
	color: $body-color;
	font-weight: $weight--light;
}

p {
	margin-top: 0;
}
