$feature-modal-bp: 650px;

// Modal
.c-feature-modal {
	@include flex-extend;
	justify-content: flex-end;

	@include bp-below($feature-modal-bp) {
		flex-direction: column-reverse;
	}
	@include bp("extra-large") {
		height: 100%;
		min-width: 520px;
		width: 70vw;
	}
}

.c-feature-modal__body {
	@include bp-below($feature-modal-bp) {
		position: relative;
		z-index: 1;
		background: $white;
		margin: -$lsu $bsu 0;
	}
	@include bp($feature-modal-bp) {
		width: percentage(calc(2/3));

		max-height: 100%;
		overflow: auto;
	}
	position: relative;

	padding: $hsu $lsu $hsu;

	&:focus {
		// Only receives focus when modal is opened on on click, shouldn't need to be indicated
		outline: none;
	}
}

.c-feature-modal__close {
	@include button-reset;

	position: absolute;
	top: $ssu;
	right: $lsu;

	@include flex-extend;
	align-items: center;
	@include bp-below($feature-modal-bp) {
		flex-direction: column-reverse;
	}
	padding: $ssu;

	@include font-14b;
	@include link-brand;
	@include link-transition;
}

.c-feature-modal__close-text {
	@include bp-below($feature-modal-bp) {
		color: $grey--dark;
	}
	@include bp($feature-modal-bp) {
		@include font-14b;
	}
}

.c-feature-modal__close-icon {
	@include bp-below($feature-modal-bp) {
		font-size: 30px;
	}
	@include bp($feature-modal-bp) {
		margin-left: $ssu;
	}
}

.c-feature-modal__title {
	display: block;

	@include font-heading-28b;

	+ .c-feature-modal__subtitle {
		margin-top: $ssu;
	}
}

.c-feature-modal__subtitle {
	display: block;

	@include font-16bu;
	color: $brand;

	+ .c-feature-modal__title {
		margin-top: $ssu;
	}
}

.c-feature-modal__text {
	display: block;
	margin-top: $lsu;
}

.c-feature-modal__link {
	margin-top: $lsu;
}

.c-feature-modal__media {
	background: $brand;
	width: 100%;

	@include bp($feature-modal-bp) {
		width: percentage(calc(1/3));
	}

	position: relative;
}

.c-feature-modal__image {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: middle right;
}

.c-feature-modal__image--mobile {
	display: block;
	@include bp($feature-modal-bp) {
		display: none;
	}
}

.c-feature-modal__image--desktop {
	display: none;
	@include bp($feature-modal-bp) {
		display: block;
	}
}

.c-feature-modal__cta {
	border-radius: 8px;
	padding: $bsu;
	background: $brand;
	color: $white;

	@include flex-extend;
	align-items: flex-end;

	.c-feature-modal__media & {
		@include bp-below($feature-modal-bp) {
			display: none;
		}
		@include bp($feature-modal-bp) {
			position: absolute;
			bottom: 0;
			left: 0;

			max-width: 90%;
		}
	}

	.c-feature-modal__body & {
		@include bp-below($feature-modal-bp) {
			margin-top: $hsu;
		}
		@include bp($feature-modal-bp) {
			display: none;
		}
	}
}

.c-feature-modal__cta-text {
	flex-grow: 1;
	@include font-heading-20b;
}

.c-feature-modal__cta-icon {
	flex-shrink: 0;
	margin-left: $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $white;

	font-size: 25px;

	@include link-transition;
}

.c-feature-modal__cta {
	&:hover,
	&:focus {
		.c-feature-modal__cta-icon {
			background: $white;
			color: $brand;
		}
	}
}
