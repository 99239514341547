/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

.c-megamenu {
	display: none;
	//background: $white;
	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('medium') {
	}

	@include bp('large') {
		background-color: transparent;
		position: absolute;
		left: 0;
		display: block;
		width: auto;

		max-height: 0;
		visibility: hidden;
		transition: max-height 0.5s ease-in-out;

		overflow: hidden;
		text-align: left;
		z-index: 9;
		//box-shadow: 0 8px 6px -6px rgba($black, 0.2);
		box-shadow: 3px 3px 5px rgba($black, 0.2);
		//border-radius: 6px;
		transform: translateX(-80px);
		&.c-megamenu--has-featured-links {
			transform: translateX(-175px);
		}

		&.allow-animations,
		.allow-animations > & {
			animation: 0.5s becomeHidden forwards;
		}
		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 600px;
		}

		&[aria-expanded="true"].allow-animations,
		[aria-expanded="true"].allow-animations > & {
			animation: 0.5s becomeVisible forwards;
		}


		.no-js .c-navigation__item:hover & {
			display: block;
		}

		.c-navigation__item:last-child & {
			left: -158px;
		}
	}

	@include bp('extra-large') {
		.c-navigation__item:last-child & {
			left: 0;
		}
	}
}

.c-megamenu__wrapper {
	@include clearfix;
	background-color: $white;

	@include bp('medium') {
	}

	@include bp('large') {
		//max-width: em($layout-wrapper-max-width);
		//padding: 0 $bsu;
		//padding-top: $bsu * 3;
		//padding-bottom: $bsu * 3;
		margin: $tsu auto 0;
		width: 100%;
		display: flex;
		border-radius: $border-radius;
		overflow: hidden;
		min-width: 290px;
		background-color: $grey--light;
	}

	@include bp('extra-large') {
		margin: 0 auto 0;
		width: 100%;
		min-width: 290px;
	}
}

.c-megamenu__intro {
	//width: $megamemu__intro-width;
	//float: left;
	//padding-right: $hsu;
	@include font-base-16;
	width: 45%;
	padding: $lsu ($lsu - $tsu);
	background-color: $skyblue--lightest;
}

.c-megamenu__intro-heading {
	display: block;
	margin-bottom: $bsu;
	//font-size: rem(24px);
}

.c-megamenu__items {
	padding: $ssu $lsu;

	@include bp('large') {
		//min-width: 50%;
		//width: 100%;
		width: 290px;
		padding: $ssu;
		//width: calc(100% - #{$megamemu__intro-width});
		//float: right;
		//columns: 2;
		//column-gap: $lsu;
		//padding: 0;
	}

	@include bp('extra-large') {
		//columns: 3;
	}
}

.c-megamenu__item-group-label {
	@include font-base-12;
	color: $grey--dark;
	padding: $tsu 0;

	@include bp('large') {
		padding: 0;
		margin-bottom: $bsu;
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp('large') {
		display: block;
		//@include ui-border('bottom');
		margin: 0;
	}

	&.c-megamenu__item-group {
		@include bp('large') {
			margin-bottom: $bsu;
		}
	}
}

.c-megamenu__link {
	@include font-heading-14b;
	color: $grey--darkest;
	display: block;
	padding: $tsu 0;
	border-radius: $border-radius;

	&[aria-expanded="true"],
	&:hover {
		color: $brand;
		background-color: $white;
	}

	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;
		//text-transform: uppercase;
		color: $grey--darkest;

		&::before {
			@include abs-center-v;
			right: $bsu;
			display: block;
			color: $brand--alt;
			opacity: 0;
			transition: all 0.3s ease;
		}

		&:hover::before {
			right: $ssu;
			opacity: 1;
		}

		&.c-megamenu__item-group-link {
			border-left: 1px dashed $grey--dark;
		}
	}
}

.c-megamenu__expander {
	display: none;
	position: absolute;
	left: inherit;
	top: $ssu;
	font-size: 13px;
	color: $grey--darkest;

	@include bp('large') {
		display: block;
		left: inherit;
		top: ($ssu * 1.5);
		right: $ssu;
	}
}

.c-megamenu__grandchild-items {
	display: none;

	.c-megamenu__grandchild__nav & {
		display: block;
	}
}

.c-megamenu__grandchild__nav {
	border-left: 1px solid $grey;
	padding: $bsu $ssu;
	display: none;
	width: 300px;

	&[aria-expanded="true"] {
		display: block;
	}
}

.c-megamenu__item-heading {
	@include font-heading-16b-cap;
	color: $grey--darkest;
}

.c-megamenu__item-heading-link {
	@include font-heading-16b-cap;
	color: $grey--darkest;
	padding: $ssu $bsu;
	display: block;
}

.c-megamenu__featured-links {
	width: 100%;
	padding: $bsu;
	background-color: $white;

	display: none;
	@include bp('large') {
		width: 300px;
		display: block;
	}
}

.c-megamenu__featured-link-itmes {
	display: flex;
	flex-wrap: wrap;
	margin: $bsu 0;
}

.c-megamenu__featured-link-item {
	margin-right: $tsu;
	margin-bottom: $ssu;
}

.c-megamenu__featured-link {
	@include font-base-12l;
	color: $grey--dark;
	background-color: $grey--light;
	border-radius: 2px;
	display: block;
	padding: $ssu $bsu;

	&:hover {
		color: $white;
		background-color: $grey--funsion;
	}
}
