﻿// CONTROL.FAQ

.c-faq__item {
	@include ui-border('all');
	margin-bottom: $tsu;
	box-shadow: none;
	transition-property: box-shadow border;
	transition-duration: 0.25s;
	transition-timing-function: ease-in-out;
	box-shadow: 0 0 6px rgba($black, 0.2);
	border-radius: $border-radius;
	border-color: $grey--lightest;

	&[aria-expanded="false"] {

		&:hover {
			border-color: $grey--lightest;
			box-shadow: 0 2px 4px 0 rgba($black, 0.1);
		}
	}
}

.c-faq__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-left: $bsu * 3;
	background-color: $white;
	cursor: pointer;
	outline: none;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-faq__item-heading {
	@include font-base-18b;
}

.c-faq__item-icon {
	position: absolute;
	top: 50%;
	left: $bsu;
	right: inherit;
	font-size: 18px;
	transform: translateY(-50%);
	transition: transform 0.3s linear;
	color: $brand;

	&::before {
		content: $iconf-plus;
	}

	.c-faq__item.is-expanded &, .c-faq__item[aria-expanded="true"] & {
		transform: translateY(-50%) rotate(0);

		&::before {
			content: $iconf-minus;
		}
	}
}

.c-faq__item-body {
	padding: $ssu $bsu $bsu;

	.js & {
		display: none;
	}

	.c-faq__item.is-expanded &,
	.c-faq__item[aria-expanded="true"] & {
		display: block;
	}
}
