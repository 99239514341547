﻿.o-eloqua-form,
.o-dynamics-form {
    max-width: 700px;

    form {
        @include font-heading-16;

        input.lp-form-fieldInput,
        select.lp-form-fieldInput,
        textarea.lp-form-fieldInput {
            background-color: transparent !important;
            color: $body-color !important;
            border-radius: 6px;
            border: 1px solid $black !important;
            padding: $ssu $ssu;
            height: 45px;

            &::placeholder {
                color: grey !important;
            }
        }

        .lp-ellipsis {
            @include font-heading-16b;
            color: $black !important;
            margin-bottom: $tsu;
            font-weight: bold !important;
        }

        .emptyContainer {
            padding: 0px !important;
        }

        .marketing-field {
            padding: 0 0 10px;
        }

        span.lp-required {
            @include font-heading-16b;
            color: #C32032 !important;
            display: inline;
            float: none;
            font-weight: 700;
            margin: 0 5px;
            padding: 0;
        }

        .lp-form-fieldInput input[type="checkbox"] {
            height: 20px;
            min-height: 20px;
            width: 20px;
            margin-right: 0;
            margin-top: 5px;
        }

        .lp-form-field {
            > span {
                font-size: 14px !important;
                width: auto;
                display: inline-block;

                a {
                    color: $brand;

                    &:hover {
                        color: #000;
                    }
                }
            }
        }

        .columnContainer {
            position: relative;
            min-height: 0px !important;
            padding-bottom: 0px !important;
            padding-top: 7px !important;

            .lp-form-button {
                @include font-heading-16b;
                @include link-transition;
                color: $white;
                background-color: $brand;
                border: 0px none !important;
                float: right;
                display: inline-block;
                padding: ($ssu * 1.5) ($bsu * 1.5);
                text-align: center;
                text-decoration: none;
                vertical-align: middle;
                cursor: pointer;
                border-radius: $border-radius;
                height: 50px;
                width: 100%;

                @include bp("large") {
                    bottom: -60px;
                }

                .o-theme-primary & {
                    background-color: $grey--funsion;
                }

                &:hover,
                &:focus {
                    background-color: $brand--alt;

                    .o-theme-primary & {
                        background-color: $grey--darkest;
                    }
                }

                &:disabled {
                    cursor: not-allowed;

                    &,
                    &:hover,
                    &:focus {
                        background-color: $grey--dark;
                    }
                }
            }
        }
    }
}

.submit-button-style {
    height: auto;
}

.columnContainer {
    .marketing-field {
        .lp-form-field {
            .lp-form-fieldInput + .lp-ellipsis {
                font-weight: 300;
                margin-left: 5px;
                line-height: 20px;
            }
        }
    }
}

/*====-------    Landing Form   -------====*/

.c-dynamics-landing__form {
    background: linear-gradient(112deg,#1c1a1a 0,#1c1a1a 40%,#3B444A 40%,#3B444A 100%);
    padding: 25px 40px;
    color: $white;

    @include bp("large") {
        padding: 35px 60px;
    }

    form input.lp-form-fieldInput,
    form select.lp-form-fieldInput,
    form textarea.lp-form-fieldInput {
        background-color: $white !important;
        color: $black !important;
        border-radius: 6px;
        border: 1px solid $white !important;
        padding: $ssu $ssu;
        height: auto;
        text-indent: 0;

        &::placeholder {
            color: grey !important;
        }
    }

    form .lp-ellipsis {
        @include font-heading-16b;
        color: $white !important;
        border: 0 !important;
        margin-bottom: $tsu;
        font-weight: bold !important;
    }

    .lp-form-button {
        @include font-heading-16b;
        @include link-transition;
        color: $white;
        background-color: $grey--funsion;
        border: 0px none !important;
        float: right;
        display: inline-block;
        padding: ($ssu * 1.5) ($bsu * 1.5);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        border-radius: $border-radius;
        width: calc(100% - 20px);
        position: absolute;
        bottom: -50px;
        left: 10px;
        height: 50px;

        @include bp("large") {
            bottom: -70px;
        }

        &:hover,
        &:focus {
            background-color: $grey--darkest;
        }

        &:disabled {
            cursor: not-allowed;

            &,
            &:hover,
            &:focus {
                background-color: $grey--dark;
            }
        }
    }
}

.containerWrapper {

    > div {
        display: none;
    }

    .columnContainer {
        display: block;
    }
}