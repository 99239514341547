// COMPONENT.HEADER

.c-header {
	position: absolute;
	top: 0;
	z-index: $z-header;
	height: ($header-height - 18px);
	width: 100%;
	background-color: $white;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;

	&.is-sticky {
		position: fixed;

		&.is-up {
			transform: translateY(calc(-100% - 6px));

			&.is-open {
				transform: none;
			}
		}
	}

	@include bp('large') {
		height: $header-height;
	}
}

.c-header__wrapper {
	@include flex-extend;
	position: relative;
	align-items: center;
	justify-content: space-between;
}

.c-header__logo {
	display: inline-block;
	padding: ($ssu - 3px) $bsu;
	text-align: center;
	background-color: $white;

	@include bp('large') {
		background-color: $brand;
		padding: 18px 25px;
	}

	@include bp('extra-large') {
		padding: 14px ($bsu + $tsu);
	}

	.c-header__logo--s {
		display: block;
		width: 115px;

		@include bp('large') {
			display: none;
		}
	}

	.c-header__logo--m {
		display: none;
		width: 20px;

		@include bp('large') {
			display: block;
		}

		@include bp('extra-large') {
			display: none;
		}
	}

	.c-header__logo--l {
		display: none;
		width: 140px;

		@include bp('extra-large') {
			display: block;
		}
	}

	&:focus {
		@include focus-outline;
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;
}

// Language
.c-header__language {
	display: none;

	@include bp('medium') {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}

//Header CTA
.c-header__cta {
	@include font-heading-16b;
	padding: 13px $bsu;
	border-radius: 0;
	margin-left: $bsu;
	display: none;

	@include bp('medium') {
		display: block;
	}

	@include bp('large') {
		padding: 22px $bsu;
	}
}

.c-header__cta-mobile {
	display: block;
	margin: 0;

	@include bp('medium') {
		display: none;
	}
}
//Header Social
.c-header__social {
	margin: auto $bsu;

	@include bp('extra-large') {
		display: block;
	}
}

.c-header__social-list {
	text-align: center;
}

.c-header__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-header__social-link {
	@include circle(40px);
	@include link-transition;
	font-size: 16px;
	color: $grey--dark;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $white;
		background-color: $grey--dark;
	}
}

//Header secondary Nav
.c-header__secondary-nav {
	display: none;

	@include bp('large') {
		display: block;
	}
}

.c-header__secondary-nav-link {
	@include font-base-14;
	color: $black;
	display: inline-block;
	margin: 0 $ssu;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-header__offices {
	margin: $bsu auto;
	display: flex;
	justify-content:center;
	align-content: center;
}

.c-header__office-info {
	margin: 0 $bsu;
	padding: 0 $ssu;
}

.c-header__office-info:last-child {
	border-left: 1px solid $black;
	margin-left: 0;
	padding-left: $bsu;
}

.c-header__office-link {
	@include font-base-14;
	color: $black;
}

// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 15);
$mobile-button-font-size: 25px;
$mobile-button-padding: calc(($mobile-button-size - $mobile-button-font-size) / 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;
	margin-right: $ssu;

	@include bp('medium') {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {

	@include bp('large') {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 1px solid $mobile-button-color;

		&:nth-child(1) { top: $mobile-button-padding; }
		&:nth-child(2) { top: $mobile-button-padding + 12px; }
		&:nth-child(3) { top: $mobile-button-padding + 24px; }
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) { transform: translateY(12px) rotate(45deg); }
			&:nth-child(2) { opacity: 0; }
			&:nth-child(3) { transform: translateY(-12px) rotate(-45deg); }
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;

	&:hover,
	&[aria-expanded="true"] {
		color: $mobile-button--hover-color;
	}

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $white;
	top: $header-height;
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height});
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp('large') {
		position: absolute;
		left: 0;
		top: $header-height;
		height: auto;
	}
}

.c-header__search-wrapper {
	width: 65%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 20px;

	@include bp('large') {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	@include flex-extend;
}

.c-header__search-input {
	@include font-16;
	@include placeholder-text($grey);
	display: block;
	padding: 0.3em 0.5em;
	width: calc(100% - 50px);
	margin: 0 auto;
	border: none;
	border-bottom: 1px solid $grey;
	border-radius: 0;
	background: transparent;
	height: 50px;
}

.c-header__search-btn {
	padding: 0;
	width: 50px;
	height: 50px;
	border: none;
	background: 0 0;

	font-size: 26px;
	line-height: 1;
	color: $brand;
	cursor: pointer;

	position: relative;
	border-bottom: 1px solid $grey;
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		color: darken($brand, 10%);
	}
}
