﻿// PLUGINS.TABLESCROLL

.tablescroll {
	position: relative;
	overflow: hidden;
	clear: both;
	margin-bottom: 1.5em;

	.tablescroll-inner {
		width: 100%;
	}

	&::before,
	&::after {
		@include pseudo-element;
		top: 0;
		width: 1%;
		height: 100%;
		display: none;
	}

	&.is-scrolling {

		.tablescroll-inner {
			overflow-x: scroll;
		}

		&::before,
		&::after {
			display: block;
		}
	}

	&::before {
		box-shadow: rgba($black, 0.5) 5px 0 20px;
		left: -1%;
	}

	&::after {
		box-shadow: rgba($black, 0.5) -5px 0 20px;
		right: -1%;
	}

	&.is-end {

		&::after {
			display: none;
		}
	}

	&.is-start {

		&::before {
			display: none;
		}
	}
}
