﻿// CONTROL.VIDEO

.c-video__embed {
	padding-bottom: percentage(calc(9/16));
}

.c-video__cover {
	@include abs-center;
	width: 100%;
}

.c-video__heading-inner {
	display: none;
}

.c-video__icon {
	@include abs-center;
	width: 66px;
	height: 54px;
	//background-color: $brand;
	//border-radius: 6px;

	&::before {
		@include font-icon;
		content: $iconf-play;
		color: $white;
		font-size: 80px;
		text-shadow: 0 0 6px rgba($black, 0.5);

		//@include pseudo-element;
		//left: 22px;
		//top: 17px;
		//border-width: 10px 20px;
		//border-color: transparent;
		//border-left-color: $white;
		//border-style: solid;
	}

	@include bp('small') {
		width: 76px;
		height: 62px;
		/*
		&::before {
			left: 25px;
			top: 18px;
			border-width: 13px 26px;
		}
		*/
	}

	@include bp('medium') {
		width: 90px;
		height: 76px;
		/*
		&::before {
			left: 30px;
			top: 23px;
			border-width: 15px 30px;
		}
		*/
	}
}
