﻿// CONTROL.ELOQUA-CTA

.c-eloqua-cta {
    background-color: $skyblue--lightest;
    border-radius: 6px;
    overflow: hidden;

    @include bp('large') {
        @include flex-extend;
        padding: 0;
    }

    .container-fluid div {
        padding-left: 0 !important;
        margin: 0 !important;
        padding-right: 0 !important;
    }
}

.c-eloqua-cta--image-right {

    @include bp('large') {
        flex-flow: row-reverse;
    }
}

.c-eloqua-cta__image {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &::before {
        content: "";
        display: none;
        width: 100%;
        height: 100%;
        background-image: url(/Content/images/interface/bg/left_light.svg);
        background-repeat: no-repeat;
        background-position: top right;
        left: auto;
        right: -1px;
        top: 0;
        bottom: 0;
        z-index: 1;
        position: absolute;

        .c-eloqua-cta--image-right & {
            left: -1px;
            right: auto;
            background-image: url(/Content/images/interface/bg/right_light.svg);
            background-position: top left;
        }
    }

    &::after {
        content: "";
        display: block;
        width: 100%;
        height: 150px;
        background-image: url(/Content/images/interface/bg/top_light.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        position: absolute;
    }

    @include bp('large') {
        flex: 0 0 35%;
        align-self: stretch;
        margin-bottom: 0;

        &::before {
            display: block;
        }

        &::after {
            display: none;
        }
    }
}

.c-eloqua-cta__image-figure {
    display: block;

    @include bp('large') {
        display: none;
    }
}

.c-eloqua-cta__content {
    padding: $bsu;
    width: 100%;

    @include bp('large') {
        @include flex-extend;
    }
}

.c-eloqua-cta__body {
    @include font-base-16;
    padding: $bsu;

    @include bp('large') {
        flex: 0 0 40%;
    }

    strong {
        font-weight: $weight--bold;
    }
}

.c-eloqua-cta__form {
    padding: 0;

    @include bp('large') {
        padding: $bsu;
        padding-top: 12px;
    }

    .elq-form .elq-item-input,
    .elq-form .elq-item-select,
    .elq-form .elq-item-textarea,
    form input.lp-form-fieldInput,
    form select.lp-form-fieldInput,
    form textarea.lp-form-fieldInput {
        background-color: transparent !important;
        color: $body-color !important;
        border-radius: 6px;
        border: 1px solid $brand !important;
        padding: $ssu $ssu;
        min-height: 45px;

        &::placeholder {
            color: grey !important;
        }

        .o-theme-dark & {
            color: $white !important;
            border: 1px solid $white !important;

            &::placeholder {
                color: grey !important;
            }
        }
    }

    .elq-form .elq-label,
    .lp-ellipsis {
        @include font-heading-16;
        font-weight: 700 !important;
        color: $black !important;
        margin-bottom: $tsu;

        .o-theme-dark & {
            color: $white !important;
            border: 1px solid $brand !important;
        }
    }

    .elq-form .row.single-checkbox-row {
        color: $body-color !important;

        .o-theme-dark & {
            color: $white !important;
        }
    }

    .form-element-instruction {
        @include font-base-14;
        color: $body-color !important;

        .o-theme-dark & {
            color: $white !important;
        }
    }

    .elq-form .elq-required {
        color: $red !important;
    }

    .elq-form .LV_invalid {
        color: lighten($red, 15%);
    }

    .elq-form .LV_invalid_field,
    .elq-form input.LV_invalid_field:active,
    .elq-form input.LV_invalid_field:hover,
    .elq-form textarea.LV_invalid_field:active,
    .elq-form textarea.LV_invalid_field:hover {
        background-color: #f7d0d1 !important;
    }

    .elq-form .elq-field-style input[type=submit] {
        @include font-heading-16b;
        @include link-transition;
        color: $brand;
        background-color: $skyblue--light;
        float: right;
        display: inline-block;
        padding: $ssu ($bsu * 1.5);
        border: 0;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 30px;
        height: auto;
        width: auto;

        &:hover,
        &:focus {
            background-color: darken($brand--alt-light, 10);
        }

        &:disabled {
            cursor: not-allowed;

            &,
            &:hover,
            &:focus {
                background-color: $grey--dark;
            }
        }
    }
}



.c-image-feature__image {

    @include bp('medium') {
        height: 100%;
    }
}

.c-image-feature__body {
    @include font-base-16;
    padding: $bsu;
    text-align: left;
    width: 100%;
    position: relative;

    @include bp('medium') {
        align-self: center;
        padding: ($bsu * 1.5) ($bsu * 2);
    }

    @include bp('large') {
        padding: $bsu * 1.5;

        .o-layout-content--has-aside & {
            padding: ($bsu * 1.5) ($bsu * 2);
        }
    }

    > *:last-child,
    &:last-child {
        margin-bottom: 0;
    }
}

.c-image-feature__heading {
    @include font-heading-20b;
    position: relative;
    margin-bottom: $bsu;
    color: $brand;

    @include bp('large') {
        @include font-heading-30b;
    }
}

.c-image-feature__text {
    margin-bottom: $bsu;

    @include bp('large') {
        margin-bottom: $bsu * 1.5;
    }

    p {
        @include font-body-text;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

// DARK VERSION
.o-theme-dark {

    &.c-eloqua-cta {
        background-color: $brand;
        color: #fff;
    }

    .c-eloqua-cta__image {
        &::before {
            background-image: url(/Content/images/interface/bg/left_dark.svg);
        }

        &::after {
            background-image: url(/Content/images/interface/bg/top_dark.svg);
        }
    }

    &.c-eloqua-cta--image-right {
        .c-eloqua-cta__image {
            &::before {
                background-image: url(/Content/images/interface/bg/right_dark.svg);
            }
        }
    }

    .c-eloqua-cta__body {
        color: $white;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    .c-image-feature__heading::before {
        background-color: $white;
    }
}

/*=========------------------    New Form    ------------------=========*/

.c-eloqua-cta__form {
    width: 100%;

    form {
        @include font-heading-16;
        width: 100%;
        max-width: 100%;

        div {
            width: 100%;
            max-width: 100%;

            @include bp('large') {
                max-width: 100%;
            }
        }
    }

    .emptyContainer {
        padding: 0px !important;
    }

    .marketing-field {
        padding: 0 0 10px;
    }

    span.lp-required {
        @include font-heading-16b;
        color: #C32032 !important;
        display: inline;
        float: none;
        font-weight: 700;
        margin: 0 5px;
        padding: 0;
    }

    form input[type="checkbox"] {
        height: 20px;
        min-height: 20px;
        width: 20px;
        margin-right: 0;
        margin-top: 5px;
    }

    form .lp-form-field {
        > span {
            font-size: 14px !important;
            width: auto;
            display: inline-block;

            a {
                color: #44ADB3;
                text-decoration: underline;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    .columnContainer {
        position: relative;
        min-height: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 7px !important;

        .lp-form-button {
            @include font-heading-16b;
            @include link-transition;
            color: $brand;
            background-color: $skyblue--light;
            text-transform: uppercase;
            float: right;
            display: inline-block;
            padding: $ssu ($bsu * 1.5);
            border: 0;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            border-radius: 30px;
            height: auto;
            width: auto;

            &:hover,
            &:focus {
                background-color: $brand--alt;
            }

            &:disabled {
                cursor: not-allowed;

                &,
                &:hover,
                &:focus {
                    background-color: $grey--dark;
                }
            }
        }
    }
}

.columnContainer {
    .marketing-field {
        .lp-form-field {
            .lp-form-fieldInput + .lp-ellipsis {
                font-weight: 300;
                margin-left: 5px;
                line-height: 20px;
            }
        }
    }
}

.containerWrapper {

    > div {
        display: none;
    }

    .columnContainer {
        display: block;
    }
}
