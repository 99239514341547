// COMPONENT.FOOTER

$footer-logo-width: 150px;

.c-footer {
	position: relative;
	background-color: $brand;
}

.c-footer__wrapper{
	background-color: $blue--dark;
}

.c-footer__primary {
	padding: $bsu 0;

	@include bp('medium') {
		@include flex-extend;
		padding-top: $lsu;
	}

	> .o-grid {
		width: 100%;
	}
}

.c-footer__secondary {
	padding: $bsu 0;
	@include flex-extend;
	flex-direction: column;
	justify-content: center;

	@include bp('medium') {
		@include flex-extend;
		flex-direction: row;
		align-items: center;
		justify-content: initial;
	}
	//background-color: $black;
}

.c-footer__logo {
	display: none;
	width: $footer-logo-width;
	padding: 0;
	margin: 0 auto;
	text-align: center;

	img {
		width: 100%;
	}

	@include bp('medium') {
		display: block;
		align-self: flex-start;
		margin: 0 auto;
	}
}

.c-footer__signup {
	padding: $bsu 0;

	.c-form__field-label {
		color: $white;
	}
}

.c-footer__signup-fields {
	@include list-reset;

	@include flex-extend;
	flex-direction: column;
	margin-top: -$bsu;
	@include bp("small") {
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: -$bsu;
	}
	@include bp("medium") {
		flex-wrap: nowrap;
	}
}

.c-footer__signup-field,
.c-footer__signup-submit {
	flex-grow: 1;

	padding-top: $bsu;
	padding-left: $bsu;

	@include bp("small") {
		width: 50%;
	}
	@include bp("medium") {
		width: auto;
	}
}

.c-footer__signup-submit {
	@include flex-extend;
	align-items: flex-end;
}

.c-footer__navigation {
	display: none;
	column-count: 3;
	column-gap: $lsu;
	margin-bottom: $bsu;


	@include bp('medium') {
		display: block;
		flex: 0 0 auto;
		width: 100%;
		padding-bottom: $bsu;
		margin-bottom: $bsu;
		border-bottom: 2px solid $brand;
	}

	@include bp('large') {
	}
}

.c-footer__navigation-link {
	@include font-heading-16b;
	display: inline-block;
	padding: $tsu 0;
	color: $skyblue--light;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__secondary-nav--wapper {
	@include flex-extend;
	flex-direction: column;
	padding-top: $bsu;
	padding-bottom: $bsu;
	margin-bottom: $bsu;
	border-bottom: 2px solid $brand;
	text-align: center;

	@include bp('medium') {
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;
		text-align: left;
	}
}

.c-footer__social {
	margin-bottom: $bsu;

	@include bp('medium') {
		flex: 0 0 auto;
		width: calc(50% - #{calc($footer-logo-width / 2)});
		padding-left: $lsu;
		margin-bottom: 0;
	}

	@include bp('large') {
		width: 30%;
		padding-left: $hsu;
	}
}

.c-footer__social-heading {
	display: block;
	margin-bottom: $ssu;
	color: $white;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include circle(40px);
	@include link-transition;
	border: 1px solid $white;
	font-size: 16px;
	color: $white;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $brand;
		background-color: $white;
	}
}

.c-footer__offices {
	width: 100%;
	margin-bottom: $bsu;

	p {
		width: 100%;
	}

	a[href^="tel:"] {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.c-footer__office-info {
	@include font-heading-14;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;

	@include bp("medium") {
		justify-content: inherit;
		align-items: flex-start;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.c-footer__office-phone {
	@include font-heading-14b;
}

.c-footer__office-link {
	@include font-heading-14b;
	color: $white;
	margin-left: $tsu;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-footer__office-location {
	@include bp("extra-large") {
		margin-left: $bsu;
	}
}

.c-footer__legal {
	margin-bottom: 0;
	text-align: center;

	@include bp('small') {
		@include flex-extend;
		align-items: center;
		//justify-content: space-between;
	}
}

.c-footer__legal a {
	color: $skyblue;
}

.c-footer__legal-copyright {
	@include font-base-12;
	display: block;
	margin-bottom: 0;
	color: $white;

	@include bp('small') {
		display: inline;
		margin: 0;
	}
}

.c-footer__legal-links-item {
	display: inline-block;
	padding: 0 $tsu;
	margin: 0 $tsu;
}

.c-footer__legal-link {
	@include font-base-12;
	color: $skyblue;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include font-base-12;
	display: block;
	text-align: center;
	color: $skyblue;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer-back-to-top {
	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 54px;
	height: 54px;
	font-size: 32px;
	color: $white;
	background: $brand;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;
	border-radius: 50%;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		font-size: 16px;
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp('large') {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		background: transparent;
		color: $grey--darkest;
		border: 1px solid $grey--darkest;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				color: $white;
				border: none;
				background: $brand;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
