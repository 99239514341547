// CONTROL.RELATED-INSIGHTS

@mixin related-insights-wide {
	.c-related-insights__pod-image-fallback {
		padding-top: percentage(calc(9/16));
	}

	.c-related-insights__pod-image--wide {
		display: block;
	}

	.c-related-insights__pod-image--square {
		display: none;
	}
}

@mixin related-insights-square {
	.c-related-insights__pod-image-fallback {
		padding-top: percentage(calc(1/1));
	}

	.c-related-insights__pod-image--wide {
		display: none;
	}

	.c-related-insights__pod-image--square {
		display: block;
	}
}

@mixin related-insights-appear {
	@media screen and (prefers-reduced-motion: no-preference) {
		.c-related-insights__pod,
		.c-related-insights__cta {
			@content;

			opacity: 0;
			@include link-transition(all, 0.5s);
		}

		&[data-scroll-appear="true"] {
			.c-related-insights__pod,
			.c-related-insights__cta {
				opacity: 1;
				transform: none;
			}
		}
	}
}

.c-related-insights {
	overflow: hidden;
}

.c-related-insights__top {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$bsu;

	@include bp("large") {
		margin-left: -$lsu;
		margin-top: -$lsu;
	}

	flex-direction: row-reverse;
	justify-content: space-between;

	.c-related-insights__item {
		@include flex-extend;
		align-items: flex-end;
		@include related-insights-square;

		@include bp("large") {
			@include related-insights-wide;
		}
	}

	.c-related-insights__pod {
		width: 100%;
		height: auto;
	}

	.c-related-insights__item {
		@include bp-below("medium") {
			width: 100%;
		}
	}

	@include bp("medium") {
		@include bp-below("extra-large") {
			.c-related-insights__item {
				width: percentage(calc(1/2));
			}

			.c-related-insights__head {
				width: percentage(calc(1/2));
			}
		}
	}

	@include bp("extra-large") {
		.c-related-insights__item {
			width: percentage(calc(1/3));
		}

		.c-related-insights__head {
			width: percentage(calc(2/3));
		}
	}
}

.c-related-insights__bottom {
	@include flex-extend;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: (-$bsu + $bsu);

	@include bp("large") {
		margin-left: -$lsu;
		margin-top: (-$lsu + $lsu);
	}

	@include related-insights-square;

	.c-related-insights__item {
		width: percentage(calc(1/1));
	}

	@include bp("medium") {
		.c-related-insights__item {
			width: percentage(calc(1/2));
		}
	}

	@include bp("large") {
		.c-related-insights__item {
			width: percentage(calc(1/4));

			&:nth-child(2) {
				width: percentage(calc(1/2));

				.c-related-insights__pod-title {
					@include font-heading-28b;
				}

				@include related-insights-wide;
			}
		}
	}
}

.c-related-insights__head {
	flex-grow: 1;

	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}
}

.c-related-insights__head-content {
	width: 100%;
	@include bp("medium") {
		max-width: 530px;
	}
	margin: 0 auto;
}

.c-related-insights__heading {
	@include font-heading-28b;
	@include bp("large") {
		@include font-heading-50b;
	}
}

.c-related-insights__introduction {
	margin-top: $bsu;
	@include bp("large") {
		margin-top: $lsu;
	}
}

.c-related-insights__introduction-link {
	display: block;
	margin-top: $bsu;

	@include font-14b;

	@include link-brand;
	@include link-transition;
}

.c-related-insights__item {
	padding-left: $bsu;
	padding-top: $bsu;
	@include bp("large") {
		padding-left: $lsu;
		padding-top: $lsu;
	}

	@include related-insights-appear {
		transform: translateY(100px);
	}
}


// Pod
.c-related-insights__pod {
	display: block;
	position: relative;

	height: 100%;

	// Create stacking context
	filter: grayscale(0);
}

.c-related-insights__pod-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;

	position: relative;
	z-index: 1;
}

.c-related-insights__pod-shadow {
	content: "";
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 100%);
}

.c-related-insights__pod-image-fallback {
	background: $grey--dark;
	height: 100%;
}

.c-related-insights__pod-body {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	width: 100%;

	color: $white;
	padding: $bsu;
}

.c-related-insights__pod-tag {
	display: inline-block;
	padding: $ssu;
	border-radius: 2px;

	background: $brand;
	color: $white;

	@include font-12;
}

.c-related-insights__pod-title {
	display: block;
	margin-top: $bsu;

	@include font-heading-20b;
}


// CTA
.c-related-insights__cta {
	background: $brand;
	color: $white;

	border-radius: 8px;
	padding: $bsu;

	@include flex-extend;
	align-items: flex-end;
}

.c-related-insights__cta-text {
	@include font-heading-20b;
}

.c-related-insights__cta-icon {
	flex-shrink: 0;
	margin-left: $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $white;

	font-size: 20px;

	@include link-transition;

	color: $white;
	background: $brand;

	--fa-primary-color: currentColor;
	--fa-secondary-color: currentColor;
}

.c-related-insights__cta {
	&:hover,
	&:focus {
		.c-related-insights__cta-icon {
			background: $white;
			color: $brand;
		}
	}
}

/////////////////
// Grid layout //
/////////////////
@supports (display: grid) {
	@include related-insights-wide;

	.c-related-insights__bottom {
		display: grid;
		grid-gap: $bsu;
		margin: $bsu 0 0;
		@include bp("large") {
			grid-gap: $lsu;
			margin: $lsu 0 0;
		}

		.c-related-insights__item {
			&,
			&:nth-child(2) {
				width: 100%;
			}
			padding: 0;
			width: auto;

			.c-related-insights__pod {
				height: auto;
			}
		}

		@include bp-below("medium") {
			grid-template-columns: 1fr;
		}
		@include bp("medium") {
			grid-template-columns: 1fr 1fr;
		}
		@include bp("large") {
			grid-template-columns: 1fr 2fr 1fr;

			.c-related-insights__item {
				&:nth-child(1) {
					grid-column-start: 1;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}

				&:nth-child(2) {
					grid-column-start: 2;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 2;
				}

				&:nth-child(3) {
					grid-column-start: 3;
					grid-column-end: span 1;
					grid-row-start: 1;
					grid-row-end: span 1;
				}

				&:nth-child(4) {
					grid-column-start: 3;
					grid-column-end: span 1;
					grid-row-start: 2;
					grid-row-end: span 1;
				}

				&:nth-child(1),
				&:nth-child(3) {
					// Square
					padding-top: 100%;
					position: relative;
					.c-related-insights__pod {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
					@include related-insights-square;
				}
				&:nth-child(2) {
					@include related-insights-wide;
				}
			}
		}
	}
}
