// CONTROL.PODS-DYNAMIC

.c-pods-dynamic {
	background: url(/content/images/interface/bg/f5_hero_wave_bg.png) right -150px no-repeat;
	background-size: auto;
	padding: ($bsu * 3) 0;
}

.c-pods-dynamic__layout {
	@include flex-extend;
	flex-wrap: wrap;
	margin-left: -$hsu;
	margin-top: -$lsu;
}

.c-pods-dynamic__body,
.c-pods-dynamic__pods {
	padding-left: $hsu;
	padding-top: $lsu;
}

.c-pods-dynamic__body {
	width: 100%;
	@include bp("extra-large") {
		width: calc(100% - 750px);
	}
}

.c-pods-dynamic__pods {
	width: 100%;
	@include bp("extra-large") {
		width: 750px;
	}
}

.c-pods-dynamic__heading {
	@include font-heading-24b;
	margin-bottom: $lsu;

	@include bp('large') {
		@include font-heading-50b;
	}
}

.c-pods-dynamic__select-wrapper {
	display: inline-block;
	vertical-align: bottom;
	max-width: 100%;

	position: relative;
	top: $ssu;
	&::after {
		position: absolute;
		right: $ssu;
		top: 50%;
		transform: translateY(-50%);

		@include font-icon;
		content: $iconf-arrow-down;
		color: $orange;
		font-size: 20px;

		pointer-events: none;
	}
}

.c-pods-dynamic__select {
	@include font-heading-24b;
	color: $brand;
	border: none;
	border-bottom: 1px solid $brand;
	background: none;
	cursor: pointer;
	appearance: none;
	padding: $ssu $lsu $ssu 0;
	max-width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	@include bp('large') {
		@include font-heading-50b;
	}
}

.c-pods-dynamic__group {
	display: none;
	&[aria-hidden="false"] {
		display: block;
	}
}

.c-pods-dynamic__pods {
	.c-pod--cta {
		@extend .c-pod--small;
	}
}
