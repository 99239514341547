// OBJECT.BTN

// Shared button styles

.o-btn {
	@include link-transition;
	display: inline-block;
	padding: $ssu ($bsu * 1.5);
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	border-radius: $border-radius;

	&:disabled {
		cursor: not-allowed;
	}
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}

.o-btn--primary {
	@include font-heading-16b;
	color: $white;
	background-color: $brand;

	&:hover,
	&:focus {
		background-color: $grey--funsion;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}
}

.o-btn--light {
	@include font-heading-16b;
	color: $brand;
	background-color: $white;
	box-shadow: 0 0 0 1px inset $brand;

	&:hover,
	&:focus {
		color: $white;
		background-color: $brand;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			box-shadow: none;
			background-color: $white;
			color: $grey--dark;
		}
	}
}

.o-btn--outline {
	@include font-heading-16b;
	color: $brand;
	background-color: transparent;
	border: 1px solid $brand;

	&:hover,
	&:focus {
		background-color: $brand;
		color: $white;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: transparent;
			color: $grey;
			border-color: $grey;
		}
	}
}

.o-btn--small {
	@include font-base-16;
	padding: $ssu ($ssu * 2);
}

.o-btn--orange {
	@include font-heading-16b;
	color: $white;
	background-color: $orange;

	&:hover,
	&:focus {
		background-color: darken($orange, 10);
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}
}

.o-btn--dark {
	@include font-heading-16b;
	color: $white;
	background-color: $grey--funsion;

	&:hover,
	&:focus {
		background-color:$brand;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}
}

.o-btn--text {
	&, .o-btn__text {
		@include font-base-14b;
	}
	.o-btn__icon {
		font-size: 24px;
	}
}

.c-expand-collapse__item-btn--close.o-btn {
	display: none;
}
