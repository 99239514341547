﻿// CONTROL.ELOQUA-CTA

.c-eloqua-cta {
    background-color: $grey--pale;
    border-radius: 6px;
    overflow: inherit;
    position: relative;
    margin-bottom: $hsu;

    @include bp('large') {
        @include flex-extend;
        padding: 0;
        flex-flow: row;
    }

    &:before {
        content: "";
        display: block;
        width: 90%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        left: 0;

        @include bp('large') {
            width: 300px;
        }
    }

    &.o-theme-dark:before {
        background: linear-gradient(112deg, #1c1a1a 0%, #1c1a1a 49%, transparent 49%, transparent 100%);
    }

    &.o-theme-light:before {
        background: linear-gradient(112deg, $grey 0%, $grey 49%, transparent 49%, transparent 100%);
    }

    &.o-theme-primary:before {
        background: linear-gradient(112deg, $brand--alt 0%, $brand--alt 49%, transparent 49%, transparent 100%);
    }

    .container-fluid div {
        padding-left: 0 !important;
        margin: 0 !important;
        padding-right: 0 !important;
    }
}

.c-eloqua-cta--image-right {

    @include bp('large') {
        flex-flow: row-reverse;
    }
}

.c-eloqua-cta__image {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    &::before {
        display: none;

        .c-eloqua-cta--image-right & {
            display: none;
        }
    }

    &::after {
        display: none;
    }

    @include bp('large') {
        flex: 0 0 35%;
        align-self: stretch;
        margin-bottom: 0;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
}

.c-eloqua-cta__image-figure {
    display: block;

    @include bp('large') {
        display: none;
    }

    .clear-cut-image & {
        margin: 0 auto $ssu;
        display: block;
        position: relative;
        z-index: 1;
        max-width: 250px;
        top: -30px;

        @include bp('large') {
            display: block;
        }
    }

    .o-fit {
        background-color: transparent !important;
    }
}

.c-eloqua-cta__content {
    padding: $bsu;
    position: relative;
    z-index: 1;

    @include bp('large') {
        @include flex-extend;
    }
}

.c-eloqua-cta__heading {
    @include font-heading-28b;
}

.c-eloqua-cta__sub-heading {
    @include font-heading-14b--cap;
    margin-bottom: $bsu;
}

.c-eloqua-cta__body {
    @include font-base-16l;
    padding: $bsu;


    @include bp('large') {
        flex: 0 0 40%;
    }

    strong {
        font-weight: $weight--bold;
    }
}

/*====----  Form starts here  ----====*/

.c-eloqua-cta__form {
    padding: 0;
    flex-grow: 1;
    width: 100%;

    @include bp('large') {
        padding: $bsu;
        padding-top: 12px;
    }

    .elq-form .elq-item-input,
    .elq-form .elq-item-select,
    .elq-form .elq-item-textarea,
    form input.lp-form-fieldInput,
    form select.lp-form-fieldInput,
    form textarea.lp-form-fieldInput {
        background-color: $white !important;
        color: $body-color !important;
        border-radius: 6px;
        border: 1px solid $white !important;
        padding: $ssu $ssu;
        height: auto;
        min-height: 45px;
        text-indent: 0;

        .o-theme-primary &,
        .o-theme-dark & {
            color: $black !important;
            border: 1px solid $white !important;

            &::placeholder {
                color: grey !important;
            }
        }

        &::placeholder {
            color: grey !important;
        }
    }

    .elq-form .elq-label,
    form .lp-ellipsis {
        @include font-heading-16b;
        color: $black !important;
        margin-bottom: $tsu;

        .o-theme-primary &,
        .o-theme-dark & {
            color: $white !important;
            border: 0 !important;
        }
    }

    .elq-form .elq-item-label {
        @include font-base-14l;
        color: $body-color;

        .o-theme-primary &,
        .o-theme-dark & {
            color: $white;
        }
    }

    .elq-form .row.single-checkbox-row {
        color: $body-color !important;

        .o-theme-primary &,
        .o-theme-dark & {
            color: $white !important;
        }
    }

    .form-element-instruction {
        @include font-base-14;
        color: $body-color !important;

        .o-theme-primary &,
        .o-theme-dark & {
            color: $white !important;
        }
    }

    .elq-form .elq-required {
        color: $red !important;
    }

    .elq-form .LV_invalid {
        color: lighten($red, 15%);
    }

    .elq-form .LV_invalid_field,
    .elq-form input.LV_invalid_field:active,
    .elq-form input.LV_invalid_field:hover,
    .elq-form textarea.LV_invalid_field:active,
    .elq-form textarea.LV_invalid_field:hover {
        background-color: #f7d0d1 !important;
    }

    .elq-form .elq-field-style input[type=submit] {
        @include font-heading-16b;
        @include link-transition;
        color: $white;
        background-color: $brand;
        float: right;
        display: inline-block;
        padding: ($ssu * 1.5) ($bsu * 1.5);
        border: 0;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        border-radius: $border-radius;
        width: calc(100% - 30px);
        position: absolute;
        bottom: -50px;
        left: 15px;
        height: auto;

        @include bp("large") {
            bottom: -70px;
        }

        .o-theme-primary & {
            background-color: $grey--funsion;
        }

        &:hover,
        &:focus {
            background-color: $brand--alt;

            .o-theme-primary & {
                background-color: $grey--darkest;
            }
        }

        &:disabled {
            cursor: not-allowed;

            &,
            &:hover,
            &:focus {
                background-color: $grey--dark;
            }
        }
    }
}

.elq-form input[type=checkbox],
.elq-form input[type=radio] {
    width: auto;
    height: auto;
}

.c-image-feature__image {

    @include bp('medium') {
        height: 100%;
    }
}

.c-image-feature__body {
    @include font-base-16;
    padding: $bsu;
    text-align: left;
    width: 100%;
    position: relative;

    @include bp('medium') {
        align-self: center;
        padding: ($bsu * 1.5) ($bsu * 2);
    }

    @include bp('large') {
        padding: $bsu * 1.5;

        .o-layout-content--has-aside & {
            padding: ($bsu * 1.5) ($bsu * 2);
        }
    }

    > *:last-child,
    &:last-child {
        margin-bottom: 0;
    }
}

.c-image-feature__heading {
    @include font-heading-20b;
    position: relative;
    margin-bottom: $bsu;
    color: $brand;

    @include bp('large') {
        @include font-heading-30b;
    }
}

.c-image-feature__text {
    margin-bottom: $bsu;

    @include bp('large') {
        margin-bottom: $bsu * 1.5;
    }

    p {
        @include font-body-text;
    }

    *:last-child {
        margin-bottom: 0;
    }
}

// DARK VERSION
.o-theme-dark {

    &.c-eloqua-cta {
        background-color: $grey--funsion;
        color:#fff;
    }

    .c-eloqua-cta__image {
        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    &.c-eloqua-cta--image-right {
        .c-eloqua-cta__image {
            &::before {
                display: none;
            }
        }
    }

    .c-eloqua-cta__body {
        color: $white;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    .c-image-feature__heading::before {
        background-color: $white;
    }
}

// PRIMARY VERSION
.o-theme-primary {

    &.c-eloqua-cta {
        background-color: $brand;
    }

    .c-eloqua-cta__image {
        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    &.c-eloqua-cta--image-right {
        .c-eloqua-cta__image {
            &::before {
                display: none;
            }
        }
    }

    .c-eloqua-cta__body {
        color: $white;

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    .c-image-feature__heading::before {
        background-color: $white;
    }
}


/*=========------------------    New Form    ------------------=========*/

.c-eloqua-cta__form {
    width: 100%;

    form {
        @include font-heading-16;
        width: 100%;
        max-width: 100%;

        div {
            width: 100%;
            max-width: 100%;

            @include bp('large') {
                max-width: 100%;
            }
        }
    }

    .emptyContainer {
        padding: 0px !important;
    }

    .marketing-field {
        padding: 0 0 10px;
    }

    span.lp-required {
        @include font-heading-16b;
        color: #C32032 !important;
        display: inline;
        float: none;
        font-weight: 700;
        margin: 0 5px;
        padding: 0;
    }

    form input[type="checkbox"] {
        height: 20px;
        min-height: 20px;
        width: 20px;
        margin-right: 0;
        margin-top: 5px;
    }

    form .lp-form-field {
        > span {
            font-size: 14px !important;
            width: auto;
            display: inline-block;

            a {
                color: $brand;

                &:hover {
                    color: #000;
                }
            }
        }
    }

    .columnContainer {
        position: relative;
        min-height: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 7px !important;

        .lp-form-button {
            @include font-heading-16b;
            @include link-transition;
            color: $white;
            background-color: $brand;
            border: 0px none !important;
            float: right;
            display: inline-block;
            padding: ($ssu * 1.5) ($bsu * 1.5);
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            border-radius: $border-radius;
            width: calc(100% - 20px);
            position: absolute;
            bottom: -50px;
            left: 10px;
            height: 50px;

            @include bp("large") {
                bottom: -60px;
            }

            .o-theme-primary & {
                background-color: $grey--funsion;
            }

            &:hover,
            &:focus {
                background-color: $brand--alt;

                .o-theme-primary & {
                    background-color: $grey--darkest;
                }
            }

            &:disabled {
                cursor: not-allowed;

                &,
                &:hover,
                &:focus {
                    background-color: $grey--dark;
                }
            }
        }
    }
}

.o-theme-dark,
.o-theme-primary {

    .c-eloqua-cta__form {
        form {
            @include font-heading-16;
            color: #fff;
        }
    }
}

.o-theme-primary {
    form .lp-form-field {
        > span {
            font-size: 14px !important;
            width: auto;
            display: inline-block;

            a {
                color: $grey--funsion;
            }
        }
    }
}

.columnContainer {
    .marketing-field {
        .lp-form-field {
            .lp-form-fieldInput + .lp-ellipsis {
                font-weight: 300;
                margin-left: 5px;
                line-height: 20px;
            }
        }
    }
}

.containerWrapper {

    > div {
        display: none;
    }

    .columnContainer {
        display: block;
    }
}