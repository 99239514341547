// OBJECT.BTN

// Shared button styles

.o-btn {
	@include link-transition;
	display: inline-block;
	padding: $ssu ($bsu * 1.5);
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	border-radius: 30px;

	&:disabled {
		cursor: not-allowed;
	}
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}

.o-btn--primary {
	@include font-heading-16b;
	color: $brand;
	background-color: $brand--alt-light;

	&:hover,
	&:focus {
		background-color: darken($brand--alt-light, 10);
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}

	// see: '.o-body-text__btn--light'
	&[class*="--light"] {
		color: $brand;
		background-color: $white;

		&:hover,
		&:focus {
			color: $white;
			background-color: $brand;
		}
	}
}

.o-btn--light {
	@include font-heading-16b;
	color: $white;
	background-color: $brand--alt-light;

	&:hover,
	&:focus {
		color: $white;
		background-color: darken($brand--alt-light, 10);
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $white;
			color: $grey--dark;
		}
	}
}

.o-btn--outline {
	@include font-heading-16b;
	color: $brand--alt-light;
	background-color: transparent;
	border: 1px solid $brand--alt-light;

	&:hover,
	&:focus {
		background-color: $brand--alt-light;
		color: $white;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: transparent;
			color: $grey;
			border-color: $grey;
		}
	}
}

.o-btn--small {
	@include font-base-16;
	padding: $ssu ($ssu * 2);
}

.o-btn--orange {
	@include font-heading-16b;
	color: $white;
	background-color: $orange;

	&:hover,
	&:focus {
		background-color: darken($orange, 10);
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}
}

.o-btn--dark {
	@include font-heading-16b;
	color: $brand--alt-light;
	background-color: $brand;

	&:hover,
	&:focus {
		background-color: darken($brand, 10);
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			background-color: $grey--dark;
		}
	}
}

.o-btn--text {
	background: none;
	padding: 0;

	@include link-brand;

	.o-btn__icon,
	.o-btn__text {
		display: inline-block;
		vertical-align: middle;
	}
}
