﻿// CONTROL.STATISTICS

.c-statistics {
	display: flex;
	justify-content: space-around;
	background-color: $brand;
}

.c-statistics-item {
	padding: $bsu $lsu;
	text-align: center;
	color: $white;
}
