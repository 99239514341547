$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-photo: "\e924";
$iconf-laptop: "\e900";
$iconf-lightbulb: "\e901";
$iconf-handshake: "\e902";
$iconf-link: "\e910";
$iconf-youtube: "\e903";
$iconf-arrow-down: "\e904";
$iconf-arrow-left: "\e905";
$iconf-arrow-right: "\e906";
$iconf-arrow-up: "\e907";
$iconf-plus: "\e908";
$iconf-minus: "\e909";
$iconf-close: "\e90a";
$iconf-tick: "\e90b";
$iconf-search: "\e90c";
$iconf-play: "\e90d";
$iconf-address: "\e90e";
$iconf-download: "\e90f";
$iconf-calendar: "\e911";
$iconf-chat: "\e912";
$iconf-email: "\e913";
$iconf-external: "\e914";
$iconf-fax: "\e915";
$iconf-filter: "\e916";
$iconf-google-maps: "\e917";
$iconf-mouse: "\e918";
$iconf-phone: "\e919";
$iconf-print: "\e91a";
$iconf-reload: "\e91b";
$iconf-share: "\e91c";
$iconf-instagram: "\e91d";
$iconf-pinterest: "\e91e";
$iconf-linkedin: "\e91f";
$iconf-twitter: "\e920";
$iconf-facebook: "\e921";
$iconf-web: "\e922";
$iconf-weibo: "\e923";

