.o-loader {
	position: relative;

	// ::before is an overlay to block interaction
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba($white, 0.8);
	}

	// ::after is the loader itself
	&::after {
		content: "";
		@include abs-center;
		z-index: 1;
	}
}

@keyframes spin-centred-cw {
	0% {
		transform: translate(50%, -50%) rotate(0deg);
	}

	100% {
		transform: translate(50%, -50%) rotate(360deg);
	}
}

.o-loader {
	&::after {
		animation: 1s linear infinite spin-centred-cw;

		width: 50px;
		height: 50px;
		border-radius: 100%;

		border: 8px solid rgba($brand, 0.1);
		border-top-color: $brand;
	}
}
