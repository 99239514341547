// OBJECT.CONTROL

.o-control {
	margin: $lsu 0;

	@include bp("large") {
		margin: $hsu 0;
	}

	.o-layout-content--has-aside .o-layout-main & {
		&:first-child {
			margin: $lsu 0;

			@include bp("large") {
				margin: $hsu 0;
			}
		}
	}
}
