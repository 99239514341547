﻿// CONTROL.FILTER(in Listing)

.c-hero-filter__wrapper {
    margin-bottom: $bsu;
}

.c-filter__field {
    margin-bottom: $bsu;

    @include bp('large') {
        display: inline-flex;
        align-items: center;
    }
}

.c-filter__label {
    display: block;
    @include font-14b;
    margin-bottom: calc($ssu/2);

    @include bp('large') {
        margin-bottom: 0;
        margin-right: $ssu;
    }
}

.c-filter__dropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;

    select {
        @include ui-border('all');
        padding: $ssu $lsu $ssu $ssu;
        margin: 0;
        width: 100%;
        background: $grey--light;
        /*Hiding the select arrow for firefox*/
        -moz-appearance: none;
        /*Hiding the select arrow for chrome*/
        -webkit-appearance: none;
        /*Hiding the select arrow default implementation*/
        appearance: none;

        &::-ms-expand {
            display: none;
        }
    }

    &:before {
        @include font-icon;
        content: $iconf-arrow-down;
        position: absolute;
        display: block;
        //font-family: 'font-icons';
        //content: "\e903";
        right: $bsu;
        top: 50%;
        transform: translateY(-50%);
        color: $brand;
        pointer-events: none;
    }

    @include bp('large') {
        width: auto;
        min-width: 200px;
        margin-right: $bsu;
    }
}

.c-filter__input-wrap {
    position: relative;
}

.c-filter__input {
    @include ui-border('all');
    padding: $ssu $lsu $ssu $ssu;
    margin: 0;
    width: 100%;
    background: $grey--light;
}

.c-filter__input-icon {
    position: absolute;
    right: $bsu;
    top: 50%;
    transform: translateY(-50%);
    color: $brand;
    pointer-events: none;
}

.c-filter__expand {
    .c-filter__expand-body {
        display: none;
    }

    .c-filter__heading {
        @include font-24b;
        cursor: pointer;
    }

    &[aria-expanded="true"] {
        .c-filter__expand-body {
            display: block;
        }

        .c-filter__heading {
            margin-bottom: 0.7em;
        }
    }

    @include bp('large') {
        .c-filter__expand-body {
            display: block;
        }

        .c-filter__heading {
            margin-bottom: 0.7em;
            cursor: default;
        }
    }
}
// filter in sidebar
.c-filter__aside {
    @include ui-border('all');



    .c-filter__form {
        padding: $bsu;
    }

    .c-filter__field {
        display: block;
        width: 100%;
    }

    .c-filter__dropdown {
        margin-right: 0;
        width: 100%;
    }

    .c-filter__label {
        margin-bottom: calc($ssu/2);
    }

    .c-filter__label-inline {
        display: inline-block;
        margin-top: $ssu;
        vertical-align: middle;
        @include font-14;
        width: 50px;
    }

    .c-filter__label-inline + .c-filter__input-wrap {
        width: calc(100% - 55px);
        display: inline-block;
        vertical-align: middle;
        margin-top: $ssu;
    }

    .c-filter__controls {
        @include flex-extend;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
}

.c-filter__reset {
    color: $brand;
}

.c-filter__actions {
    display: inline-flex;
    margin-left: -$bsu;
}

.c-filter__action {
    margin-left: $bsu;
}

.c-filter__open,
.c-filter__close {
    &, &.o-btn {
        // Extra specificity needed for bad override order
        display: none;
    }
}

@include bp-below("large") {
    .c-hero-filter__wrapper {
        margin-bottom: $bsu;

        .c-filter {
            visibility: hidden;
            position: fixed;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            overflow: auto;
            background: $white;
            padding: $bsu;
            padding-top: $header-height + $bsu;

            .c-filter__label {
                color: $body-color;
            }
        }

        .c-filter__open,
        .c-filter__close {
            &, &.o-btn {
                // Extra specificity needed for bad override order
                display: block;
            }
        }

        &.allow-animations {
            .c-filter {
                animation: 0.5s slideoutDown forwards, 0.5s becomeHidden forwards;
            }

            &[aria-expanded="true"] {
                .c-filter {
                    animation: 0.5s slideinUp forwards, 0.5s becomeVisible forwards;
                }
            }
        }
    }
}
