﻿// CONTROL.DOUBLE-CTA

.c-double-cta {
	border-radius: $border-radius;
	box-shadow: none;
}

.c-cta {
	.c-double-cta & {
		margin: 0;
		height: 100%;
	}
}

.o-layout-wrapper {
	.c-double-cta & {
		height: 100%;
	}
}

.c-double-cta .c-cta__body {
	@include flex-extend;
	padding: ($bsu * 1.5) ($bsu * 2);
	align-items: center;
	flex-direction: column;
	text-align: center;
	position: relative;
	padding: $bsu;
	margin: 0;
	border-radius: $border-radius;
	text-align: center;
	background-color: $brand;
	height: 100%;
	justify-content: center;

	&.o-theme-light {
		background-color: $grey--pale;
	}

	&.o-theme-dark {
		background-color: $grey--funsion;
	}


	@include bp('large') {
		padding: ($bsu * 1.5) ($bsu * 4);
	}
}

.c-cta__body-wrapper {
	.c-double-cta & {
		margin-bottom: $bsu;
		flex: inherit;
		padding-right: 0;
		margin-bottom: $bsu;
		text-align: center;

		.hasText & {
			@include bp('medium') {
				.o-layout-main & {
					flex: inherit;
					text-align: center;
				}
			}
		}
	}
}

.c-cta__heading {
	@include font-heading-24b;
	margin-bottom: 0.5em;

	@include bp('medium') {
		@include font-heading-28b;
	}

	.c-double-cta  & {
		//color: $brand;
	}

	.c-double-cta .o-theme-dark & {
		color: $white;
	}
}

.c-cta__text {
	@include font-body-text-b;
	margin-bottom: 2em;
	color: $body-grey-color;

	.c-double-cta .o-theme-dark & {
		color: $white;
	}
}

.c-cta__link {

	@include bp('medium') {
		white-space: nowrap;
	}

	.c-double-cta .o-theme-light & {
		color: $white;
		background-color: $brand;

		&:hover,
		&:focus {
			background-color: $grey--funsion;
		}
	}

	.c-double-cta .o-theme-dark & {
		color: $white;
		background-color: $brand;

		&:hover,
		&:focus {
			color: $grey--funsion;
			background-color: $white;
		}
	}
}

