/* OBJECT.LAYOUT */
.o-layout-wrapper {
	//@include layout-wrapper;

	@include bp("medium") {
		padding: 0 $lsu;
	}
	@include bp("extra-large") {
		padding: 0 $hsu;
	}

	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.o-layout-wrapper--narrow {
	max-width: 960px;
}

.o-layout-page {
	position: relative;
	padding-top: 200px;

	@include bp("mobile") {
		padding-top: $bsu;
	}

	&.hasHero {
		padding-top: $bsu;
	}

	.c-hubhero__wrapper ~ &,
	.c-hero__wrapper ~ & {
		padding-top: 60px;
	}

	&.o-layout-search-page,
	&.o-layout-404-page,
	&.o-layout-page--no-hero {
		padding-top: 100px;

		@include bp("large") {
			padding-top: 160px;
		}
	}
}

.o-layout-page__bg {
	background-image: url(/Content/images/interface/bg/f5_body_wave_bg.png);
	background-repeat: no-repeat;
	background-position: bottom right;
	padding-bottom: 1px;
}

.o-home--main {
	position: relative;
	z-index: 1;
	padding-top: 80px;
	padding-bottom: 180px;
	background-color: $white;
}