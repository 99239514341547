@media print {

	.c-header {
		position: absolute;
		box-shadow: none;

		&.is-up {
			transform: none;
		}
	}

	.c-header__mobile-button {
		display: none;
	}

	.o-cta {
		page-break-inside: avoid;
	}

	.o-figure {
		page-break-inside: avoid;
	}

	.o-embed {
		page-break-inside: avoid;
	}

	.m-quote {
		page-break-inside: avoid;
	}

	.c-listing-stacked__item {
		page-break-inside: avoid;
	}

	.c-related-item {
		page-break-inside: avoid;
	}

	.c-subnav__item {
		display: block;
		page-break-inside: avoid;

		&.is-selected::before {
			top: 0;
			height: 100%;
		}
	}

	.c-share__items {
		page-break-inside: avoid;
	}

	.c-footer-back-to-top {
		display: none;
	}
}