﻿// CONTROL.FORM

.c-form,
.c-form .umbraco-forms-form form {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		box-shadow: inset 0 1px 3px 0 rgba($black, 0.2);
		border-radius: 4px;

		&.input-validation-error {
			box-shadow: none;
		}
	}

	.umbraco-forms-caption {
		font-size: 18px !important;
		font-weight: 400;
		line-height: 1.35 !important;
		margin-bottom: 1.5em;
	}

	.umbraco-forms-label, .c-form__label {
		@include font-14b;
	}
}


