// OBJECT.FIGURE

@mixin figure {
	display: block;

	> img,
	> noscript > img {
		display: block;
		width: 100%;
	}
}

.o-figure {
	@include figure;
}