// CONTROL.BENEFITS-CAROUSEL

$benefits-carousel-shadow--mobile: 15px;
$benefits-carousel-shadow--desktop: 30px;

$benefits-carousel-shadow-bp: $layout-wrapper-max-width;

.c-benefits-carousel {
	.flickity-slider {
		margin-left: $bsu;

		@include bp("medium") {
			margin-left: $lsu;
		}

		@include bp("large") {
			margin-left: $hsu;
		}
	}
}

.c-benefits-carousel__head {
	@include bp($benefits-carousel-shadow-bp) {
		@include flex-extend;
	}
}

.c-benefits-carousel__heading {
	margin-bottom: $bsu;
	@include bp("medium") {
		margin-bottom: $lsu;
	}

	@include bp($benefits-carousel-shadow-bp) {
		width: 50%;
		flex-shrink :0;
		padding-right: $hsu;
	}
}

.c-benefits-carousel__introduction {
	display: none;
	@include bp("medium") {
		display: block;
	}
	@include bp($benefits-carousel-shadow-bp) {
		padding-top: 30px;
		flex-shrink: 1;
	}
}

.c-benefits-carousel__body {
	padding: 0 0 30px;
	@include bp("medium") {
		padding: 30px 0;
	}
}

.c-benefits-carousel__items {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	align-items: stretch;
	// Align the inner content with the rest of the
	// content, instead of aligning the shadow
	margin-left: -$benefits-carousel-shadow--mobile * 2;

	@include bp($benefits-carousel-shadow-bp) {
		margin-left: -$benefits-carousel-shadow--desktop * 2;
	}
	// So items' shadows are contained in the viewport
	padding-left: $benefits-carousel-shadow--mobile;

	@include bp($benefits-carousel-shadow-bp) {
		padding-left: $benefits-carousel-shadow--desktop;
	}

	&.flickity-enabled {
		display: block;
	}

	position: relative;

	@include bp("small") {
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: $benefits-carousel-shadow--mobile;
			bottom: $benefits-carousel-shadow--mobile;

			@include bp($benefits-carousel-shadow-bp) {
				top: $benefits-carousel-shadow--desktop;
				bottom: $benefits-carousel-shadow--desktop;
			}

			width: 20vw;
			max-width: 225px;
			background: linear-gradient(to left, rgba($white, 1) 0%, rgba($white, 0) 100%);
		}
	}
}

.c-benefits-carousel__item {
	// So items' shadows are contained in the viewport
	padding: $benefits-carousel-shadow--mobile;
	@include bp($benefits-carousel-shadow-bp) {
		padding: $benefits-carousel-shadow--desktop;
	}

	max-width: calc(100% - #{$bsu});
	width: 100%;
	@include bp("small") {
		width: 400px;
	}
	@include bp("medium") {
		width: 585px;
	}

	// TODO: This causes issues with resizing
	.c-benefits-carousel__items.flickity-enabled .flickity-viewport[style*="height"] & {
		height: 100%;
	}

	// This is needed to make sure the last item gets far enough on the page before Flickity's "contain" option stops further movement
	&:last-child {
		width: calc(100% + 30px);
		.c-benefits-carousel__item-body {
			margin-right: 30px;
		}
		@include bp("small") {
			width: (400px + 120px);
			.c-benefits-carousel__item-body {
				margin-right: 120px;
			}
		}
		@include bp("medium") {
			width: 685px;
		}
	}
}

.c-benefits-carousel__item-body {
	height: 100%;

	padding: $bsu;
	@include bp("medium") {
		padding: $bsu $lsu $lsu;
	}
	border-radius: 10px;

	box-shadow: 0 0 $benefits-carousel-shadow--mobile rgba($black, 0.3);
	@include bp($benefits-carousel-shadow-bp) {
		box-shadow: 0 0 $benefits-carousel-shadow--desktop rgba($black, 0.3);
	}
}

.c-benefits-carousel__item-icon {
	color: $brand;

	&, .c-benefits-carousel & {
		// Extra specificity to override bad styles from Font Awesome
		@include flex-extend;
	}
	align-items: stretch;
	justify-content: flex-end;

	margin-bottom: $ssu;
	margin-right: -$ssu;

	font-size: 50px;
}

.c-benefits-carousel__item-content {}

.c-benefits-carousel__item-title {
	display: block;
	margin-bottom: $bsu;
	@include font-heading-20b;

	@include bp("large") {
		@include font-heading-28b;
	}
}

.c-benefits-carousel__item-text {}

.c-benefits-carousel__item-link {
	@include font-heading-14b;
	@include link-brand;
}


.c-benefits-carousel__arrows {
	@include flex-extend;
	margin-top: $bsu;
}

.c-benefits-carousel__arrow {
	@include button-reset;
	@include flex-extend;
	align-items: center;
	justify-content: center;
	padding: $ssu;
	font-size: 20px;

	@include bp("large") {
		font-size: 40px;
	}

	+ .c-benefits-carousel__arrow {
		margin-left: $bsu;
	}

	@include link-transition;
	color: $brand;

	&:hover,
	&:focus {
		color: $brand--alt;
	}

	&:disabled {
		&,
		&:hover,
		&:focus {
			color: $grey;
		}
	}
}
