// COMPONENT.RELATED-ITEMS

.c-related-items__intro {
	margin-bottom: $bsu;

	p {
		@include font-16;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.c-related-item {
	margin-bottom: $tsu;
	box-shadow: 0 0 6px rgba($black, 0.2);
}

.c-related-item__link {
	@include link-transition;
	position: relative;
	display: block;
	padding: $ssu $bsu;
	padding-left: $bsu * 3;
	border: 1px solid $base-ui-color;
	background-color: $white;
	outline: none;

	&:hover,
	&:focus {
		border-color: $brand--alt;
	}
}

.c-related-item__text {
	@include font-16;
	color: $brand;
}

.c-related-item__icon {
	@include abs-center-v;
	left: $bsu;
	color: $brand;
}

.c-related-item__meta {
	@include font-12;
	display: block;
	margin-top: $tsu;
	color: $grey--dark;
}
