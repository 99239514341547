// CONTROL.PODS-MODAL

.c-pods-modal {
	background: url(/content/images/interface/bg/f5_hero_wave_bg.png) top right no-repeat;
	background-size: contain;

	.c-pod--cta {
		@extend .c-pod--small;
	}
}

.c-pods-modal__header {
	@include flex-extend;
	@include bp-below("large") {
		flex-wrap: wrap;
	}

	margin-bottom: $lsu;
}

@include bp-below("large") {
	.c-pods-modal__heading + .c-pods-modal__introduction {
		margin-top: $bsu;
	}
}
@include bp("large") {
	.c-pods-modal__heading {
		width: 50%;
		padding-right: $hsu;

		&:last-child {
			width: 100%;
			padding-right: 0;
		}
	}

	.c-pods-modal__introduction {
		width: 50%;

		&:first-child {
			width: 100%;
		}
	}
}
