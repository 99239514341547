﻿// CONTROL.CTA

.c-cta__body {
	position: relative;
	padding: $bsu;
	margin: $bsu 0;
	text-align: center;
	background-color: $brand;
	border-radius: 6px;
	@include flex-extend;
	padding: ($bsu * 1.5) $bsu;
	align-items: center;
	text-align: left;
	justify-content: center;
	flex-direction: column;

	&.o-theme-light {
		background-color: $brand--alt-light;
	}

	&.o-theme-dark {
		background-color: $brand;
	}

	@include bp('medium') {
		padding: $bsu ($bsu * 2);
		flex-direction: row;

		.o-layout-aside & {
			flex-direction: column;
		}
	}

	@include bp('large') {
		padding: $bsu ($bsu * 4);

		.o-layout-aside & {
			padding: $bsu ($bsu * 2);
		}
	}

	&.hasText {
		flex-direction: column;
		text-align: center;
	}

	.c-footer & {
		margin: 0;
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;
	//flex: 1 1 auto;
	padding-right: 0;
	text-align: center;


	> *:last-child {
		margin-bottom: 0;
	}

	@include bp('medium') {
		padding-right: $lsu;
		margin-bottom: 0;
		text-align: left;

		.o-layout-aside & {
			padding-right: 0;
			margin-bottom: $bsu;
			text-align: center;
		}
	}

	.hasText & {
		@include bp('medium') {
			padding-right: 0;
			margin-bottom: $bsu;
			text-align: center;
		}
	}
}

.c-cta__heading {
	@include font-heading-20;
	margin-bottom: 0.5em;
	color: $white;

	@include bp('medium') {
		@include font-heading-24;
	}

	.o-theme-light & {
		color: $brand;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $body-grey-color;

	.o-theme-dark & {
		color: $white;
	}
}

.c-cta__link {

	@include bp('medium') {
		white-space: nowrap;
	}
}