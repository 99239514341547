// CONTROL.PRICING

.c-pricing {}

.c-pricing__layout {
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-pricing__item {
	padding-left: $bsu;
	padding-top: $bsu;
	width: percentage(calc(1/1));
	// Important: These need to align with mediaqueries.js
	@include bp("medium") {
		width: percentage(calc(1/2));
	}

	@include bp("large") {
		width: percentage(calc(1/3));
	}

	@include flex-extend;
	align-items: stretch;
}

.c-pricing__card {
	@include flex-extend;
	flex-direction: column;

	box-shadow: 0 5px 10px 0 rgba($black, 0.2);

	// For the breakout card
	margin-bottom: $lsu;
}

// HEADER //
.c-pricing__item-header {
	position: relative;
	background: linear-gradient(180deg, $grey--darkest 0%, $grey--dark 100%);
	color: $white;

	text-align: center;
}

.c-pricing__item-header-inner {
	// 33px: height of ribbon - its offset
	padding: ($bsu + 33px) $lsu $bsu;
}

.c-pricing__item-ribbon {
	position: absolute;
	top: 0;
	right: 50%;
	transform: translate(50%, -$tsu);
	width: 200px;
	padding: $ssu $bsu $tsu;
	background: $brand;
	color: $white;
	text-align: center;
	@include font-14bu;

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		width: 0;
		border-left: 100px solid transparent;
		border-right: 100px solid transparent;
		border-top: 10px solid $brand;

		// Apparently there's sometimes a black line, but I haven't managed to replicate it
		// See if this fixes it, because -1px is quite obvious and it's likely due to rounding issues
		margin-top: -0.5px;
	}
}

.c-pricing__item-title {
	display: block;
	@include font-heading-20bu;

	padding-bottom: $ssu;
	margin-bottom: $bsu;

	position: relative;
	&::after {
		content: "";
		position: absolute;
		top: 100%;
		right: 50%;
		transform: translateX(50%);

		width: 60px;
		height: 2px;
		background: currentColor;
	}
}

.c-pricing__item-introduction {
	display: block;
	margin-bottom: $bsu;

	@include font-body-text;
	b, strong {
		font-weight: $weight--bold;
	}
}

.c-pricing__item-price {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-50b;
}

.c-pricing__item-lead-in {
	display: block;
	@include font-14;
}


// BODY //
.c-pricing__item-body {
	flex-grow: 1;

	text-align: left;

	padding-bottom: $bsu;
}

.c-pricing__item-body-expand-controls {
	@include flex-extend;
	@include bp("medium") {
		display: none;
	}

	padding: 0 $bsu;
	.c-pricing__item-body[aria-expanded="true"] & {
		padding-bottom: $bsu;
	}
	justify-content: center;

	// Nesting to be more specific than .o-btn
	.c-pricing__item-body-expand {
		display: block;
		.c-pricing__item-body[aria-expanded="true"] & {
			display: none;
		}
	}
	.c-pricing__item-body-collapse {
		display: none;
		.c-pricing__item-body[aria-expanded="true"] & {
			display: block;
		}
	}
}

.c-pricing__item-body-section {
	@include bp-below("medium") {
		&:nth-child(n+2) {
			display: none;
			.c-pricing__item-body[aria-expanded="true"] & {
				display: block;
			}
		}
	}
}

.c-pricing__item-body-section-title {
	display: block;
	padding: $ssu $bsu;

	background: $grey--light;

	@include font-heading-16b;
}

.c-pricing__item-body-section-text {
	display: block;
	padding: $bsu;

	ul {
		// !important to override tinyMCE styles
		padding-left: 30px !important;
		> li {
			// !important to override tinyMCE styles
			list-style: none !important;

			position: relative;
			&::before {
				@include font-icon;
				content: $iconf-tick;

				position: absolute;
				top: 0;
				right: 100%;
				transform: translateX(-6px);

				color: $brand;

				font-size: 20px;
			}
		}
	}
}

.c-pricing__item-pitch {
	display: block;
	padding: $bsu;

	text-align: center;
	@include font-body-text;
	b, strong {
		font-weight: $weight--bold;
	}
}


.c-pricing__item-button {
	width: calc(100% - #{$lsu * 2});
	margin-left: $lsu;

	transform: translateY(50%);
	margin-top: -22px; // Half its height
}
