/* OBJECT.LAYOUT */

.o-layout-wrapper {
	@include layout-wrapper;
	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-page {
	position: relative;
	padding-top: $bsu;
}

.o-layout-content--has-aside {

	@include bp('large') {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {

		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $lsu;
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__heading {
	@include font-36b;
	margin-bottom: $bsu;
}

.o-layout-header__lede {
	@include font-20-tall;
	width: 80%;
	margin: 0 auto;

	a {
		@include link-brand;
	}

	.o-body-text__btn {
		@extend .o-btn;
		@extend .o-btn--primary;

		a {
			transition: none;
			color: inherit;
			background: none;
			border: 0;
			text-decoration: none;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2 {
		@include font-heading-24b;
	}
}

.o-layout-header__date {
	@include font-12;
	margin-bottom: 2em;
}
