﻿// CONTROL.FORM

.c-form,
.c-form .umbraco-forms-form form {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	select,
	textarea {
		@include font-16;
		display: block;
		width: 100%;
		// 11px top and bottom padding makes them 48px tall, same as buttons
		padding: 11px 0.65em;
		border: 1px solid $grey;
		max-width: 100% !important;

		@include bp-below(400px) {
			// Not sure why this specific rule is needed
			max-width: calc(100vw - #{$bsu} * 2);
		}

		&.input-validation-error {
			border-color: $error;
			margin-bottom: $tsu;
		}
	}

	select ~ .field-validation-error {
		border-color: $error;
		margin-bottom: $tsu;
	}

	textarea {
		height: 150px;
	}

	span.field-validation-error, .c-form__error-message, .validation-summary-errors {
		@include font-14;
		color: #f00 !important;
	}


	.c-form__field {
		margin-bottom: $bsu;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.c-form__field-label {
		@include font-16;
	}

	.c-form__required-indicator {
		color: $red;
	}

	.c-form__controls {
		margin-top: $bsu;
	}

	legend {
		font-weight: 300;
		font-size: 16px;
		line-height: 18px;
		display: block;
	}
}

.umbraco-forms-caption {
	@include font-18;
	margin-bottom: 1.5em;
}

.umbraco-forms-form {
	max-width: 490px;

	.umbraco-forms-fieldset {
		@include ui-border('bottom');
		padding-bottom: $lsu;
		margin-bottom: $lsu;
	}

	.umbraco-forms-field {
		margin-bottom: $bsu;

		&:last-child {
			margin-bottom: 0;
		}

		&.checkbox {
			position: relative;
			padding-left: $bsu;

			input[type="checkbox"] {
				position: absolute;
				left: 0;
				top: $tsu;
			}
		}
	}

	.umbraco-forms-label-wrapper {
		order: 2;
	}

	.umbraco-forms-label, .c-form__label {
		@include font-18;
		display: block;
	}

	.umbraco-forms-tooltip {
		@include font-14;
		color: $grey--dark;
	}


	span.field-validation-error, .c-form__error-message, .validation-summary-errors {
		@include font-14;
		color: #f00 !important;
	}
}

.c-form__error-message {
	display: none;
}

.has-error .c-form__error-message {
	display: block;
}

.umbraco-forms-field-wrapper {
	margin-top: $ssu;


	.shortanswer & {

		@include bp('medium') {
			width: 75%;
		}
	}

	.dropdown & {

		@include bp('medium') {
			width: 75%;
		}
	}

	.date & {
		position: relative;
		width: 75%;

		&::after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
			color: $brand;
		}

		@include bp('medium') {
			width: 50%;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			margin-bottom: calc($ssu/2);
			margin-left: 3px;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
}


.titleanddescription {

	h2 {
		@include font-32;
		margin-bottom: 0.5em;
	}

	p {
		@include font-20;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-form {
	max-width: 700px;

	.btn {
		@extend .o-btn;

		&.primary {
			@extend .o-btn--primary;
		}
	}
}
// Fake inputs
.c-fake-checkbox {
	&:checked ~ .c-fake-checkbox__display {
		&::before {
			border-color: $blue;
		}
	}

	&:focus ~ .c-fake-checkbox__display {
		background-color: $grey--lightest;
	}
}

.c-fake-checkbox__display {
	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 4px;
		top: 5px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}

	&.o-fake-input__display--small {
		&::before {
			left: 3px;
			top: 5px;
			width: 12px;
			height: 6px;
		}
	}
}
// Fake Radio
.c-fake-radio {
	&:checked ~ .c-fake-radio__display {
		&::before {
			background-color: $blue;
		}
	}

	&:focus ~ .c-fake-radio__display {
		background-color: $grey--lightest;
	}
}

.c-fake-radio__display {
	border-radius: 25px;

	@include link-transition;

	&::before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 3px;
		top: 3px;
		display: block;
		width: 17px;
		height: 17px;
		border-radius: 17px;
		background-color: transparent;
	}
}
