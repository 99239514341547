// Modal

.c-feature-modal__media {
	background: linear-gradient(135deg, $teal 0%, $teal--dark 100%);
}

.c-feature-modal__close-text {
	@include bp-below($feature-modal-bp) {
		@include font-base-10lu;
	}
}
