﻿// CONTROL.VIDEO

.c-video__icon {
	width: auto;
	height: auto;
	&::before {
		color: $brand;
	}
}

.c-video__cover {
	z-index: 1;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		pointer-events: none;
		background: rgba($black, 0.3);
	}

	.c-video__body {
		z-index: 2;
	}
}

@include bp("medium") {
	.c-video__heading {
		display: none;
	}

	.c-video__body {
		@include abs-center;

		@include flex-extend;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		padding: 40px;
		max-width: 1200px;
	}

	.c-video__heading-inner {
		display: block;

		padding-top: $lsu;

		max-width: 440px;

		@include font-heading-50b;
		color: $white;

		position: relative;
		&::before {
			content: "";

			position: absolute;

			top: 0;
			left: 0;

			width: 100%;
			height: 3px;

			background: $brand;
		}
	}

	.c-video__icon {
		flex-grow: 1;
		@include flex-extend;
		align-items: center;
		justify-content: center;

		position: static;
		transform: none;

		margin-left: 80px;

		width: auto;
		height: auto;
		&::before {
			color: $brand;
			font-size: 200px;
		}
	}
}
