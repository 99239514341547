﻿// CONTROL.HEADING

.c-heading {
	margin: $lsu 0 -#{$bsu};


	@include bp('large') {
		margin: $lsu 0 -#{$lsu};
	}
}

.c-heading__main {
	@include font-heading-30b;
	color: $grey--darkest;
	text-align: center;

	&::after {
		content: '';
		display: block;
		width: $lsu;
		height: 3px;
		background-color: $brand--alt;
		margin: $bsu auto;
	}
}