/* COMPONENT.HEADER-NOTIFICATION */

.c-header-notification {
	background: $brand;
	color: $white;
	padding: $ssu 0;
}

.c-header-notification__heading {
	margin-bottom: $ssu;
}

.c-header-notification__text {
	:last-child {
		margin-bottom: 0;
	}

	a {
		@include link-plain;
		text-decoration: underline;
	}
}

.c-header-notification--alert {
	// This will be different for each project
	background: $white;
	color: $brand;
}
