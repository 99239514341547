.o-eloqua-form {
	max-width: 700px;

	.elq-form .elq-item-input,
	.elq-form .elq-item-select,
	.elq-form .elq-item-textarea {
		background-color: transparent !important;
		color: $body-color !important;
		border-radius: 6px;
		border: 1px solid $brand !important;
		padding: $ssu $ssu;

		.o-theme-dark & {
			color: $white !important;
			border: 1px solid $white !important;
		}
	}

	.elq-form .elq-label {
		@include font-heading-16;
		color: $black !important;
		margin-bottom: $tsu;

		.o-theme-dark & {
			color: $white !important;
			border: 1px solid $brand !important;
			.c-eloqua-landing & {
				border: none !important;
			}
		}
	}

	.elq-form .row.single-checkbox-row {
		color: $body-color !important;

		.o-theme-dark & {
			color: $white !important;
		}
	}

	.elq-form .elq-item-label,
	.form-element-instruction {
		@include font-base-14;
		color: $body-color !important;

		.o-theme-dark & {
			color: $white !important;
		}
	}

	.elq-form .elq-required {
		color: $red !important;
	}

	.elq-form .LV_invalid {
		color: lighten($red, 15%);
	}

	.elq-form .LV_invalid_field,
	.elq-form input.LV_invalid_field:active,
	.elq-form input.LV_invalid_field:hover,
	.elq-form textarea.LV_invalid_field:active,
	.elq-form textarea.LV_invalid_field:hover {
		background-color: #f7d0d1 !important;
	}

	.elq-form .elq-field-style input[type=submit] {
		@include font-heading-16b;
		@include link-transition;
		color: $brand;
		background-color: $brand--alt-light;
		float: right;
		display: inline-block;
		padding: $ssu ($bsu * 1.5);
		border: 0;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;
		cursor: pointer;
		border-radius: 30px;


		&:hover,
		&:focus {
			background-color: darken($brand--alt-light, 10);
		}

		&:disabled {
			cursor: not-allowed;

			&,
			&:hover,
			&:focus {
				background-color: $grey--dark;
			}
		}
	}
}
