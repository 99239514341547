/* COMPONENT.MEGAMENU */

$megamemu__intro-width: 400px;

.c-megamenu {
	display: none;
	//background: $white;
	&[aria-expanded="true"],
	[aria-expanded="true"] > & {
		display: block;
	}

	&[aria-expanded="true"] {
		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}

	@include bp('medium') {
	}

	@include bp('large') {
		background-color: transparent;
		transition: max-height 0.5s ease-in-out;
		position: absolute;
		left: 0;
		display: block;
		width: auto;
		max-height: 0;
		overflow: hidden;
		text-align: left;
		box-shadow: 3px 3px 5px rgba($black, 0.2);

		&[aria-expanded="true"],
		[aria-expanded="true"] > & {
			max-height: 600px;
		}

		.no-js .c-navigation__item:hover & {
			display: block;
		}

		.c-navigation__item:last-child & {
			left: -158px;
		}

		:focus-within > & {
			display: block;
		}
	}

	@include bp('extra-large') {
		.c-navigation__item:last-child & {
			left: 0;
		}
	}
}

.c-megamenu__wrapper {
	@include clearfix;
	background-color: $skyblue--lightest;

	@include bp('medium') {
	}

	@include bp('large') {
		margin: $tsu auto 0;
		width: 100%;
		display: flex;
		border-radius: 6px;
		overflow: hidden;
		min-width: 600px;
		background-color: $white;
	}

	@include bp('extra-large') {
		margin: $tsu auto 0;
		width: 100%;
		min-width: 680px;
	}
}

.c-megamenu__intro {
	@include font-base-16;
	width: 45%;
	padding: $lsu ($lsu - $tsu);
	background-color: $skyblue--lightest;
}

.c-megamenu__intro-heading {
	display: block;
	margin-bottom: $bsu;
}

.c-megamenu__items {
	padding: $ssu $lsu;

	@include bp('large') {
		width: 65%;
		padding: $lsu ($lsu - $tsu);

		:focus-within > & {
			display: block;
		}
	}

	@include bp('extra-large') {
		//columns: 3;
	}
}

.c-megamenu__item-group-label {
	@include font-base-12;
	color: $grey--dark;
	padding: $tsu 0;
	
	@include bp('large') {
		padding: 0;
		margin-bottom: $bsu;
	}
}

.c-megamenu__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp('large') {
		display: block;
		//@include ui-border('bottom');
		margin: 0;
	}

	&.c-megamenu__item-group {
		@include bp('large') {
			margin-bottom: $bsu;
		}
	}
}


.c-megamenu__link {
	@include font-heading-16b;
	color: $brand;
	display: block;
	padding: $tsu 0;

	&:hover {
		color: $brand--alt;
	}

	&:before {
		display: none;
	}

	@include bp('large') {
		position: relative;
		padding: $tsu $lsu $tsu $bsu;
		//text-transform: uppercase;
		color: $brand;
		

		&::before {
			@include abs-center-v;
			right: $bsu;
			display: block;
			color: $brand--alt;
			opacity: 0;
			transition: all 0.3s ease;
		}

		&:hover::before {
			right: $ssu;
			opacity: 1;
		}

		&.c-megamenu__item-group-link {
			border-left: 1px dashed $grey--dark;
		}
	}
}