// COMPONENT.HERO

.c-hero__wrapper {
	background-color: transparent;
	margin-top: ($header-height - 40px);
	position: relative;
	padding: $bsu;
	background-position: center;
	background: url(/Content/images/interface/bg/f5_herobanner_wave_bg.png), linear-gradient(180deg, rgba($grey--darkest,1) 0%,rgba($grey--funsion,1) 100%);
	background-repeat: no-repeat;
	background-position: center right, center;
	background-size: 100%, cover;

	&::before {
		display: none;
	}

	> .o-layout-wrapper {
		padding: 0;

		@include bp("medium") {
			padding: 0 $lsu;
		}
	}

	@include bp("medium") {
		min-height: 250px !important;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}
	}

	@include bp("large") {
		margin-top: ($header-height - 10px);
	}

	@include bp("extra-large") {
		margin-top: $header-height;
	}

	&.hasGradientColor {
		position: relative;

		&::before {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			background-position: center;
			background: url(/Content/images/interface/bg/f5_herobanner_wave_bg.png), transparent;
			background-repeat: no-repeat;
			background-position: center right, center;
			background-size: 100%, cover;
			background-color: transparent;

			@include bp("medium") {
				display: block;
			}
		}
	}
}

.c-hero {
	position: relative;
	width: 100%;
	padding-bottom: $lsu;

	.hasGradientColor & {
		z-index: 1;
	}
}

.c-hero__heading {
	@include font-heading-30b;
	position: static;
	color: $white;
	text-shadow: 0 0 5px $black;

	@include bp("large") {
		@include font-heading-50b;
		left: 50%;
	}
}

.c-hero__sub-title {
	@include font-heading-16b;
	color: $brand--alt;
	display: block;
	margin-bottom: $bsu;
}

/////////////
// PRIMARY //
/////////////
.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	margin-left: -$lsu;
	margin-top: -$bsu;

	.c-hero__title-wrapper,
	.c-hero__body {
		padding-left: $lsu;
		padding-top: $bsu;
		width: 100%;

		@include bp("large") {
			width: 50%;
		}
	}

	@include bp("medium") {
		@include flex-extend;
		flex-wrap: wrap;
		flex-direction: row;
		margin: 0;
		align-items: center;
		padding: $lsu 0 $bsu;

		&.has_heroImg {
			flex-direction: row-reverse;
		}
	}

	@include bp("extra-large") {
		padding: $lsu 0 $bsu;
	}

	@supports (display: grid) {
		margin-top: 0;
		margin-left: 0;
		display: grid;
		grid-gap: $bsu;
		grid-template-columns: repeat(1, 1fr);

		@include bp("medium") {
			@include bp-below("large") {
				padding: $bsu 0;
			}
		}

		@include bp("large") {
			grid-gap: 10%;
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(1600px) {
			grid-gap: 20%;
		}

		.c-hero__title-wrapper,
		.c-hero__body {
			height: 100%;
			width: 100%;
			padding: 0;
			margin: 0;
		}
	}
}

.c-hero__body {
	@include font-base-18l;
	@include flex-extend;
	flex-direction: column;
	overflow: visible;
	padding: 0;
	color: $white;
	align-items: flex-start;
	width: 100%;
	background-color: transparent;
	margin: $bsu 0;

	a {
		@include link-brand;
		text-decoration: underline;

		&.o-btn {
			color: $white;
			text-decoration: none;
		}
	}

	strong {
		@include font-base-18b;
	}

	@include bp("medium") {
		background-color: transparent;
		padding: 0 0 $bsu;
		margin: 0;
	}
}

.c-hero__title {
	@include font-heading-30b;
	color: $white;
	margin-top: ($bsu * 1.5);

	position: relative;
	z-index: 2;

	@include bp("medium") {
		@include font-heading-30b;
		margin-top: 0;
	}

	@include bp("large") {
		@include font-heading-50b;
		line-height: 1;
	}

	strong {
		@include font-heading-48-h;
		color: $brand;

		@include bp("large") {
			@include font-heading-72-h;
		}
	}
}

.c-hero__text {
	@include font-base-18;
	color: $white;

	@include bp("large") {
		padding-right: $bsu;
	}

	b, strong {
		font-weight: $weight--bold;
	}
}

.c-hero__scroll-icon {
	margin-top: $lsu;
	color: $white;
	text-align: center;
	font-size: 24px;
	display: none;

	@include bp("large") {
		display: none;
	}
}

.c-hero__figure {
	flex-grow: 1;
	position: relative;
	overflow: hidden;
	width: 100%;

	&::before {
		content: ' ';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(/Content/images/interface/bg/hero.svg);
		background-size: 100%;
		width: 100%;
		height: 120px;
		bottom: inherit;
		top: 210px;
		position: absolute;
		z-index: 0;

		@include bp("medium") {
			display: none;
		}
	}

	@include bp("medium") {
		width: 50%;
	}

	@include bp("large") {
		margin-left: $lsu;
		width: 60%;
		background: inherit;
	}

	.c-hero__primary--right & {
		@include bp("large") {
			margin-right: $lsu;
			margin-left: 0;
		}
	}
}

.c-hero__image {
	display: block;
	width: 100%;
	position: relative;

	@include bp("large") {
		height: 100%;
		object-fit: cover;
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: $lsu 0 0;
	text-align: center;
}

.c-hero__secondary--home {
	margin: #{$lsu + 50px} 0 0;

	@include bp("medium") {
		margin: #{$lsu + 68px} 0 0;
	}
}

.c-hero__secondary-title-text {
	@include font-heading-28b;
	margin-bottom: $ssu;
	color: $brand;

	@include bp("medium") {
		@include font-heading-30b;
		margin-bottom: $bsu;
	}

	@include bp("large") {
		@include font-heading-50b;
		margin-bottom: $lsu;
		line-height: 1;
	}
}

.c-hero__secondary-introduction {
	flex: 0 1 auto;

	a {
		@include link-brand;
	}
}