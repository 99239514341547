$eloqua-landing-bp: "large";

.c-eloqua-landing {}

.c-eloqua-landing__layout {
	@include bp-below($eloqua-landing-bp) {
		margin-top: -$lsu;
	}
	@include bp($eloqua-landing-bp) {
		display: flex;
		margin-left: -$lsu;
	}
}

.c-eloqua-landing__column {
	@include bp-below($eloqua-landing-bp) {
		padding-top: $lsu;
	}
	@include bp($eloqua-landing-bp) {
		// Force equal width
		flex: 1 0 auto;
		width: 0;

		padding-left: $lsu;
	}
}

.c-eloqua-landing__introduction {
	@include font-heading-28b;
}

.c-eloqua-landing__form {
	padding: $bsu;
	@include bp($eloqua-landing-bp) {
		padding: $lsu;
	}
	background: $black;
	background: linear-gradient(112deg, $black 0, $black 40%, $blue 40%, $blue 100%);
}

// Hero styles

.c-eloqua-landing__hero {
	// Only contains the title
	&.c-hero__wrapper {
		min-height: 350px;
	}
	.c-hero__primary {
		grid-template-columns: 1fr;
	}
	.c-hero__title {
		margin-bottom: 0;
	}
}
