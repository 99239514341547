﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	margin-top: $lsu;
	margin-bottom: $lsu;

	@include bp("medium") {
		margin-top: 170px;
	}
}

.c-breadcrumb__link {
	@include link-brand;
	@include font-base-12l;

	color: $brand;
}

.c-breadcrumb__current {
	@include link-plain;
	@include font-base-12l;
	color: $grey--dark;
}

.o-list--inline > [data-divider]::after {
	content: '>';

	.c-breadcrumb & {
		@include font-base-12l;
		color: $brand;
	}
}