// CONTROL.TESTIMONIALS-ROTATOR

.c-testimonials-rotator {
	.flickity-button {
		border-radius: 8px;

		&.previous {
			left: $bsu;
		}
		&.next {
			right: $bsu;
		}
	}

	.flickity-button-icon {
		transform: scale(0.5);
	}
}

.c-testimonials-rotator__body {
	background: rgba($black, 0.6);

	&.noImg {
		background: linear-gradient(180deg, $grey--darkest 0%, $grey--funsion 100%);
	}
}

.c-testimonials-rotator__abstract {
	@include font-base-20b;
	@include bp("medium") {
		@include font-base-24b;
	}
	@include bp("large") {
		@include font-base-28b;
	}
}
