// CONTROL.TESTIMONIALS-ROTATOR

.c-testimonials-rotator__viewpoint {
	min-height: 560px;
	overflow: hidden;
}

.c-testimonials-rotator__item {
	width: 100%;
	position: relative;
}

.c-testimonials-rotator__figure {
	min-height: 560px;
}

.c-testimonials-rotator__image {
	min-height: 560px;
}

.c-testimonials-rotator__spacer {
	min-height: 560px;
	background: $grey--dark;
}

.c-testimonials-rotator__body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background: rgba($black, 0.2);
	color: $white;
}

.c-testimonials-rotator__body-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;

	width: calc(100% - 90px);
	max-width: 900px;
	padding: 0 $bsu;
}

.c-testimonials-rotator__abstract {
	display: block;
	margin-top: $lsu;
	@include font-base-14sb;
	@include bp("medium") {
		@include font-base-20b;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-testimonials-rotator__author {
	display: block;
	margin-top: $lsu;
	@include font-body-text;
}

.c-testimonials-rotator__btn {
	margin-top: $bsu;
}
