// COMPONENT.HERO-HOME

.c-hero-banner-wrapper {
	position: relative;
	z-index: 0;
	margin-top: 0;
	pointer-events: all;
	background: radial-gradient(farthest-corner at 70% 128%, $grey--funsion 30%, $black 90%);
}

.c-hero__links {
	pointer-events: all;
}

.c-hero__item {
	width: 100%;
	position: relative;
	z-index: 1;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	color: $white;
	margin-top: 50px;

	@include bp("large") {
		background: none;
		margin-top: 0;
		height: 750px;
	}

	@include bp-below("large") {
		&:last-child {
			padding-bottom: 50px;
		}
	}
}

.c-hero__item-intro--wrapper {
	z-index: 1;

	@include bp("large") {
		height: 100vh;
	}

	opacity: 1;
	position: relative;

	.o-layout-wrapper {
		@include bp("medium") {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&::after {
		@include bp('large') {
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 0;
			background: linear-gradient(to right, $black 0%, transparent 70%);
			top: 0;
			left: 0;
		}
	}
}

.o-grid-layout {

	&.c-hero__item-intro-container {
		grid-gap: $bsu;
		grid-template-columns: repeat(1, 1fr);
		//margin-top: 20%;
		@include bp("medium") {
			@include bp-below("large") {
				padding: $lsu 0;
			}
		}

		@include bp("large") {
			grid-gap: 10%;
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(1600px) {
			grid-gap: 20%;
		}

		@media (min-width: 1024px) and (max-height: 841px) {
			//margin-top: 16%;
		}
	}

	&.c-hero__indicators {
		grid-gap: 40px;
	}
}

.c-hero__item-intro {
	position: relative;
	display: flex;
	//overflow: hidden;
	width: 100%;
	-webkit-box-pack: center;
	justify-content: center;
	z-index: 1;
	opacity: 1;
	transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) .3s, transform ease-in-out 0.3s;
	transform: translateY(0);

	@include bp("large") {
		//background: url(/Content/images/interface/bg/f5_homepage_herobanner.png) no-repeat right bottom, linear-gradient(to right, $black 0%, $grey--funsion 100%);
		background-size: contain;
	}

	&.is_animated-hidden {
		transition-delay: 3s;
		transform: translateY(-100px);
		opacity: 0;
		transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) .3s, transform ease-in-out 0.3s;
	}

	&::after {
		@include bp("large") {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			background-image: url(/Content/images/interface/bg/f5_body_wave_1x.png);
			background-position: right bottom;
			background-size: contain;
			background-repeat: no-repeat;
			bottom: -30%;
		}
	}
}

.c-hero__item-title {
	@include font-heading-28b;
	color: $white;
	margin-top: ($bsu * 1.5);

	@include bp("medium") {
		@include font-heading-50b;
		margin-top: 0;
	}

	@media(min-width: 1600px) {
		@include font-heading-64b;
	}

	span,
	strong {
		@include font-heading-42-h;
		color: $brand;

		@include bp("medium") {
			@include font-heading-72-h;
		}

		@media(min-width: 1600px) {
			@include font-heading-94-h;
		}
	}
}

.c-hero__item-title__animation {
	opacity: 0;
	visibility: hidden;
	animation: 2.5s 0.5s fadein forwards, 2.5s 0.5s becomeVisible forwards, 2.5s 0.5s heroSlideinUp forwards;
}

.c-hero__item-desciption {
	@include font-base-14l;
	line-height: 1.8;
	color: $white;
	position: relative;
	z-index: 1;

	@include bp("medium") {
		@include font-base-16l;
		line-height: 1.8;
	}

	@include bp("large") {
		padding-right: $bsu;
	}

	@include bp("extra-large") {
		@include font-base-18l;
		line-height: 2;
	}

	strong {
		@include font-base-14b;
		line-height: 2;

		@include bp("medium") {
			@include font-base-18b;
			line-height: 2;
		}

		@media (min-width: 1024px) and (max-height: 841px) {
			@include font-base-16b;
			line-height: 1.5;
		}
	}

	@media (min-width: 1024px) and (max-height: 841px) {
		@include font-base-16l;
		line-height: 1.5;
	}
}

.c-hero__item-desciption__animation {
	opacity: 0;
	visibility: hidden;
	animation: 2.5s 1s fadein forwards, 2.5s 1s becomeVisible forwards, 2.5s 1s heroSlideinUp forwards;
}

// slide animation
.c-slide-in {
	position: relative;
	transform: translateY(100px);
	opacity: 0;
	transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) .3s, transform ease-in-out 0.5s;

	&.c-hero__item-body {
		transform: translateY(140px);
		transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) .3s, transform ease-in-out 1s;
	}

	.is_animated & {
		transition-delay: 3s;
		transform: translateY(0);
		opacity: 1;
		transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) .3s, transform ease-in-out 1s;

		&.c-hero__item-body {
			transition-delay: 3s;
			transition: opacity cubic-bezier(0.42, 0, 0.44, 0.99) 2s, transform ease-in-out 2.5s;
		}
	}
}


.c-hero__item-body {
	@include font-base-14l;
	color: $white;
	margin-top: $bsu;

	@include bp("medium") {
		@include font-base-18l;
		margin-top: ($bsu * 1.5);
	}

	@include bp("large") {
		margin-top: $hsu;
	}

	@media (min-width: 1024px) and (max-height: 841px) {
		@include font-base-16l;
		line-height: 1.5;
	}
}

.c-hero__slides {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	&::after {
		@include bp('large') {
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 0;
			background: linear-gradient(to right, $black 0%, transparent 70%);
			top: 0;
			left: 0;
		}
	}

	> .o-layout-wrapper {
		display: flex;
		justify-content: flex-start;
		width: 100%;

		@include bp('large') {
			align-items: center;
			max-width: 1200px;
		}

		.o-grid {
			width: 100%;
		}
	}
}

.c-hero__slide-body {
	position: relative;
	z-index: 1;
}

// hero indicator
.c-hero__indicator-wrapper {
	position: absolute;
	bottom: ($bsu * 3);
	width: 100%;
	z-index: 1;

	@media (min-height: 1000px) {
		bottom: 15%;
	}

	@media (min-width: 1024px) and (max-height: 841px) {
		bottom: 40px;
	}

	opacity: 0;
	visibility: hidden;
	animation: 1.5s 1.5s fadein forwards, 1.5s 1.5s becomeVisible forwards, 1.5s 1.5s heroSlideinUp forwards;
}

.o-grid-layout--three {
	&.c-hero__indicators {
		grid-template-columns: repeat(3, 1fr);
	}
}

/*.c-hero__indicators {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}*/

.c-hero__indicator {
	@include font-base-18b;
	color: $white;
	display: flex;
	justify-content: center;

	&[aria-current="true"] {
		color: $white;
	}

	@media (min-width: 1024px) and (max-height: 841px) {
		@include font-base-16b;
		line-height: 1.5;
	}
}

.c-hero__indicator-icon {
	font-size: 35px;
	flex: 0 auto;
	margin-right: $bsu;
	color: $brand;

	.c-hero__indicator[aria-current="true"] & {
		color: $white;
	}

	@include bp("large") {
		color: $white;
	}
}

.c-scroll__to {
	position: absolute;
	left: auto;
	top: 0;
	right: 0;
	bottom: auto;
	width: 100%;
	height: 3px;
	background: pink;
	display: block;
	color: #fff;
	z-index: 11111;
}


///////////////
// MOUSE ICON //
///////////////
.c-scroll__wrapper {
	position: fixed;
	z-index: 2;
	left: auto;
	top: 23%;
	right: 1%;
	bottom: auto;
	z-index: 1;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
}

.c-scroll__mouse {
}

.c-scroll__mouse-icon {
	width: 20px;
	height: 35px;
	border: 2px solid $white;
	border-radius: 15px;
	cursor: pointer;
	position: relative;
	text-align: center;
}

.c-scroll__mouse-icon-wheel {
	height: 6px;
	margin: 2px auto 0;
	display: block;
	width: 3px;
	background-color: $white;
	border-radius: 50%;
	animation: 1.6s ease infinite wheel-up-down;
}

.c-scroll__indicator-wrapper {
	width: 2px;
	height: 60vh;
	margin: $bsu auto;
	background-color: rgba($white, 0.4);
}

.c-scroll__indicator {
	width: 2px;
	height: calc( 60vh * 0.25 );
	background-color: $white;
	transition: height 250ms ease-in-out 0ms;
}

@keyframes wheel-up-down {
	0% {
		margin-top: 2px;
		opacity: 0;
	}

	30% {
		opacity: 1;
	}

	100% {
		margin-top: 20px;
		opacity: 0;
	}
}


.c-svg__wrapper {
	margin: ($bsu * 1.5) 0 0;

	@include bp("large") {
		margin: 0;
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		top: 70px;
	}
}

.c-svg__container {
	height: 100%;
}

.c-svg {
	position: relative;
	height: 100%;



	@include bp("medium") {
		margin-top: ($bsu * 1.5);
	}

	@include bp("large") {
		margin-top: 0;
	}
}

.c-svg__item-path {
	stroke-dashoffset: 0;
	stroke-dasharray: 0;
	transition: all linear 0.1s;
	transition-timing-function: cubic-bezier(0.49, 0.2, 0.32, 0.82);
	stroke: $brand;
	stroke-width: 2;

	@include bp("extra-large") {
		stroke-width: 2;
	}

	.is_animated & {
		animation: dash 2s linear forwards;
	}
}

.c-svg__size-container {
	position: relative;
	height: 750px;
	overflow: hidden;
}

.c-svg__size {
	position: absolute;
	top: 0;
	right: 50%;
	width: calc(1628/800) * 750px;
	height: 100%;
	transform: translateX(50%);
}


@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

// debugmode

.trigger_point {
	position: absolute;
	right: 0;
	width: 200px;
	height: 1px;
	background-color: $red;
	color: $white;
	z-index: 100;
}


@keyframes heroSlideinUp {
	0% {
		transform: translateY(100px);
	}

	100% {
		transform: translateY(0);
	}
}
