// CONTROL.RELATED-INSIGHTS

.c-related-insights__heading {
	@include font-heading-28b;

	@include bp("large") {
		@include font-heading-50b;
	}

	strong {
		@include font-heading-32-h;
		color: $brand;

		@include bp("large") {
			@include font-heading-72-h;
		}
	}
}

.c-related-insights__pod-image-fallback {
	background: linear-gradient(135deg, $teal 0%, $teal--dark 100%);
}

.c-related-insights__pod-title {
	@include font-heading-20b;
}