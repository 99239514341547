// CONTROL.PEOPLE

.c-people {}

.c-people__head {
	margin-bottom: $lsu;

	@include bp($layout-wrapper-max-width) {
		@include flex-extend;
	}
}

.c-people__heading {
	margin-bottom: $lsu;

	@include bp($layout-wrapper-max-width) {
		width: 50%;
		flex-shrink :0;
		padding-right: $hsu;
	}
}

.c-people__introduction {
	@include bp($layout-wrapper-max-width) {
		flex-shrink: 1;
	}
}

.c-people__list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	align-items: stretch;

	margin-left: -$bsu;
	margin-top: -$bsu;
}

.c-people__item {
	padding-left: $bsu;
	padding-top: $bsu;
	width: percentage(calc(1/1));

	@include bp("small") {
		width: percentage(calc(1/2));
	}

	@include bp("medium") {
		width: percentage(calc(1/3));
	}

	@include bp("large") {
		width: percentage(calc(1/4));
	}
}


// Person
.c-people__person {
	width: 100%;
	height: 100%;

	@include button-reset;
	position: relative;
}

.c-people__person-shape {
	border-radius: 8px;
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		z-index: 2;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background: linear-gradient(to top, rgba($black, 0.95) 0%, rgba($grey--darkest, 0) 30%);
	}
}

.c-people__person-image {
	display: block;
	width: 100%;

	position: relative;
	z-index: 1;
}

.c-people__person-body {
	position: absolute;
	z-index: 3;
	bottom: 0;
	left: 0;
	width: 100%;

	@include flex-extend;
	align-items: flex-end;

	padding: $bsu;

	color: $white;
	text-align: left;
}

.c-people__person-content {
	flex-grow: 1;
}

.c-people__person-name {
	display: block;
	@include font-24b;
}

.c-people__person-title {
	display: block;
	margin-top: $ssu;

	@include font-16bu;
}

.c-people__person-icon {
	flex-shrink: 0;
	margin-left: $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $white;

	font-size: 25px;

	@include link-transition;
}

.c-people__person {
	transform: scale(1);

	&:hover,
	&:focus {
		outline: none;
		&::before {
			content: "";
			position: absolute;
			z-index: 4;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.c-people__person-icon {
			background: $white;
			color: $brand;
		}
	}

	&:focus {
		&::before {
			@include focus-outline($brand);
		}
	}
}


// CTA
.c-people__cta {
	height: 100%;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	padding: $lsu;
	border-radius: 8px;

	color: $brand;

	position: relative;
}

.c-people__cta-text {
	@include font-heading-24b;
}

.c-people__cta-icon {
	&, .c-people & {
		// Extra specificity to override interfering style from FontAwesome
		position: absolute;
	}
	bottom: $bsu;
	right: $bsu;

	flex-shrink: 0;
	margin-left: $bsu;

	@include flex-extend;
	align-items: center;
	justify-content: center;

	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $brand;
	background: $brand;
	color: $white;

	font-size: 20px;

	@include link-transition;

	position: relative;
	&::before,
	&::after {
		position: absolute;

		top: 50%;
		right: 50%;

		transform: translate(50%, -50%);
	}
}

.c-people__cta {
	&:hover,
	&:focus {
		.c-people__cta-icon {
			color: $brand;
			background: $white;
		}
	}
}
