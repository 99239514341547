// CONTROL.BENEFITS-CAROUSEL

.c-benefits-carousel__heading {
	@include font-heading-36b;

	strong {
		@include font-heading-48-h;
		color: $brand;
	}
}

.c-benefits-carousel__item-icon {
	color: $teal;
}

.c-benefits-carousel__item-text > p{
	@include font-base-14l;

	@include bp("large") {
		@include font-base-16l;
	}
}