﻿// CONTROL.BENEFITS-LIST

$benefits-list-bp: "medium";

.c-benefits-list {
}

.c-benefits-list__layout {
	@include bp($benefits-list-bp) {
		margin-left: -$lsu;
		margin-top: -$bsu;

		@include flex-extend;
	}
}

.c-benefits-list__content {
	margin-bottom: $bsu + $ssu;

	@include bp($benefits-list-bp) {
		padding-left: $lsu;
		padding-top: $bsu;
		flex-grow: 1;
	}
}

.c-benefits-list__heading {
	@include font-heading-20b;
	position: relative;
	margin-bottom: $bsu;

	@include bp("large") {
		@include font-heading-30b;
	}
}

.c-benefits-list__body {
	@include font-body-text;
}

.c-benefits-list__button-list {
	@include list-reset;
	margin-top: $bsu;
	margin-left: -$ssu;

	@include flex-extend;
	flex-wrap: wrap;
	align-items: flex-start;
}

.c-benefits-list__button-item {
	padding-top: $ssu;
	padding-left: $ssu;

	@include bp("large") {
		width: 50%;
	}
}

.c-benefits-list__button {
	display: block;
	width: 100%;
}

.c-benefits-list__items {
	@include bp($benefits-list-bp) {
		flex-shrink: 0;
		padding-left: $hsu;
		padding-top: $bsu;
		width: percentage(calc(2/3));

		&.c-benefits-list__items--list {
			width: percentage(calc(1/2));
		}
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -$bsu;
	margin-top: -$lsu;
}

@supports (display: grid) {
	.c-benefits-list__items {
		display: grid;
		margin-top: 0;
		column-gap: $lsu;
		margin-left: 0;
		row-gap: $bsu;

		.c-benefits-list__item {
			width: 100%;
			padding-top: 0;
			padding-left: 0;
		}
	}

	.c-benefits-list__items--grid {
		@include bp("small") {
			grid-template-columns: 1fr;
		}

		@include bp("medium") {
			grid-template-columns: 1fr 1fr;
		}
	}

	.c-benefits-list__items--list {
		grid-template-columns: 1fr;
	}
}

.c-benefits-list__items--grid {

	@include bp("medium") {
		.c-benefits-list__item {
			width: 100%;
		}
	}
}


.c-benefits-list__items--list {
	.c-benefits-list__item {
		width: 100%;
	}
}

.c-benefits-list__item {
	padding-left: $bsu;
	padding-top: $lsu;
	margin: 0 0 $bsu;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	@include bp($benefits-list-bp) {
		.c-benefits-list__items--grid & {
			display: block;


			.c-benefits-list__item-heading {
				margin-top: $ssu;
				margin-left: 0;
				margin-bottom: $ssu;
			}
		}
	}
}

.c-benefits-list__item-icon {
	&, .c-benefits-list & {
		// Extra specificity to override bad styles from Font Awesome
		@include flex-extend;
	}

	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	font-size: 45px;
	--fa-secondary-color: #{$blue--dark};
	--fa-primary-color: #{$skyblue--light};
	
	--fa-primary-opacity: 1;
	--fa-secondary-opacity: 1;
}

.c-benefits-list__item-image {
}

.c-benefits-list__item-heading {
	@include font-heading-20b;
	margin-left: $bsu;
	flex: 1;
}

.c-benefits-list__item-text {
	@include font-body-text;
	width: 100%;
	padding-top: 5px;

	@include bp("medium") {
		padding-top: 0;
	}
}
