﻿// COMPONENT.SHARE

$share-icon-size: 40px;
$share-icon-font-size: 16px;

$share: $brand--alt;
$facebook: $brand--alt;
$twitter: $brand--alt;
$pinterest: $brand--alt;
$google: $brand--alt;
$weibo: $brand--alt;
$email: $brand--alt;
$print: $brand--alt;
$linkedIn: $brand--alt;

.c-share {
	margin: $lsu auto;
	text-align: center;
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include font-base-16sb;
	display: block;
	margin: 0 0 $ssu;

	.c-share--floating & {
		display: none;
	}
}

.c-share__items {
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;
	margin: -#{$ssu} 0 0 -#{$ssu};
}

.c-share__item {
	padding: $ssu 0 0 $ssu;
}

.c-share__icon {
	@include link-transition;
	@include circle($share-icon-size);
	display: block;
	border: 1px solid $brand;
	font-size: $share-icon-font-size;
	text-align: center;
	vertical-align: middle;
	color: $brand;

	&:focus {
		outline: none;
	}

	&.iconf-facebook {
		border-color: $facebook;
		color: $facebook;

		&:hover,
		&:focus {
			color: $white;
			background: $facebook;
		}
	}

	&.iconf-twitter {
		border-color: $twitter;
		color: $twitter;

		&:hover,
		&:focus {
			color: $white;
			background: $twitter;
		}
	}

	&.iconf-pinterest {
		border-color: $pinterest;
		color: $pinterest;

		&:hover,
		&:focus {
			color: $white;
			background: $pinterest;
		}
	}

	&.iconf-google-plus {
		border-color: $google;
		color: $google;

		&:hover,
		&:focus {
			color: $white;
			background: $google;
		}
	}

	&.iconf-weibo {
		border-color: $weibo;
		color: $weibo;

		&:hover,
		&:focus {
			color: $white;
			background: $weibo;
		}
	}

	&.iconf-email {
		border-color: $email;
		color: $email;

		&:hover,
		&:focus {
			color: $white;
			background: $email;
		}
	}

	&.iconf-print {
		border-color: $print;
		color: $print;

		&:hover,
		&:focus {
			color: $white;
			background: $print;
		}
	}

	&.iconf-linkedin {
		border-color: $linkedIn;
		color: $linkedIn;

		&:hover,
		&:focus {
			color: $white; 
			background: $linkedIn;
		}
	}
}
