// CONTROL.PODS
.c-pod__body,
.c-pod-noimage__body {
	@include flex-extend;
	flex-direction: column;
	justify-content: flex-end;
	align-content: stretch;
	text-align: left;
	padding: ($tsu + $bsu);
	position: absolute;
}

.c-pod__icon {
	&, &[class*=" fa"] {
		// Override FontAwesome's position and display styles
		position: absolute;
		@include flex-extend;
	}

	bottom: ($tsu + $bsu);
	right: ($tsu + $bsu);

	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	border-radius: 8px;
	border: 1px solid $white;
	color: $white;
	font-size: 20px;

	@include link-transition;

	.c-pod--cta & {
		background-color: $brand;
		background-color: $brand;
	}

	a.c-pod__figure:hover & {
		background-color: $grey--funsion;
	}
}

a.c-pod__figure .c-pod__body,
button.c-pod__figure .c-pod__body,
a.c-pod-noimage .c-pod-noimage__body,
button.c-pod-noimage .c-pod-noimage__body {
	padding-right: ($tsu + $bsu) + 44px + ($tsu + $bsu) - 4px;

	.c-insights-resources & {
		padding-right: ($tsu + $bsu);
	}

	&::after {
		@extend .c-pod__icon;
		@include font-icon;

		.c-pod--cta & {
			display: none;
		}
	}
}


a.c-pod__figure .c-pod__body,
a.c-pod-noimage .c-pod-noimage__body {
	&::after {
		content: $iconf-arrow-right;

		.c-insights-resources & {
			display: none;
		}
	}
}

button.c-pod__figure .c-pod__body,
button.c-pod-noimage .c-pod-noimage__body {
	&::after {
		font-size: 25px;
		content: $iconf-plus;
	}
}

.c-pod__heading,
.c-pod-noimage__heading {
	@include font-heading-24b;

	position: static;
	transform: none;
}

.c-pod__text,
.c-pod-noimage__text {
	@include font-16;

	display: block;
	margin-top: 0;

	opacity: 0;

	transition-property: max-height, opacity, margin-top;

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

.c-pods-dynamic__group .c-pod,
.has-image .c-pod__figure {

	&:hover,
	&:focus {
		.c-pod__body {
			background: $brand;
			color: $white;
		}

		.c-pod__heading {
			color: $white;
		}

		.c-pod__image-fallback + .c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pods-dynamic__group .c-pod.c-pod--cta {
	&:hover,
	&:focus {
		.c-pod__body {
			background: transparent;
			color: $brand;
		}

		.c-pod__heading {
			color: $brand;
		}

		.c-pod__image-fallback + .c-pod__body {
			background-color: transparent;
		}
	}
}


.c-pod__body:not(.c-insights-resources__body) {
	transition: background 0.2s ease-in-out;
	background: linear-gradient( to bottom, rgba($black, 0) 0%, rgba($black, 0) 50%, rgba($black, 0.1) 60%, rgba($black, 0.8) 100% );
}

.c-pod.c-pod--cta .c-pod__body {
	background: transparent;
}


.c-pod:hover,
.c-pod__figure:focus {
	.c-pod__text {
		opacity: 1;
		margin-top: $bsu;
	}

	.c-pod__body::after {
		color: $white;
		background: $brand;
	}
}

.c-pod__image-fallback {
	background: linear-gradient(135deg, $teal, $teal--dark 50%) no-repeat;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		pointer-events: none;

		background: $brand;
		opacity: 0;

		transition: opacity 0.2s ease-in-out;
	}

	.c-pod:hover &,
	.c-pod__figure:focus & {
		&::before {
			opacity: 1;
		}
		background: linear-gradient(135deg, $teal, $teal--dark 50%) no-repeat;
	}

}

// NO IMAGE POD
.c-pod-noimage {
	background: linear-gradient(135deg, $teal, $teal--dark 50%) no-repeat;
}

a.c-pod-noimage,
button.c-pod-noimage {
	overflow: hidden;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		pointer-events: none;

		background: $brand;
		opacity: 0;

		transition: opacity 0.2s ease-in-out;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
		background: linear-gradient(135deg, $teal, $teal--dark 50%) no-repeat;
	}
}

.c-pod-noimage:hover,
.c-pod-noimage:focus {
	.c-pod-noimage__text {
		opacity: 1;
		margin-top: $bsu;
	}

	.c-pod-noimage__body::after {
		color: $white;
		background: $brand;
	}
}

.c-pod--small {
	a.c-pod__figure .c-pod__body,
	button.c-pod__figure .c-pod__body,
	a.c-podnoimage .c-podnoimage__body,
	button.c-podnoimage .c-podnoimage__body {
		padding-right: $bsu + 44px + $bsu;
		&::after {
			bottom: $bsu;
			right: $bsu;
		}
	}
}
