// COMPONENT.HERO

.c-hero__wrapper {
	background-color: transparent;
	margin-top: ($header-height - 18px);
	position: relative;
	padding-bottom: 0;
	background-position: center;

	&::before {
		content: " ";
		display: block;
		width: 100%;
		height: 300px;
		z-index: -1;
		position: absolute;
		background: linear-gradient(135deg, rgba($brand,1) 0%,rgba($blue--dark,1) 100%);
		background-color: $brand;
	}

	> .o-layout-wrapper {
		padding: 0;

		@include bp("medium") {
			padding: 0 $lsu;
		}
	}

	@include bp("medium") {
		background: url(/Content/images/interface/bg/hero.svg), linear-gradient(135deg, rgba($brand,1) 0%,rgba($blue--dark,1) 100%);
		padding-bottom: 80px;
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: 100%;
		min-height: 250px !important;

		&::before {
			display: none;
		}

		&::after {
			content: '';
			width: 100%;
			height: 2px;
			display: block;
			background: $white;
			bottom: -1px;
			position: absolute;
		}
	}

	@include bp("large") {
		margin-top: $header-height;
	}
}

.c-hero {
	position: relative;
	width: 100%;
	padding-bottom: $lsu;
}

.c-hero__heading {
	@include font-heading-30b;
	position: absolute;
	top: calc(50% + 32px);
	left: 40%;
	transform: translate(-50%, -50%);
	color: $white;
	text-shadow: 0 0 5px $black;

	@include bp("large") {
		@include font-heading-50b;
		left: 50%;
	}
}

.c-hero__sub-title {
	@include font-heading-16b;
	color: $brand--alt;
	display: block;
	margin-bottom: $bsu;
}

.c-heer-rotator {
	.flickity-prev-next-button {
		top: 100%;
		background-color: transparent;
		border-radius: 50%;
		border: 2px solid $brand--alt;
		color: $brand--alt;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: $lsu;
		height: $lsu;
		z-index: 1;

		@include bp("medium") {
		
			border: 2px solid $white;
			color: $white;
			z-index: 2;
		}

		@include bp("extra-large") {
			top: 85%;
		}
	}

	.flickity-prev-next-button.next {
		right: $bsu;

		@include bp("medium") {
			right: initial;
			left: 60px;
		}
	}

	.flickity-prev-next-button.previous {
		left: $bsu;

		@include bp("medium") {
			left: 0;
		}
	}

	.flickity-prev-next-button .flickity-button-icon {
		width: 50%;
		height: 50%;
		position: static;
	}
	// custom flick slider fade effect - have flashing issue with flick-fade function with infinity scroll
	.flickity-slider {
		transform: none !important;
	}

	.c-hero {
		left: 0 !important;
		opacity: 1;
		transition: opacity ease-in-out 0.5s;
		//z-index: -1;
	}

	.c-hero.is-selected {
		//z-index: 1;
		opacity: 1;
	}
}

/////////////
// PRIMARY //
/////////////
.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;

	@include bp("medium") {
		@include flex-extend;
		flex-direction: row-reverse;
		margin: 0;
		align-items: center;
		padding: 0;
	}

	@include bp("extra-large") {
		padding: $lsu 0;
	}

	&.c-hero__primary--right {
		@include bp("large") {
			flex-direction: row-reverse;
		}
	}
}

.c-hero__body {
	@include flex-extend;
	flex-direction: column;
	overflow: visible;
	padding: $bsu;
	color: $white;
	align-items: flex-start;
	width: 100%;
	background-color: $white;

	@include bp("medium") {
		background-color: transparent;
		padding: $hsu 0 $bsu;
		width: 50%;
	}

	@include bp("large") {
		width: 40%;
	}


	@include bp("extra-large") {
		//width: 450px;
		width: 40%;
	}
}

.c-hero__title {
	@include font-heading-28b;
	color: $brand;
	position: relative;
	z-index: 2;
	margin-bottom: $ssu;

	@include bp("medium") {
		@include font-heading-30b;
		color: $white;
		margin-bottom: $bsu;
	}

	@include bp("large") {
		@include font-heading-50b;
		line-height: 1;
		margin-bottom: $lsu;
	}
}

.c-hero__text {
	@include font-18;
	display: none;

	@include bp("large") {
		display: block;
		flex-grow: 1;
	}
}

.c-hero__scroll-icon {
	margin-top: $lsu;
	color: $red;
	text-align: center;
	font-size: 24px;
	display: none;

	@include bp("large") {
		display: block;
	}
}

.c-hero__figure {
	flex-grow: 1;
	position: relative;
	overflow: hidden;
	width: 100%;

	&::before {
		content: ' ';
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(/Content/images/interface/bg/hero.svg);
		background-size: 100%;
		width: 100%;
		height: 120px;
		bottom: inherit;
		top: 210px;
		position: absolute;
		z-index: 0;

		@include bp("medium") {
			display: none;
		}
	}

	@include bp("medium") {
		width: 50%;
	}

	@include bp("large") {
		margin-left: $lsu;
		width: 60%;
		background: radial-gradient(ellipse at center, rgba($brand--alt,1) 0%,rgba($brand--alt,0) 70%);
	}

	.c-hero__primary--right & {
		@include bp("large") {
			margin-right: $lsu;
			margin-left: 0;
		}
	}
}

.c-hero__image {
	display: block;
	width: 100%;
	position: relative;

	@include bp("large") {
		height: 100%;
		object-fit: cover;
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: $lsu 0 0;
	text-align: center;
}

.c-hero__secondary--home {
	margin: #{$lsu + 50px} 0 0;

	@include bp("medium") {
		margin: #{$lsu + 68px} 0 0;
	}
}

.c-hero__secondary-title-text {
	@include font-heading-28b;
	margin-bottom: $ssu;
	color: $brand;

	@include bp("medium") {
		@include font-heading-30b;
		margin-bottom: $bsu;
	}

	@include bp("large") {
		@include font-heading-50b;
		margin-bottom: $lsu;
		line-height: 1;
	}
}

.c-hero__secondary-introduction {
	flex: 0 1 auto;

	a {
		@include link-brand;
	}
}