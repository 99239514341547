$base-font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', sans-serif;
$heading-font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B', sans-serif;
$highlight-font-family: alisha, sans-serif;

// Icons
$icomoon-font-path: '/Content/fonts/font-icons/fusion5/fonts';
$border-radius: 8px;

@import "../fonts/font-icons/fusion5/variables";
@import "themes/fusion5/fusion5.theme";
@import "main";
@import "../fonts/font-icons/fusion5/style.scss";

// Overrides
@import "themes/fusion5/fusion5.base.typography";

@import "themes/fusion5/fusion5.object.btn";
@import "themes/fusion5/fusion5.object.body-text";
@import "themes/fusion5/fusion5.object.layout";
@import "themes/fusion5/fusion5.object.control";
@import "themes/fusion5/fusion5.object.eloqua-form";

@import "themes/fusion5/fusion5.control.faq";
@import "themes/fusion5/fusion5.control.related-items";
@import "themes/fusion5/fusion5.control.cta";
@import "themes/fusion5/fusion5.control.double-cta";
@import "themes/fusion5/fusion5.control.eloqua-cta";
@import "themes/fusion5/fusion5.control.dynamics";
@import "themes/fusion5/fusion5.control.eloqua-form";
@import "themes/fusion5/fusion5.control.eloqua-landing";
@import "themes/fusion5/fusion5.control.image-feature";
@import "themes/fusion5/fusion5.control.image-gallery";
@import "themes/fusion5/fusion5.control.video";
@import "themes/fusion5/fusion5.control.case-study";
@import "themes/fusion5/fusion5.control.rte";
@import "themes/fusion5/fusion5.control.subnav";
@import "themes/fusion5/fusion5.control.benefits-carousel";
@import "themes/fusion5/fusion5.control.benefits-list";
@import "themes/fusion5/fusion5.control.related-insights";
@import "themes/fusion5/fusion5.control.pods";
@import "themes/fusion5/fusion5.control.filter";
@import "themes/fusion5/fusion5.control.form";
@import "themes/fusion5/fusion5.control.highlights";
@import "themes/fusion5/fusion5.control.testimonials-rotator";
@import "themes/fusion5/fusion5.control.content-pathway";

@import "themes/fusion5/fusion5.macro.quote";

@import "themes/fusion5/fusion5.components.header";
@import "themes/fusion5/fusion5.components.navigation";
@import "themes/fusion5/fusion5.components.megamenu";
@import "themes/fusion5/fusion5.components.hubmenu";
@import "themes/fusion5/fusion5.components.footer";
@import "themes/fusion5/fusion5.components.breadcrumb";
@import "themes/fusion5/fusion5.components.hero";
@import "themes/fusion5/fusion5.component.feature-modal";
@import "themes/fusion5/fusion5.components.share";
@import "themes/fusion5/fusion5.components.pagination";

//plugin

// Custom components
@import "themes/fusion5/fusion5.components.hero-home";
@import "themes/fusion5/fusion5.components.hero-hub";
@import "themes/fusion5/fusion5.control.pricing";
@import "themes/fusion5/fusion5.control.insights-resources";
//Custom controls