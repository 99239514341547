﻿// CONTROL.CTA

.c-cta__body {
	position: relative;
	padding: $bsu;
	margin: $bsu 0;
	text-align: center;
	background-color: $brand;
	border-radius: $border-radius;
	@include flex-extend;
	padding: ($bsu * 1.5) $bsu;
	align-items: center;
	text-align: left;
	justify-content: space-between;
	flex-direction: row;

	&.o-theme-light {
		background-color: $grey--pale;
	}

	&.o-theme-dark {
		background-color: $grey--funsion;
	}

	flex-direction: column;
	@include bp("medium") {
		padding: $bsu ($bsu * 2);
		flex-direction: row;

		.o-layout-aside & {
			flex-direction: column;
		}
	}

	padding: $bsu ($bsu * 2);
	@include bp("large") {
		padding: $bsu ($bsu * 4);

		.o-layout-aside & {
			padding: $bsu ($bsu * 2);
		}
	}

	&.hasText {
		@include bp("medium") {
			flex-direction: row;
		}
		text-align: left;
	}

	.c-footer & {
		margin: 0;
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;
	//flex: 1 1 auto;
	padding-right: 0;
	text-align: center;


	> *:last-child {
		margin-bottom: 0;
	}

	@include bp('medium') {
		padding-right: $lsu;
		margin-bottom: 0;
		text-align: left;

		.o-layout-aside & {
			padding-right: 0;
			margin-bottom: $bsu;
			text-align: center;
		}
	}

	.hasText & {
		@include bp('medium') {
			padding-right: $bsu;
			margin-bottom: 0;
			text-align: left;
		}
	}
}

.c-cta__heading {
	@include font-heading-24b;
	margin-bottom: 0.5em;
	color: $white;

	@include bp('medium') {
		@include font-heading-28b;
	}

	.o-theme-light & {
		color: $black;
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $body-grey-color;

	.o-theme-dark & {
		color: $white;
	}
}

.c-cta__link {

	@include bp('medium') {
		white-space: nowrap;
	}

	.o-theme-light & {
		color: $white;
		background-color: $brand;

		&:hover,
		&:focus {
			background-color: $grey--funsion;
		}
	}


	.o-theme-dark & {
		&:hover,
		&:focus {
			color: $grey--funsion;
			background-color: $white;
		}
	}
}