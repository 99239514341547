// CONTROL.LISTING-GRID

.c-listing-grid {
	position: relative;

	margin-top: $lsu + $lsu;
	@include bp("large") {
		margin-top: $hsu + $lsu;
	}
}

.c-listing-grid__anchor {
	pointer-events: none;
	opacity: 0;

	position: absolute;
	top: -$lsu;
}

.c-listing-grid__list {
	@include list-reset;

	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -$bsu;
	margin-top: -$bsu;

	position: relative;
}

.c-listing-grid__item {
	padding-left: $bsu;
	padding-top: $bsu;
	width: percentage(calc(1/1));

	@include bp("large") {
		width: percentage(calc(1/2));
	}
}

.c-listing-grid__tile {
	display: block;
	height: 100%;

	background: $white;
	padding: $bsu;
	padding-right: $bsu + 44px + $bsu;
	@include bp("small") {
		padding: $lsu;
		padding-right: $bsu + 44px + $lsu;
	}

	position: relative;
	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		position: absolute;
		bottom: $bsu;
		right: $bsu;
		@include bp("small") {
			bottom: $lsu;
			right: $lsu;
		}

		@include flex-extend;
		align-items: center;
		justify-content: center;

		width: 44px;
		height: 44px;
		border-radius: 8px;

		font-size: 20px;

		background: $brand;
		color: $white;
	}

	&,
	&::after,
	.c-listing-grid__eyebrow,
	.c-listing-grid__heading,
	.c-listing-grid__text,
	.c-listing-grid__text li::marker {
		@include link-transition;
	}
	.c-listing-grid__eyebrow {
		color: $brand;
	}
	.c-listing-grid__heading,
	.c-listing-grid__text {
		color: $body-color;
	}
	&:hover,
	&:focus {
		background: $brand;

		&::after {
			background: $white;
			color: $brand;
		}

		.c-listing-grid__eyebrow,
		.c-listing-grid__heading,
		.c-listing-grid__text {
			color: $white;
		}

		.c-listing-grid__text {
			li::marker,
			a {
				color: $white !important;
			}
		}
	}
}

.c-listing-grid__eyebrow {
	display: block;
	margin-bottom: $bsu;

	@include font-12bu;
}

.c-listing-grid__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-28b;
}

.c-listing-grid__text {}
