﻿// UTILITY.MARGINS

.u-m--0 {
	margin: 0 !important;
}

.u-mt--0 {
	margin-top: 0 !important;
}

.u-mr--0 {
	margin-right: 0 !important;
}

.u-mb--0 {
	margin-bottom: 0 !important;
}

.u-ml--0 {
	margin-left: 0 !important;
}

.u-mh--0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.u-mv--0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
