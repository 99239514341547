// OBJECT.CONTROL

.o-control {
    margin: $bsu 0 $lsu !important;

    @include bp("large") {
        margin: $bsu 0 $hsu !important;
	}

	.o-layout-content--has-aside .o-layout-main & {
		&:first-child {
			margin-top: 0;
		}
	}
}

.o-control__heading {
	@include font-heading-24b;
	margin-bottom: $bsu;

	@include bp('large') {
		@include font-heading-30b;
	}
}

.o-control__figcaption {
	@include ui-border('bottom');
	@include font-body-text;
	padding: $bsu 0;
}

.o-control--grey {
	background: $grey--light;

	margin: 0;
	padding: $lsu 0;

	@include bp("large") {
		padding: ($hsu + $bsu) 0;
	}

	+ .o-control--grey {
		padding-top: 0;
	}
}
