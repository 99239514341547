// OBJECT.BODY-TEXT

// .mce-content-body class here targets the RTE within the CMS so it will also get styled
// if statement to remove declaration from AMP CSS - can't include !important
@if not $amp {
	.mce-content-body {
		padding: 10px !important;
	}
}

.mce-content-body,
.o-body-text {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2,
	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
	}

	h2 {
		@include font-heading-36b;
	}

	h3 {
		@include font-heading-28b;
	}

	h4 {
		@include font-heading-24b;
	}

	h5 {
		@include font-heading-16b;
	}

	> p {
		@include font-body-text;
		margin-bottom: 1.5em;
	}

	a {
		@include link-brand;
		font-weight: bold;
		color: $brand;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $base-ui-color;
		height: 0;
		clear: both;
		margin: 1.5em 0;
	}

	> ul {
		li {

			&::marker {
				color: $brand;
			}
		}
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-body-text;
			margin-bottom: 1em;
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {

			li {
				list-style-type: circle;
			}
		}

		ol {

			li {
				list-style-type: lower-roman;
			}
		}
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}

	table {
		width: 100%;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;

		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th {
		@include font-16;
		background-color: $white;
	}

	thead {

		td {
			@include font-16;
			background-color: $white;
		}
	}

	th,
	td {
		border: 1px solid $grey--pale;
		padding: $ssu $bsu;
	}

	td {
		@include font-16;
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		@include font-12;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}

	.o-body-text__btn {
		@extend .o-btn;
		@extend .o-btn--primary;
	}

	b, strong {
		font-weight: $weight--bold;
	}
}

.o-hero .o-body-text__btn--dark,
.c-hubhero .o-body-text__btn--dark,
.c-hubhero__secondary-introduction .o-body-text__btn--dark,
.o-hero .o-body-text__btn--light,
.c-hubhero .o-body-text__btn--light,
.c-hubhero__secondary-introduction .o-body-text__btn--light {
	@extend .o-btn;
	@extend .o-btn--primary;
	@extend .c-hubhero__link;

	a {
		transition: none;
		color: inherit;
		background: none;
		border: 0;
		text-decoration: none;

		&:hover,
		&:focus {
			color: inherit;
		}
	}
}

// RTE figure - shared styling across macros
.o-body-text__figure {
	margin: $lsu auto;
	clear: both;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin: 0 $lsu $bsu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin: 0 0 $bsu $lsu;
		}
	}
}

.o-body-text__figcaption {
	@include ui-border('bottom');
	@include font-12;
	padding: $bsu 0
}
