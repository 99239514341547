﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	background-color: $skyblue--lightest;
	border-radius: 6px;
	overflow: hidden;

	@include bp('medium') {
		@include flex-extend;
		padding: 0;
	}
}

.c-image-feature--right {

	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	position: relative;

	&::before {
		content: "";
		display: none;
		width: 100%;
		height: 100%;
		background-image: url(/Content/images/interface/bg/left_light.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top right;
		left: auto;
		right: -2px;
		top: 0;
		bottom: 0;
		z-index: 1;
		position: absolute;

		.c-image-feature--right & {
			left: -2px;
			right: auto;
			background-image: url(/Content/images/interface/bg/right_light.svg);
			background-position: top left;
		}
	}

	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 100px;
		background-image: url(/Content/images/interface/bg/top_light.svg);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
		left: 0;
		right: 0;
		bottom: -1px;
		z-index: 1;
		position: absolute;
	}

	@include bp('medium') {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;

		&::before {
			display: block;
		}

		&::after {
			display: none;
		}
	}
}

.c-image-feature__image {

	@include bp('medium') {
		height: 100%;
	}
}

.c-image-feature__body {
	@include font-base-16;
	padding: $bsu;
	text-align: left;
	width: 100%;
	position: relative;

	@include bp('medium') {
		align-self: center;
		padding: ($bsu * 3) ($bsu * 2);
	}

	@include bp('large') {
		padding: $bsu * 4;

		.o-layout-content--has-aside & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-heading-20b;
	position: relative;
	margin-bottom: $bsu;
	color: $brand;

	@include bp('large') {
		@include font-heading-30b;
	}
}

.c-image-feature__eyebrow {
	display: block;
	margin: 0 0 $ssu;

	@include font-base-14;
}

.c-image-feature__text {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $bsu * 1.5;
	}

	p,
	li {
		@include font-body-text;
		b, strong {
			font-weight: $weight--bold;
		}
	}

	*:last-child {
		margin-bottom: 0;
	}
}

// DARK VERSION
.o-theme-dark {

	&.c-image-feature {
		background-color: $brand;
	}

	.c-image-feature__figure {
		&::before {
			background-image: url(/Content/images/interface/bg/left_dark.svg);
		}

		&::after {
			background-image: url(/Content/images/interface/bg/top_dark.svg);
		}
	}

	&.c-image-feature--right {
		.c-image-feature__figure {
			&::before {
				background-image: url(/Content/images/interface/bg/right_dark.svg);
			}
		}
	}

	.c-image-feature__heading,
	.c-image-feature__text {
		color: $white;

		a {
			color: $white;
			text-decoration: underline;
		}
	}

	.c-image-feature__heading::before {
		background-color: $white;
	}
}
