// COMPONENT.HUB-HERO

.c-hubhero__wrapper {
	margin-top: $header-height;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: flex-start;

	&::before {
		content: " ";
		display: block;
		width: 100%;
		height: 100%;
		z-index: 0;
		position: absolute;
		background-image: url(/Content/images/interface/bg/f5_hero_whitewave_bg.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include bp-below("medium") {
			background-image: url(/Content/images/interface/bg/f5_hero_whitewave_bg.png);
			background-size: cover;
		}
	}

	@include bp("medium") {
		min-height: 250px;
		margin-top: $header-height;
	}

	@include bp("large") {
		margin-top: ($header-height + 60px);
	}
}

.c-hubhero {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-top: $lsu;

	@include bp("large") {
		margin: 60px 0 30px;
	}
}

.c-hubhero__heading {
	@include font-heading-30b;
	color: $white;
	margin-bottom: 40px;

	@include bp("medium") {
		padding-right: $lsu;
	}

	@include bp("large") {
		@include font-heading-50b;
		margin-bottom: 60px;
	}

	strong {
		@include font-heading-48-h;
		color: $brand;

		@include bp("large") {
			@include font-heading-72-h;
		}
	}
}

.c-hubhero__content {
	&.o-grid {
		justify-content:space-between;
	}

	@supports (display: grid) {
		margin-top: 0;
		margin-left: 0;

		display: grid;
		grid-gap: $bsu;
		grid-template-columns: repeat(1, 1fr);
		@include bp("medium") {
			@include bp-below("large") {
				padding: $lsu 0;
			}
		}
		@include bp("large") {
			grid-gap: 10%;
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(1600px) {
			grid-gap: 20%;
		}

		> .o-grid__item {
			height: 100%;
			width: 100% !important;
			padding: 0;
			margin: 0;
		}
	}
}

.c-hubhero__secondary-introduction {
	@include font-base-16l;
	line-height: 1.445;
	color: $white;

	@include bp("medium") {
		@include font-base-18l;
	}

	a {
		@include link-brand;
		font-weight: bold;
		color: $brand;
	}
}

.c-hubhero__partner-logos {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: $bsu;
	margin: $bsu 0;
	justify-items: center;
	align-items: center;
}

.c-hubhero__partner-logos--white {
	.c-hubhero__partner-logo {
		filter: brightness(0) invert(1);
	}
}

.c-hubhero__partner-logo {
	display: block;
	max-width: 100%;

	@supports (aspect-ratio: 1 / 1) {
		height: auto;
		width: auto;
	}
}

.c-hubhero__links-wrapper {
	margin-bottom: $bsu;
	position: relative;
	z-index: 1;

	@include bp("large") {
		margin-bottom: -($lsu);

		&.nolinks {
			margin-bottom: $bsu;
		}
	}
}

.c-hubhero__links-label {
	@include font-base-18b;
	color: $white;
	margin-bottom: $bsu;
}

.c-hubhero__links {
	display: flex;
	flex-wrap: wrap;
	@include bp("medium"){
		flex-direction: row;
	}
}

.c-hubhero__link {
	padding: $ssu $bsu;
	margin-right: $ssu;
	border: 1px solid $brand;
	margin-bottom: $ssu;

	@include bp("large") {
		padding: $bsu ($bsu);
	}

	&:hover {
		background-color: $white;
		color: $brand;
	}
}